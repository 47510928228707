import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'modulo' })
export class ModuloPipe implements PipeTransform {
	transform(value: string) {

		if (value == "SOUADM01") {
			return "Interna";
		}
		if (value == "SOUADM02") {
			return "OUV";
		}
		if (value == "SOUADM03") {
			return "SAC";
		}

		return value;
	}
}
