import {Pipe, PipeTransform} from '@angular/core';
import {TamanhoEnum} from '../enums/tamanho-enum';

@Pipe({ name: 'cpfMask' })
export class CpfPipe implements PipeTransform {
	transform(value: string) {
		if (value) {
			value = value.toString();
			value = ('00000000000' + value).slice(-11);

			if (value.length === TamanhoEnum.TAMANHO_CPF) {
				return value.substring(0, 3).concat('.')
					.concat(value.substring(3, 6))
					.concat('.')
					.concat(value.substring(6, 9))
					.concat('-')
					.concat(value.substring(9, 11))
			}

		}

		return value;
	}
}
