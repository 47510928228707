import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Motivo} from 'app/shared/models/administracao/parametrizacao/motivo';
import {SelectItemModel} from 'app/shared/models/select-item';
import {Pesquisa} from 'app/shared/models/comum/pesquisa';
import {ParametroEquipeUnidade} from "../../../models/administracao/parametrizacao/parametro-equipe-unidade";

@Injectable()
export class MotivoService extends CrudHttpClientService<Motivo> {
	constructor(protected http: HttpClient) {
		super('administracao/parametrizacao/motivo', http);
	}

	public searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
		let httpParams: HttpParams = new HttpParams();
		httpParams = httpParams.set('value', value);
		httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
		return this.http.get(this.url + '/search-autocomplete/', this.options({ params: httpParams }));
	}

	public todosAtivos(): any {
		return this.http.get<Motivo[]>(this.url + '/ativos', this.options());
	}

	public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
		return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis', idTipoOcorrencia, this.options());
	}

	public consultarSelectItensPorNatureza(pesquisa: Pesquisa): Observable<any> {
		return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-natureza', pesquisa, this.options());
	}

	public alterarSituacao(motivo: Motivo): Observable<any> {
		return this.http.put(this.url + '/ativar-inativar', motivo, this.options());
	}

	public consultarSelectItensPorNaturezaAssuntoItem(pesquisa: Pesquisa): Observable<any> {
		return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-natureza-assunto-item', pesquisa, this.options());
	}

	public consultarSelectItensCategorizadoOcorrenciaInterna(): Observable<any> {
		return this.http.get<SelectItemModel>(this.url + '/select-itens-categorizados-ocorrencia-interna/', this.options());
	}

	public alterarSituacaoLote(itens: Motivo[]): Observable<any> {
		return this.http.put(this.url + '/ativar-inativar-lote', itens, this.options());
	  }
	public deleteLote(itens: Motivo[]): Observable<any> {
		return this.http.post(this.url + '/excluir-lote', itens, this.options());
	}

	public consultarSelectItensPorNaturezaAssuntoItemEquipeUnidade(parametroEquipeUnidade: ParametroEquipeUnidade): Observable<any> {
		return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-natureza-assunto-item-equipe-unidade', parametroEquipeUnidade, this.options());
	}
}
