import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {IndicadoresMembroDTO} from 'app/shared/models/ocorrencia/indicadores-membros-dto';
import {Observable} from 'rxjs';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';

@Injectable()
export class IndicadoresMembroService extends CrudHttpClientService<IndicadoresMembroDTO> {
    constructor(
        protected http: HttpClient,
        protected messageService: MessageService
    ) {
        super('ocorrencia/indicadores-membro', http);
    }

    public consultarPaginadoHistorico(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
    }

    public consultarPausas(matricula: string, idEquipe: number): Observable<any> {
        return this.http.get(this.url + '/consultar-pausas/' + matricula + '/'+ idEquipe, this.options())
    }

}
