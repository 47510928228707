import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Canal} from 'app/shared/models/administracao/parametrizacao/canal';
import {SelectItemModel} from 'app/shared/models/select-item';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class CanalService extends CrudHttpClientService<Canal> {
	constructor(protected http: HttpClient) {
		super('administracao/parametrizacao/canal', http);
	}

  searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

	todosAtivos(): any {
		return this.http.get<Canal[]>(this.url + '/ativos', this.options());
	}

	public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
		return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis', idTipoOcorrencia, this.options());
	}

	consultarTipoCanal(idCanal: number) {
		return this.http.get(`${this.url}/consultar-tipo-canal/${idCanal}`);
	}

	public ativarInativar(canal: Canal): Observable<any> {
		return this.http.put<Canal>(this.url + "/ativar-inativar", canal, this.options());
	}
}
