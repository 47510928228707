import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {SelectItemModel} from 'app/shared/models/select-item';
import {PostoAtendimentoProcon} from 'app/shared/models/administracao/parametrizacao/posto-atendimento-procon';

@Injectable()
export class PostoAtendimentoProconService extends CrudHttpClientService<PostoAtendimentoProcon> {
	constructor(protected http: HttpClient) {
		super('administracao/parametrizacao/postoatendimentoprocon', http);
	}

	public searchAutocomplete(value: string): Observable<any> {
		return this.http.post(this.url + '/search-autocomplete/', value, this.options());
	}

	public todosAtivos(): any {
		return this.http.get<PostoAtendimentoProcon[]>(this.url + '/ativos', this.options());
	}

	public consultarSelectItens(): Observable<any> {
		return this.http.get<SelectItemModel>(this.url + '/select-itens-perfis/', this.options());
	}

	public ativarInativar(postoAtendimentoProcon: PostoAtendimentoProcon): Observable<PostoAtendimentoProcon> {
		return this.http.put<PostoAtendimentoProcon>(this.url + "/ativar-inativar", postoAtendimentoProcon, this.options());
	}

	
	public consultarPostosAtendimentosPorProcon(idProcon: number): Observable<any> {
		return this.http.get<SelectItemModel>(this.url + `/consultar-por-procon/${idProcon}`, this.options());
	}
	
}
