import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from './../../../../arquitetura/shared/services/crud-http-client.service';
import {MessageService} from './../../../components/messages/message.service';
import {ProblemaComunicacao} from './../../../models/administracao/parametrizacao/problema-comunicacao';

@Injectable({
  providedIn: 'root'
})
export class ProblemaComunicacaoService extends CrudHttpClientService<ProblemaComunicacao> {

  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/parametrizacao/problema-comunicacao', http);
  }

  public searchAutocomplete(nome: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('nome', nome);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(`${this.url}/search-autocomplete/`, this.options({params: httpParams}));
  }

  public alterarSituacao(problemaComunicacao: ProblemaComunicacao): Observable<any> {
    return this.http.put(`${this.url}/ativar-inativar`, problemaComunicacao, this.options());
  }

  public verificarExistenciaVinculo(id: number): Observable<any> {
    return this.http.get(`${this.url}/verificar-existencia-vinculo/${id}`, this.options());
  }

  public consultarTodosPorTipoOcorrencia(idTipoOcorrencia: number): Observable<any> {
    return this.http.get(`${this.url}/consultar-todos-por-tipo-ocorrencia/${idTipoOcorrencia}`, this.options());
  }
}
