import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {TipoAtendimentoProcon} from 'app/shared/models/administracao/parametrizacao/tipo-atendimento-procon';
import {SelectItemModel} from 'app/shared/models/select-item';

@Injectable()
export class TipoAtendimentoProconService extends CrudHttpClientService<TipoAtendimentoProcon> {
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/parametrizacao/tipo-atendimento-procon', http);
  }

  public searchAutocomplete(value: string): Observable<any> {
    return this.http.post(this.url + '/search-autocomplete/', value, this.options());
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public ativarInativar(tipoAtendimento: TipoAtendimentoProcon): Observable<TipoAtendimentoProcon> {
    return this.http.put<TipoAtendimentoProcon>(this.url + "/ativar-inativar", tipoAtendimento, this.options());
  }

  public consultarSelectItens(): Observable<any> {
		return this.http.get<SelectItemModel>(this.url + '/select-itens-perfis/', this.options());
	}
}
