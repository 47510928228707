import {Component, Input} from '@angular/core';
import {FormControlName} from '@angular/forms'

@Component({
  selector: 'app-validation-field',
  templateUrl: './validation-field.component.html'
})
export class ValidationField {

  @Input() control: FormControlName;
  @Input() type: string;
  @Input() errorMessage: string;
  @Input() width: string;

  hasError(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched)
  }

  hasInvalidateType() {
    return (this.control.dirty || this.control.touched) && this.control.invalid && this.control.errors[this.type];
  }

}
