import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {UsuarioExterno} from 'app/shared/models/comum/usuario-externo';

@Injectable()
export class UsuarioExternoService extends CrudHttpClientService<any> {
	constructor(protected http: HttpClient) {
		super('comum/usuario-externo', http);
	}

	public getEmpregado(matricula: string): Observable<UsuarioExterno> {
		return this.http.get<UsuarioExterno>(this.url + '/matricula/' + matricula);
	}
}
