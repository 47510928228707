export enum EnumTipoOcorrencia {
    INTERNA = 1,
    OUVIDORIA = 2,
    SAC = 3


}

export class TipoOcorrenciaEnum {

    public static getNomeTipoOcorrencia(idTipoOcorrencia: number) {
        let tipoOcorrencia = [];
        tipoOcorrencia[EnumTipoOcorrencia.OUVIDORIA] = 'OUVIDORIA';
        tipoOcorrencia[EnumTipoOcorrencia.SAC] = 'SAC';
        tipoOcorrencia[EnumTipoOcorrencia.INTERNA] = 'VIVA VOZ';

        return tipoOcorrencia[idTipoOcorrencia];
    }

    public static getTipoOcorrencia() {
        let tipoOcorrencia = [];
        tipoOcorrencia[EnumTipoOcorrencia.OUVIDORIA] = 'OUVIDORIA';
        tipoOcorrencia[EnumTipoOcorrencia.SAC] = 'SAC';
        tipoOcorrencia[EnumTipoOcorrencia.INTERNA] = 'VIVA VOZ';

        return tipoOcorrencia;
    }

}


