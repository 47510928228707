import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {HttpClient} from '@angular/common/http';
import {PriorizacaoDistribuicao} from 'app/shared/models/administracao/parametrizacao/priorizacao-distribuicao';

@Injectable()
export class PriorizacaoDistribuicaoService extends CrudHttpClientService<PriorizacaoDistribuicao> {
    
    constructor(protected http: HttpClient) {
        super('administracao/parametrizacao/priorizacao-distribuicao', http);
    }
}
