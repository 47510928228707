import {plainToClass} from 'class-transformer';
import {Util} from 'app/arquitetura/shared/util/util';

import {environment} from 'environments/environment';

export class Storage<T> {
	private static nomeSistema: string = '';

	public static setNomeSistema(nomeSistema: string) {
		Storage.nomeSistema = nomeSistema;
	}

	constructor(private tipo: new () => T, private chave: string) {
	}

	public gravar(dado: T) {
		if (!dado) {
			return;
		}

		sessionStorage.setItem(this.getChave(), JSON.stringify(dado));
	}

	public ler(): T {
		let dado: string;

		dado = sessionStorage.getItem(this.getChave());
		if (!Util.isDefined(dado)) {
			return null;
		}

		let obj: any = plainToClass(this.tipo, JSON.parse(dado));

		return <T> obj;
	}

	public limpar() {
		sessionStorage.removeItem(this.getChave());
	}

	private getChave(): string {
		return Storage.nomeSistema + '-' + environment.envName + '-' + this.chave;
	}
}
