import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {AvaliacaoOcorrenciaExterna} from 'app/shared/models/ocorrencia/avaliacao-ocorrencia-externa';
import {
    AvaliacaoOcorrenciaExternaRedistribuicao
} from 'app/shared/models/ocorrencia/avaliacao-ocorrencia-externa-redistribuicao';

@Injectable()
export class AvaliacaoOcorrenciaExternaService extends CrudHttpClientService<AvaliacaoOcorrenciaExterna> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/avaliacao-ocorrencia-externa', http);
  }

  consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  excluirLote(avaliacoes: AvaliacaoOcorrenciaExterna[]): Observable<any> {
    return this.http.post(this.url + '/excluir-lote', avaliacoes, this.options());
  }

  redistribuir(avaliacao: AvaliacaoOcorrenciaExternaRedistribuicao): Observable<any> {
    return this.http.post(this.url + '/redistribuicao', avaliacao, this.options());
  }
}
