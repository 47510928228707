import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';

import {MessageService} from 'app/shared/components/messages/message.service';

import {Observable} from 'rxjs';
import {OcorrenciaGestaoWebservices} from 'app/shared/models/ocorrencia/ocorrencia-gestao-webservices';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {RetornoLogCorreio} from "../../models/ocorrencia/RetornoLogCorreio";

@Injectable({ providedIn: 'root' })
export class OcorrenciaGestaoWebservicesService extends CrudHttpClientService<OcorrenciaGestaoWebservices> {
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/correios/causa-raiz/ocorrencia-gestao-webService', http);   
  }

  consultarDadosArquivo(filtro: FiltroPaginado) : Observable<any> {
    return this.http.post(this.url + '/consultar-dados-lote', filtro, this.options());
  }


  consultarNotificacao(filtro: FiltroPaginado): any {
    return this.http.post(this.url + '/consultar-notificacao', filtro, this.options());
  }

  consultarInconsistencia(filtro: FiltroPaginado): any {
    return this.http.post(this.url + '/consultar-inconsistencia', filtro, this.options());
  }

  consultarRespostaNotificacao(filtro: FiltroPaginado): any {
    return this.http.post(this.url + '/consultar-resposta-notificacao', filtro, this.options());
  }

  consultarRastreamentoData(filtro: FiltroPaginado): any {
    return this.http.post(this.url + '/consultar-rastreamento-data', filtro, this.options());
  }

  consultarRastreamentoFinal(filtro: FiltroPaginado): any {
    return this.http.post(this.url + '/consultar-rastreamento-final', filtro, this.options());
  }

  consultarCancelamento(filtro: FiltroPaginado): any {
    return this.http.post(this.url + '/consultar-cancelamento', filtro, this.options());
  }

  cancelar(retorno: any): any {
    return this.http.post<any>(this.url + '/cancelar', retorno, this.options());
  }

  enviar(retorno: any): any {
    return this.http.post<any>(this.url + '/enviarCorreios', retorno, this.options());
  }

  consultarCancelamentoResposta(filtro: FiltroPaginado): any {
    return this.http.post(this.url + '/consultar-cancelamento-resposta', filtro, this.options());
  }

  consultarPorFiltro(filtro: FiltroPaginado): Observable<any> {
    return this.http.post<any>(this.url + '/consultarPorFiltro', filtro, this.options());
  }

  consultarDadosOcorrencia(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-dados-ocorrencia', filtro, this.options());
  }

  consultarLog(lote: number): Observable<RetornoLogCorreio[]> {
    return this.http.get<RetornoLogCorreio[]>(this.url+'/log/'+lote);
  }
}
