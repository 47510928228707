import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Item} from 'app/shared/models/administracao/parametrizacao/item';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {SelectItemModel} from 'app/shared/models/select-item';
import {Pesquisa} from 'app/shared/models/comum/pesquisa';
import {ParametroEquipeUnidade} from "../../../models/administracao/parametrizacao/parametro-equipe-unidade";

@Injectable()
export class ItemService extends CrudHttpClientService<Item> {
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/parametrizacao/item', http);
  }

  public carregarItensPorAssunto(assunto: number): Item[] {
    const i = [
      this.newItem(1, 'Item 1'),
      this.newItem(2, 'Item 2'),
      this.newItem(3, 'Item 3'),
      this.newItem(4, 'Item 4'),
      this.newItem(5, 'Item 5'),
    ];
    return i;
  }

  private newItem(id: number, nome: string): Item {
    const i = new Item();
    // i.id = id;
    // i.noItemAssunto = nome;
    return i;
  }

  consultar(item: Item, next?: (itens: Item[]) => void, errorCallback?: any): any {
    this.callPOST<Item[]>('/consultar', item, next, error => {
      const message = errorCallback(error, 'Ocorreu um erro ao pesquisar itens.');
      this.messageService.addMsgDanger(message);
      console.log('Erro ao chamar o servico. Erro: ' + error);
    });
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

  public consultarId(next?: (itens: Item[]) => void, errorCallback?: any): any {
    this.callPOST<Item[]>('/consultarIdT', 0, next, error => {
      const message = errorCallback(error, 'Ocorreu um erro ao pesquisar itens.');
      this.messageService.addMsgDanger(message);
      console.log('Erro ao chamar o servico. Erro: ' + error);
    });
  }

  public ativarInativar(item: Item): Observable<any> {
    return this.http.put<Item>(this.url + "/ativar-inativar", item, this.options());
  }

  public todosAtivos(): any {
    return this.http.get<Item[]>(this.url + '/ativos', this.options());
  }

  public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis', idTipoOcorrencia, this.options());
  }

  public consultarSelectItensPorNatureza(pesquisa: Pesquisa): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-natureza', pesquisa, this.options());
  }

  public consultarSelectItensPorNaturezaAssunto(pesquisa: Pesquisa): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-natureza-assunto', pesquisa, this.options());
  }

  public consultarSelectItensCategorizadoOcorrenciaInterna(): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens-categorizados-ocorrencia-interna/', this.options());
  }

  public alterarSituacaoLote(itens: Item[]): Observable<any> {
    return this.http.put(this.url + '/ativar-inativar-lote', itens, this.options());
  }
  public deleteLote(itens: Item[]): Observable<any> {
    return this.http.post(this.url + '/excluir-lote', itens, this.options());
  }

  public consultarSelectItensPorNaturezaAssuntoEquipeUnidade(parametroEquipeUnidade: ParametroEquipeUnidade): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-natureza-assunto-equipe-unidade', parametroEquipeUnidade, this.options());
  }

  public consultarItensPorAssunto(idsAssunto: number[]): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/consultar-itens-por-assunto', idsAssunto, this.options());
  }

}
