import {Injectable, Injector} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {Observer} from 'rxjs/Observer';
import {mergeMap} from 'rxjs/operators';

import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';
import {ConfiguracaoSegurancaService} from 'app/arquitetura/shared/services/seguranca/configuracao-seguranca.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {ConfirmListener} from 'app/shared/components/messages/confirm-listener';
import {Util} from 'app/arquitetura/shared/util/util';

const XSSI_PREFIX = /^\)\]\}',?\n/;

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	private static readonly HEADER_AUTHORIZATION = 'Authorization';
	private static readonly SISOU_TIPO_OCORRENCIA = 'SISOU-TIPO_OCORRENCIA';

	private initialized: boolean;
	private sessaoService: SessaoService;

	constructor(
		private injector: Injector,
		private messageService: MessageService
	) {
		this.initialized = false;
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.includes('/' + ConfiguracaoSegurancaService.CONFIGURACAO_SEGURANCA_SERVICE_URL)) {
			return this.handleDate(req, next);
		}

		return this.adicionarTokenNoCabecalho(req.headers).pipe(
      mergeMap(headersWithBearer => {
				let token: string = '';
				let handledReq: HttpRequest<any>;
				let idTipoOcorrencia = this.getSessaoService().getUsuario().idTipoOcorrencia;
				if (this.getSessaoService().getToken()) {
					token = this.getSessaoService().getToken().token;

					handledReq = req.clone({
						headers: req.headers
							.set(RequestInterceptor.HEADER_AUTHORIZATION, 'Bearer ' + token)
					});

					handledReq = req.clone({
						headers: new HttpHeaders({					  
						  'X-sisou-tipo-ocorrencia':  ''+idTipoOcorrencia,
						  'Authorization': 'Bearer ' + token,
						})
					  });
				} else {
					handledReq = req;
				}

				return this.handleDate(handledReq, next).do(
					(event: HttpEvent<any>) => {
						if (event instanceof HttpResponse) {
							// nada a fazer
						}
					},
					(err: any) => {
						if (err instanceof HttpErrorResponse) {
							if (err.status === 401) {
								this.processarErroAutenticacao();
							}
							else if (err.status === 403) {
								this.processarErroAutorizacao();
							}
						}
					});
      })
    );
	}

	private adicionarTokenNoCabecalho(headersArg?: HttpHeaders): Observable<HttpHeaders> {
    return Observable.create(async (observer: Observer<any>) => {
      let headers = headersArg;
      if (!headers) {
        headers = new HttpHeaders();
			}

      try {
				const token: string = await this.getSessaoService().atualizarToken();
				if (token == null) {
					this.processarErroAutenticacao();
					observer.error({});
					return;
				}

				headers = headers.set(RequestInterceptor.HEADER_AUTHORIZATION, 'Bearer ' + token);
        observer.next(headers);
        observer.complete();
      } catch (error) {
        observer.error(error);
      }
    });
	}

	private recuperarToken(response: HttpResponse<any>): string {
		const authHeader = response.headers.get(RequestInterceptor.HEADER_AUTHORIZATION);

		if ((!Util.isDefined(authHeader)) || (!authHeader.startsWith('Bearer '))) {
			return null;
		}

		return authHeader.substring('Bearer '.length);
	}

	/**
	 * 401 Unauthorized: apesar do nome, representa erro de autenticação
	 */
	private processarErroAutenticacao() {
		this.messageService.addConfirmOk('Sua sessão expirou. Realize o login novamente.',
			(): ConfirmListener => {
				this.getSessaoService().finalizarSessao(true);
				return;
			});
	}

	/**
	 * 403 Forbidden: representa erro de autorização
	 */
	private processarErroAutorizacao() {
		this.messageService.addConfirmOk('Acesso negado.');
	}

	private getSessaoService(): SessaoService {
		if (!this.initialized) {
			this.initialized = true;
			this.sessaoService = this.injector.get(SessaoService);
		}

		return this.sessaoService;
	}

  public handleDate(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.responseType !== 'json') {
      return next.handle(req);
    }

		req = req.clone({
      responseType: 'text'
    });

    return next.handle(req).map(event => {
      if (!(event instanceof HttpResponse)) {
        return event;
      }

			return this.processJsonResponse(event);
    });
  }

  private processJsonResponse(res: HttpResponse<string>): HttpResponse<any> {
      let body = res.body;

			if (typeof body === 'string') {
				const originalBody = body;

				body = body.replace(XSSI_PREFIX, '');

        try {
					body = body !== '' ? JSON.parse(body,
						(key: any, value: any) => this.reviveUtcDate(key, value)) : null;
        } catch (error) {
          throw new HttpErrorResponse({
            error: { error, text: originalBody },
            headers: res.headers,
            status: res.status,
            statusText: res.statusText,
            url: res.url || undefined,
          });
        }
			}

      return res.clone({ body });
  }

  private reviveUtcDate(key: any, value: any): any {
      if (typeof value !== 'string') {
          return value;
      }

			if (value === '0001-01-01T00:00:00') {
          return null;
      }

			const match = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value);
      if (!match) {
          return value;
      }

			return new Date(value);
  }
}
