import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Entity} from '../../../arquitetura/shared/models/entity';
import {Injectable} from '@angular/core';
import {map, shareReplay} from 'rxjs/operators';
import {SelectItem} from 'primeng/components/common/selectitem';
import {Observable} from 'rxjs';

@Injectable()
export class UnidadeService extends CrudHttpClientService<Entity> {

    private tiposUnidade$: Observable<SelectItem[]>;

    constructor(protected http: HttpClient) {
        super('comum/unidade', http);
    }

    public consultarPorNumero(numeroUnidade: number): any {
        return this.http.get(this.url + '/' + numeroUnidade);
    }

    public consultarUnidadesPorFiltro(parametros: any): any {
        return this.http.post<any>(`${this.url}/`, parametros, this.options());
    }

    public consultarPorNumeroNaoBloqueada(numeroUnidade: number): any {
        return this.http.get(this.url + '/nao-bloqueada/' + numeroUnidade);
    }

    public consultarTiposUnidade(): Observable<SelectItem[]> {
        if (!this.tiposUnidade$) {
            this.tiposUnidade$ = this.http.get(this.url + '/tipo').pipe(
                shareReplay(1),
                map((tipos: any[]) => {
                    const lista: SelectItem[] = tipos.map(item => {
                        return {label: item['descricao'], value: item.codigo}
                    });
                    lista.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
                    return lista;
                })
            );
        }
        return this.tiposUnidade$;
    }

    public consultarTipoUnidadesVinculada(parametros: any): any {
        return this.http.post<any>(`${this.url}/tipoUnidadesVinculada`, parametros, this.options());
    }

    public consultarVinculos(numeroUnidade: number): any {
        return this.http.get(this.url + '/vinculos/' + numeroUnidade);
    }

    public consultarUnidadeSubordinada(numeroUnidade: number): any {
        return this.http.get(this.url + '/consultar-unidade-subordinada/' + numeroUnidade);
    }

    public getFuncaoUnidade(unidade: number) {
        return this.http.get(this.url + '/consultar-funcao-unidade/' + unidade);
    }
}
