import {Entity} from 'app/arquitetura/shared/models/entity';

export class Usuario extends Entity {
    public idKeycloak: string = null;
    public login: string = null;
    public matricula: string = null;
    public nome: string = null;
    public cpf: string = null;
    public email: string = null;
    public unidade: string = null;
    public numeroNaturalUnidade: number = null;
    public ufUnidade: string = null;
    public codigo: string = null;
    public perfis: string[] = null;
    public recursos: string[] = null;
    public modulo: string = null;
    public matriculaNumerica: number = null;
    public idEquipe: number = null;
    public idTipoOcorrencia: number = null;
    public idTipoOcorrenciaAtual: number = null;
    public frenteAtual: number = null;
    public usuarioExterno: boolean = false;
    public perfisParaSelecao: string[] = null;
    public perfilSelecionado: string = null;
    public cnpjUsuarioExterno: string = null;
}
