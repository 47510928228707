import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Solicitante} from 'app/shared/models/ocorrencia/solicitante';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';


@Injectable()
export class SolicitanteService extends CrudHttpClientService<Solicitante> {

  private solicitanteSubject$ = new Subject<Solicitante>();
  solicitanteEncontrado$ = this.solicitanteSubject$.asObservable();

  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/solicitante', http);
  }

  consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  consultarPorCpfCnpj(cpfCnpj: number): Observable<Solicitante> {
    return this.http.get<Solicitante>(this.url + '/cpf-cnpj/' + cpfCnpj, this.options()).pipe(tap({
      next: solicitante => this.emiteSolicitanteBehaviorSubject(solicitante),
      error: error => this.emiteSolicitanteBehaviorSubject(undefined)
    }));
  }

  emiteSolicitanteBehaviorSubject(solicitante: Solicitante) {
    this.solicitanteSubject$.next(solicitante);
  }

  alterarEmailSolicitante(solicitante: Solicitante): Observable<any> {
    return this.http.put(this.url + '/alterar-email-solicitante', solicitante);
  }
}
