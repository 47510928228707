import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {MensagemSms} from '../../../models/administracao/parametrizacao/mensagem-sms';
import {FiltroPaginado} from '../../../models/filtroPaginado';

@Injectable()
export class MensagemSmsService extends CrudHttpClientService<MensagemSms> {
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('sms/mensagens', http);
  }

  consultarCodigoGMS(codigoSistema: string, id: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('codigoSistema', codigoSistema);
    if (id) {
      httpParams = httpParams.set('id', id.toString());
    }
    return this.http.get(this.url + '/gms', this.options({params: httpParams}));
  }

  consultarPorID(id: number): Observable<any> {
    return this.http.get<MensagemSms>(this.url + '/' + id, this.options());
  }

  isUtilizadaParaEnvio(id: number): Observable<any> {
    return this.http.get<MensagemSms>(this.url + '/utilizada-envio/' + id, this.options());
  }

  public ativarInativar(sms: MensagemSms): Observable<MensagemSms> {
    return this.http.put<MensagemSms>(this.url + '/ativar-inativar', sms, this.options());
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public searchAutocomplete(value: string): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('nome', value);
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

}
