import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Rx';

@Injectable()
export class MenuLateralService {

  private menuLateralSubject$ = new Subject<any>();
  menuLateralReload$ = this.menuLateralSubject$.asObservable();

  emiteReloadMenuLateral() {
    this.menuLateralSubject$.next();
  }

}
