import {CrudHttpClientService} from "app/arquitetura/shared/services/crud-http-client.service";
import {Justificativa} from "app/shared/models/administracao/parametrizacao/justificativa";
import {HttpClient} from "@angular/common/http";
import {MessageService} from "app/shared/components/messages/message.service";
import {Injectable} from "@angular/core";
import {FiltroPaginado} from "app/shared/models/filtroPaginado";
import {Observable} from "rxjs";
import {Item} from "app/shared/models/administracao/parametrizacao/item";

@Injectable()
export class JustificativaService extends CrudHttpClientService<Justificativa> {
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super("administracao/parametrizacao/justificativa-movimentacao", http);
  }

  incluir(justificativas: Justificativa[]): Observable<any> {
    return this.http.post(this.url, justificativas);
  }

  consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(
      this.url + "/consultar-paginado",
      filtro,
      this.options()
    );
  }

  searchAutocomplete(value: string): Observable<any> {
    return this.http.post(
      this.url + "/search-autocomplete/",
      value,
      this.options()
    );
  }

  ativarInativar(justificativa: Justificativa): Observable<any> {
    return this.http.put<Item>(
      this.url + "/ativar-inativar",
      justificativa,
      this.options()
    );
  }

  consultarPorPadrao(idTipoMovimentacao: number): any {
    return this.http.get(this.url + '/idTipoMovimentacao/' + idTipoMovimentacao, this.options());
  }

  padrao(justificativa: Justificativa): Observable<any> {
    return this.http.put(this.url + '/padrao', justificativa, this.options());
  }
}
