import {MessageService} from 'app/shared/components/messages/message.service';
import {Base} from 'app/shared/components/base';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-many-radio',
  templateUrl: './many-radio.component.html',
  styleUrls: ['./many-radio.component.css']
})
export class ManyRadioComponent extends Base implements OnInit, OnChanges {

  @Input() form: FormGroup;
  @Input() required = false;
  @Input() list: any[];
  @Input() itemDescription: string;
  @Input() itemValue: any;
  @Input() disable = false;
  @Input() name: string;
  @Input() label: string;
  @Input() selected: any;
  @Input() inline = 'false';

  nameDisable = 'disable';

  constructor(protected messageService: MessageService) {
    super(messageService);
  }

  ngOnChanges(changes: SimpleChanges) {
    const keyChanges = Object.keys(changes);
    if (this.contains(keyChanges, this.nameDisable)) {
      this.disable ? this.disableField(this.form, this.name) : this.enableField(this.form, this.name);
    }
  }

  ngOnInit(): void {
    this.form.addControl(
      this.name,
      this.getFormControl()
    );
  }

  getFormControl(): FormControl {
    return this.required
      ? new FormControl({value: this.selected || null, disabled: this.disable}, Validators.required)
      : new FormControl({value: this.selected || null, disabled: this.disable});
  }

  getItems(): any[] {
    return this.list ? this.list : [];
  }

  getDescription(i): any {
    return this.list[i][this.itemDescription];
  }

  getValue(i): any {
    return this.list[i][this.itemValue];
  }

  isValid(): boolean {
    const campo: AbstractControl = this.form.get(this.name);
    return (campo.dirty || campo.touched) && campo.invalid;
  }

}
