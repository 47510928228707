import {FiltroPaginado} from './../../models/filtroPaginado';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {DiarioBordo} from 'app/shared/models/ocorrencia/diario-bordo';
import {Observable, Subject} from 'rxjs/Rx';
import {MovimentacaoOcorrenciaSac} from 'app/shared/models/ocorrencia/movimentacao-ocorrencia-sac';

@Injectable()
export class MovimentacaoOcorrenciaService extends CrudHttpClientService<DiarioBordo> {

  private diarioBordoSubject$ = new Subject<DiarioBordo>();
  diarioBordoIncluido$ = this.diarioBordoSubject$.asObservable();

  private atualizarMovimentacao$ = new Subject<number>();

  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/movimentacao', http);
  }

  atualizarMovimentacoes(idOcorrencia: number){
    this.atualizarMovimentacao$.next(idOcorrencia);
  }

  getAtualizarMovimentacoes():Observable<number>{
    return this.atualizarMovimentacao$.asObservable();
  }

  public consultarPorOcorrencia(nuOcorrencia: number): Observable<any> {
    return this.http.get<MovimentacaoOcorrenciaSac[]>(this.url + '/ocorrencia/' + nuOcorrencia, this.options());
  }

  public consultarPorId(id: number): Observable<any> {
    return this.http.get(this.url + '/' + id);
  }

  /**
   * Consultar com paginação
   */
  public consultarHistoricoPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-historico', filtro, this.options());
  }


  //Não era pra estar aqui, tem que criar um repository certo, mas não sei fazer isso no momento...
  public consultarTipos(): Observable<any> {
    return this.http.get(this.url + '/consultar-tipos');
  }

}
