import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoadingModalComponent} from './loading-modal.component';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';

@NgModule({
	imports: [CommonModule,
	NgxLoadingModule.forRoot({
		animationType: ngxLoadingAnimationTypes.none
	})],
	declarations: [LoadingModalComponent],
	exports: [LoadingModalComponent],
})
export class LoadingModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: LoadingModule
		};
	}
}
