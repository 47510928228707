import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'nomeAcaoPipe' })
export class NomeAcaoPipe implements PipeTransform {
	transform(value: string) {


		if (value === 'A' 
			|| value === 'T' 
			|| value === 'N'
			|| value === 'E'
			|| value === 'A') { return 'Reabrir'; }

		return 'Contestar';
	}
}
