import {Entity} from 'app/arquitetura/shared/models/entity';
import {TipoOcorrencia} from './tipo-ocorrencia';


export class Item extends Entity {
  
  public nome: string = null;
  public ativo: string = null;
  public tipoOcorrenciaDTO: TipoOcorrencia = null;
  public temCategorizacao: boolean = false;  
}
