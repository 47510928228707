import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'matricula' })
export class MatriculaPipe implements PipeTransform {
	transform(value: string) {
		if (value) {
			let zero = 6 - value.length + 1;
  			return 'C' + Array(+(zero > 0 && zero)).join("0") + value;
		} 
		return value;
	}
}
