import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'tipoTemplate' })
export class TipoTemplatePipe implements PipeTransform {
	transform(value: string) {

		if (value == "T") {
			return "Tarefa";
		} 
		if (value == "R") {
			return "Resposta";
		}
		if (value == "U") {
			return "Recurso";
		}

		return value;
	}
}
