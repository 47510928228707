import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Assunto} from 'app/shared/models/administracao/parametrizacao/assunto';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {SelectItemModel} from 'app/shared/models/select-item';
import {Pesquisa} from 'app/shared/models/comum/pesquisa';
import {ParametroEquipeUnidade} from "../../../models/administracao/parametrizacao/parametro-equipe-unidade";

@Injectable()
export class AssuntoService extends CrudHttpClientService<Assunto> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('administracao/parametrizacao/assunto', http);
  }

  consultar(nome: string, situacao: string): any {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('noAssunto', nome);
    httpParams = httpParams.set('icSituacao', situacao ? situacao : '');

    return this.http.get<Assunto[]>(
        this.url + '/consultar',
        this.options({params: httpParams})
    );
  }

  public carregarAssuntos(): any {
    return this.http.get<Assunto[]>(this.url);
  }

  public alterarSituacao(assunto: Assunto): Observable<any> {
    return this.http.put(this.url + '/ativar-inativar', assunto, this.options());
  }

  public alterarSituacaoLote(assuntos: Assunto[]): Observable<any> {
    return this.http.put(this.url + '/ativar-inativar-lote', assuntos, this.options());
  }

  public deleteLote(assuntos: Assunto[]): Observable<any> {
    return this.http.post(this.url + '/excluir-lote', assuntos, this.options());
  }


  public searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

  public callListarAssuntosPorNatureza(idNatureza: number, next?: (value: any) => void): void {
    this.callGET<Assunto[]>('/itens/ativos/natureza/' + idNatureza, next, error => {
      this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar assuntos.');
      console.log('Erro ao consultar assuntos:' + error);
    });
  }

  /*
	FIX-ME: (inicio) REMOVER DAQUI!!
	*/
  public carregarAssuntosPorNatureza(nuNatureza: number): Assunto[] {
    return [
      this.newAssunto(1, 'Assunto 1'),
      this.newAssunto(2, 'Assunto 2'),
      this.newAssunto(3, 'Assunto 3'),
      this.newAssunto(4, 'Assunto 4'),
      this.newAssunto(5, 'Assunto 5')
    ];
  }

  newAssunto(id: number, nome: string): Assunto {
    let a = new Assunto();
    a.id = id;
    a.nome = nome;
    return a;
  }

  consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  todosAtivos(): any {
    return this.http.get<Assunto[]>(this.url + '/consultar-ativos', this.options());
  }

  public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis', idTipoOcorrencia, this.options());
  }

  public consultarSelectItensPorNatureza(pesquisa: Pesquisa): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-natureza', pesquisa, this.options());
  }

  public consultarSelectItensCategorizadoOcorrenciaInterna(): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens-categorizados-ocorrencia-interna/', this.options());
  }

  public consultarSelectItensPorNaturezaEquipeUnidade(parametroEquipeUnidade: ParametroEquipeUnidade): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-natureza-equipe-unidade', parametroEquipeUnidade, this.options());
  }

}
