import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {PainelSubsidiaria} from 'app/shared/models/ocorrencia/painel-subsidiaria';
import {Observable} from 'rxjs';


@Injectable()
export class PaginaInicialSubsidiariaService extends CrudHttpClientService<any> {
	constructor(
		protected http: HttpClient,
		protected messageService: MessageService
	) {
		super('subsidiaria/pagina-inicial', http);
	}

	public consultarDadosPainel(idUnidade: number): Observable<PainelSubsidiaria> {
		return this.http.get<PainelSubsidiaria>(`${this.url}/${idUnidade}`, this.options());
	}


}
