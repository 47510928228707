import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {ParametroSistema} from 'app/shared/models/administracao/parametrizacao/parametro-sistema';
import {ParametroPqv} from "../../../models/administracao/parametrizacao/parametro-pqv";
import {Observable} from "rxjs";
import {HistoricoConsoleGestor} from "../../../models/ocorrencia/historico-console-gestor";

@Injectable()
export class ParametroSistemaService extends CrudHttpClientService<ParametroSistema> {

    constructor(protected http: HttpClient) {
        super('administracao/parametrizacao/parametro-sistema', http);
    }

    public consultar(): any {
        return this.http.get<ParametroSistema[]>(this.url, this.options());
    }

    public consultarUltimo(): any {
        return this.http.get<ParametroSistema[]>(this.url + '/consultar-ultimo', this.options());
    }

    atualizaCausaRaiz(parametro: ParametroSistema) {
        return this.http.post<ParametroSistema>(this.url + '/atualizaCausaRaiz', parametro, this.options());
    }

    atualizaTempoMedioSMS(parametro: ParametroSistema) {
        return this.http.post<ParametroSistema>(this.url + '/tempo-medio-sms', parametro, this.options());
    }

    atualizaParametroPqv(parametro: ParametroPqv) {
        return this.http.post<ParametroPqv>(this.url + '/configuracao-pqv', parametro, this.options());
    }

    public consultarConfiguracaoPqv(): any {
        return this.http.get<ParametroPqv[]>(this.url + '/consultar-configuracao-pqv', this.options());
    }

    public consultarHistorico(): Observable<HistoricoConsoleGestor[]> {
        return this.http.post<HistoricoConsoleGestor[]>(this.url + '/historico', null, this.options());
    }
}
