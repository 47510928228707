import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {MensagemEmail} from 'app/shared/models/administracao/parametrizacao/mensagem-email';

@Injectable()
export class MensagemEmailService extends CrudHttpClientService<MensagemEmail> {

  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/parametrizacao/mensagem-email', http);
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public ativarInativar(mensagemEmail: MensagemEmail): Observable<MensagemEmail> {
    return this.http.put<MensagemEmail>(this.url + "/ativar-inativar", mensagemEmail, this.options());
  }

  public alterarSituacaoLote(mensagemEmail: MensagemEmail[]): Observable<MensagemEmail> {
    return this.http.put<MensagemEmail>(this.url + "/ativar-inativar-lote", mensagemEmail, this.options());
  }

  public deleteLote(mensagemEmail: MensagemEmail[]): Observable<MensagemEmail> {
    return this.http.post<MensagemEmail>(this.url + "/excluir-lote", mensagemEmail, this.options());
  }

}
