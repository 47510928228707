import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'joinOpcoes' })
export class JoinOpcoesPipe implements PipeTransform {
	
	transform(value: any[]) {
		const valuesDesc = new Array();
		if (!!value && value.length > 0) {
			value.forEach((v: any) => {
				valuesDesc.push(v.descricao);
			});
			return valuesDesc.join(', ');
		}
		return '';
	}
}
