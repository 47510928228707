import { CategorizacaoOcorrenciaLote } from './../../../models/administracao/parametrizacao/categorizacao-ocorrencia-lote';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {CategorizacaoOcorrencia} from 'app/shared/models/administracao/parametrizacao/categorizacao-ocorrencia';
import {SelectItemModel} from 'app/shared/models/select-item';
import {TipoRelatorio} from 'app/arquitetura/shared/reports/tipo-relatorio';
import {RelatorioComFiltro} from 'app/shared/models/relatorio-com-filtro';

@Injectable()
export class CategorizacaoOcorrenciaService extends CrudHttpClientService<CategorizacaoOcorrencia> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('administracao/parametrizacao/categorizacao-ocorrencia', http);
  }

  consultar(nome: string, situacao: string): any {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('noAssunto', nome);
    httpParams = httpParams.set('icSituacao', situacao ? situacao : '');

    return this.http.get<CategorizacaoOcorrencia[]>(
      this.url + '/consultar',
      this.options({ params: httpParams })
    );
  }

  consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public salvarListaCategorias(lista: CategorizacaoOcorrencia[]): Observable<any> {
    return this.http.post(this.url + '/salvar-lista', lista, this.options());
  }

  public ativarInativar(categorizacaoOcorrencia: CategorizacaoOcorrencia): Observable<any> {
    return this.http.put<CategorizacaoOcorrencia>(this.url + '/ativar-inativar', categorizacaoOcorrencia, this.options());
  }

  public alterarEmLote(categorizacaoOcorrenciaLote: CategorizacaoOcorrenciaLote): Observable<any> {
    return this.http.put<CategorizacaoOcorrenciaLote>(this.url + '/alterar-lote', categorizacaoOcorrenciaLote, this.options());
  }

  public searchAutocomplete(categorizacaoOcorrencia: CategorizacaoOcorrencia): Observable<any> {
    return this.http.post(this.url + '/search-autocomplete/', categorizacaoOcorrencia, this.options());
  }

  public consultarSelectsAssuntoCategorizacao(idItem?: number): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    if (idItem) {
      httpParams = httpParams.set('idItem', idItem.toString());
    }

    return this.http.get<SelectItemModel[]>(this.url + '/select-assunto-categorizacao', this.options({ params: httpParams }));
  }

  public consultarSelectsProdutoCategorizacao(idProblema?: number): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    if (idProblema) {
      httpParams = httpParams.set('idProblema', idProblema.toString());
    }

    return this.http.get<SelectItemModel[]>(this.url + '/select-produto-categorizacao', this.options({ params: httpParams }));
  }

  public consultarSelectsItemCategorizacao(idAssunto?: number): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    if (idAssunto) {
      httpParams = httpParams.set('idAssunto', idAssunto.toString());
    }

    return this.http.get<SelectItemModel[]>(this.url + '/select-item-categorizacao', this.options({ params: httpParams }));
  }

  public consultarSelectsCategorizacao(idAssunto: number, idItem: number): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('idAssunto', idAssunto.toString());
    httpParams = httpParams.set('idItem', idItem.toString());

    return this.http.get<SelectItemModel[]>(this.url + '/select-categorizacao', this.options({ params: httpParams }));
  }

  public consultarCategorizacoesPorOcorrencia(idOcorrencia: number): Observable<CategorizacaoOcorrencia[]> {
    return this.http.get<CategorizacaoOcorrencia[]>(this.url + '/' + idOcorrencia + '/registro-ocorrencia', this.options());
  }

  public consultarCategorizacoesParaRegistroOcorrencia(idCategorizacao: number): Observable<CategorizacaoOcorrencia> {
    return this.http.get<CategorizacaoOcorrencia>(this.url + '/' + idCategorizacao + '/registro-ocorrencia', this.options());
  }

  public gerarRelatorioTabela(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
    const relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
    relatorioComFiltro.tipo = TipoRelatorio[tipo];
    relatorioComFiltro.filtro = filtro;

    return this.http.post(this.url + '/gerar-relatorio-tabela', relatorioComFiltro, this.options(
      { observe: 'response' as 'body', responseType: 'arraybuffer' }));
  }

  public ativarInativarLote(categorizacoes: CategorizacaoOcorrencia[]): Observable<any> {
    return this.http.put<CategorizacaoOcorrencia>(this.url + '/ativar-inativar-lote', categorizacoes, this.options());
  }

  public deleteLote(categorizacoes: CategorizacaoOcorrencia[]): Observable<any> {
    return this.http.post(this.url + '/excluir-lote', categorizacoes, this.options());
  }
}
