import {AbstractControl, FormGroup} from '@angular/forms';
import {StoredPagination} from '../models/stored-pagination';
import {NamedValue} from '../models/named-value';
import {FiltroPaginado} from '../models/filtroPaginado';

export class StoredPaginationUtil {

  public static readonly PAGINATION_SESSION_NAME: string = 'storedPagination';

  /** Amarzena informações sobre a última paginação do usuário. */
  public static storePagination(filtroPaginado: FiltroPaginado, form: FormGroup): void {
    this.clearStoredPagination();

    if (filtroPaginado && form) {

      const address: string = this.getCurrentAddress();
      const formValues: NamedValue[] = [];
      Object.keys(form.controls).forEach(key => {
        const formControl: AbstractControl = form.get(key);
        formValues.push(new NamedValue(key, formControl.value));
      });

      const pagination: StoredPagination = new StoredPagination(address, filtroPaginado, formValues);
      localStorage.setItem(StoredPaginationUtil.PAGINATION_SESSION_NAME, JSON.stringify(pagination));
    }
  }

  /** Recupera informações sobre a paginação da última consulta do usuário. */
  public static recoverPagination(): StoredPagination {
    const paginationJSON: string = localStorage.getItem(StoredPaginationUtil.PAGINATION_SESSION_NAME);

    let pagination: StoredPagination;
    if (paginationJSON) {
      pagination = StoredPagination.fromJSON(paginationJSON);

      if (!pagination.getAddress() || pagination.getAddress() !== this.getCurrentAddress()) {
        return null;
      }
    }
    return pagination;
  }

  public static setFiltroPaginadoId(filtroPaginado: FiltroPaginado): void {
    const paginationJSON: string = localStorage.getItem(StoredPaginationUtil.PAGINATION_SESSION_NAME);

    let pagination: StoredPagination;
    if (paginationJSON) {
      pagination = StoredPagination.fromJSON(paginationJSON);
      pagination = new StoredPagination(pagination.getAddress(), filtroPaginado, [{name: 'id', value: filtroPaginado.filtro.id}]);
      localStorage.setItem(StoredPaginationUtil.PAGINATION_SESSION_NAME, JSON.stringify(pagination));
    }
  }

  public static setFiltroPaginadoListId(filtroPaginado: FiltroPaginado): void {
    const paginationJSON: string = localStorage.getItem(StoredPaginationUtil.PAGINATION_SESSION_NAME);

    let pagination: StoredPagination;
    if (paginationJSON) {
      pagination = StoredPagination.fromJSON(paginationJSON);
      pagination = new StoredPagination(pagination.getAddress(), filtroPaginado, [{name: 'listId', value: filtroPaginado.filtro.listId}]);
      localStorage.setItem(StoredPaginationUtil.PAGINATION_SESSION_NAME, JSON.stringify(pagination));
    }
  }

  /** Limpa a session sobre a paginação da última consulta do usuário. */
  public static clearStoredPagination(): void {
    localStorage.removeItem(StoredPaginationUtil.PAGINATION_SESSION_NAME);
  }

  /** Obtém o endereço atual da página. */
  protected static getCurrentAddress(): string {
    return window.location.href;
  }

}
