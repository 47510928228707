import {Injectable} from '@angular/core';
import {SessaoService} from './../../arquitetura/shared/services/seguranca/sessao.service';
import {Router} from '@angular/router';
import {EnumTipoOcorrencia} from './../enums/EnumTipoOcorrencia';


@Injectable({ providedIn: 'root' })
export class BackgroundModuloUtils {

  private router: Router

  constructor(router: Router){
    this.router = router;
  }

  public getRotaAtual(): any{
    const rotaAtual = this.router.url
    if( rotaAtual.includes('ocorrencia-sac') || rotaAtual.includes('-sac')){
        return EnumTipoOcorrencia.SAC
    }else if(rotaAtual.includes('ocorrencia-ouvidoria') || rotaAtual.includes('-ouv')){
      return EnumTipoOcorrencia.OUVIDORIA
    }else if(rotaAtual.includes('ocorrencia-interna') || rotaAtual.includes('-viva-voz')){
      return EnumTipoOcorrencia.INTERNA
    }else{
      return null;
    }
  }

  public isOuvidoria(): boolean{
    return (SessaoService.usuario.idTipoOcorrencia == EnumTipoOcorrencia.OUVIDORIA || this.getRotaAtual() == EnumTipoOcorrencia.OUVIDORIA)
  }

  public isSAC(): boolean{
    return (SessaoService.usuario.idTipoOcorrencia == EnumTipoOcorrencia.SAC || this.getRotaAtual() == EnumTipoOcorrencia.SAC)
  }

  public isVivaVoz(): boolean{
    return (SessaoService.usuario.idTipoOcorrencia == EnumTipoOcorrencia.INTERNA || this.getRotaAtual() == EnumTipoOcorrencia.INTERNA)
  }
}
