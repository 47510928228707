import { EmpregadoSimplificado } from 'app/shared/models/administracao/parametrizacao/empregado-simplificado';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {EmpregadoVinculado} from 'app/shared/models/administracao/parametrizacao/empregado-vinculado';

@Injectable()
export class EmpregadoVinculadoService extends CrudHttpClientService<EmpregadoVinculado> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('administracao/parametrizacao/empregado-vinculado', http);
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar', filtro, this.options());
  }

  public consultarEmpregadosPorUnidade(nuUnidade: Number): Observable<any> {
    return this.http.post(this.url + '/consultar-empregados-por-unidade', nuUnidade, this.options());
  }

  public consultarEmpregadosPorMatricula(empregado: EmpregadoSimplificado): Observable<any> {
    return this.http.post(this.url + '/consultar-empregados-por-matricula', empregado, this.options());
  }

  searchAutocomplete(value: string): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }
}
