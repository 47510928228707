import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {ManualNormativo} from 'app/shared/models/ajuda/manual-normativo';

@Injectable()
export class ManualNormativoService extends CrudHttpClientService<ManualNormativo> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ajuda/manual-normativo', http);
  }

  public carregarManuaisNormativos(): any {
    return this.http.get<ManualNormativo[]>(this.url);
  }

  public ativarInativar(manual: ManualNormativo): Observable<any> {
    return this.http.put(this.url + '/ativar-inativar', manual, this.options());
  }

  consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  todosAtivos(): any {
    return this.http.get<ManualNormativo[]>(this.url + '/consultar-ativos', this.options());
  }

  todosAtivosModulo(idTipoOcorrencia: number): any {
    return this.http.get<ManualNormativo[]>(this.url + '/consultar-ativos/' + idTipoOcorrencia, this.options());
  }

}
