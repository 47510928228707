import {Component} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

import {MessageService} from '../message.service';
import {MessageItem} from '../message-item';

/**
 * Implementação de componente responsável por gerar a estrutura 'html' onde os alertas serão gerados.
 */
@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('void', style({
        'color': '#333',
        'opacity': '0.7'
      })),
      transition('* => *', animate('8000ms ease-in-out'))
    ])
  ]
})
export class AlertMessageComponent {
  public items: MessageItem[];
  public tempoEsconderMensagens = 6000;
  private messageService: MessageService;
  public isViewMsg = false;
  /**
   * Construtor da classe.
   *
   * @param messageService
   */
  constructor(messageService: MessageService) {
    this.items = [];
    this.isViewMsg = false;
    this.messageService = messageService;
    this.messageService.getMsgEmitter().subscribe(
      (item: any) => this.addMsgItem(item)
    );
  }

  /**
   * Remove o item de mensagem da visualização.
   *
   * @param messageItem
   */
  public removeMsg(messageItem: MessageItem): void {
    this.items = this.items.filter(item => item.getMsg() !== messageItem.getMsg());
  }

  /**
   * Adiciona o item de mensagem a visualização.
   *
   * @param messageItem
   */
  private addMsgItem(messageItem: MessageItem): void {
    let count = 0;
    this.isViewMsg = true;

    this.definirTempoMsg(messageItem);

    if (this.items) {
      count = this.items.filter(
        item => item.getMsg() === messageItem.getMsg()
      ).length;
    }

    if (count === 0) {
      this.items.push(messageItem);
    }

    // Retira as mensagens após 6 segundos
    setTimeout(() => {
      this.items = [];
    }, this.tempoEsconderMensagens);
  }

  public closeMsg(event, ever?):void {
    if(event.target.id == 'msggeral' || ever){
      this.isViewMsg = false;
    }
  }

  definirTempoMsg(messageItem: MessageItem) {

    if (messageItem.getType() == MessageItem.ALERT_TYPE_DANGER) {
      this.tempoEsconderMensagens = 8000;
    } else {
      this.tempoEsconderMensagens = 6000;
    }

  }


}
