import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {DadosProcon} from 'app/shared/models/administracao/parametrizacao/dados-procon';
import {SelectItemModel} from 'app/shared/models/select-item';

@Injectable()
export class DadosProconService extends CrudHttpClientService<DadosProcon> {
	constructor(protected http: HttpClient) {
		super('administracao/parametrizacao/dados-procon', http);
	}

	public searchAutocomplete(value: string): Observable<any> {
		return this.http.post(this.url + '/search-autocomplete/', value, this.options());
	}

	public consultarSelectItensAtivos(): Observable<any> {
		return this.http.get<SelectItemModel>(this.url + '/select-itens-ativos', this.options());
	}

	public ativarInativar(dadosProcon: DadosProcon): Observable<any> {
		return this.http.put<DadosProcon>(this.url + '/ativar-inativar', dadosProcon, this.options());
	}
}
