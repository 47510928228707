import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {SubsidioOcorrencia} from 'app/shared/models/ocorrencia/subsidio-ocorrencia';
import {Observable} from 'rxjs/Rx';


@Injectable()
export class SubsidioOcorrenciaService extends CrudHttpClientService<SubsidioOcorrencia> {

  constructor(
    protected http: HttpClient) {
    super('ocorrencia/subsidio-ocorrencia', http);
  }

  public incluirLote(subsidios: SubsidioOcorrencia[]): Observable<void> {
    return this.http.post<void>(this.url + '/incluir-lote', subsidios, this.options());
  }

}
