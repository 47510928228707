import {Component, Input} from '@angular/core';
import {Base} from '../../base';
import {MessageService} from '../../messages/message.service';

@Component({
  selector: 'app-required-label',
  templateUrl: './required-label.component.html',
  styleUrls: ['./required-label.component.css']
})
export class RequiredLabelComponent extends Base {

    @Input() title: string;

    constructor(
        protected messageService: MessageService,
    ) {
        super(messageService);
        this.title = '';
    }

}
