import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {SelectItemModel} from 'app/shared/models/select-item';
import {MotivoRetorno} from 'app/shared/models/administracao/parametrizacao/motivo-retorno';

@Injectable()
export class MotivoRetornoService extends CrudHttpClientService<MotivoRetorno> {
	constructor(protected http: HttpClient) {
		super('administracao/parametrizacao/motivoretorno', http);
	}

	public searchAutocomplete(nome: string, idTipoOcorrencia: number): Observable<any> {
		let httpParams: HttpParams = new HttpParams();
		httpParams = httpParams.set('nome', nome);
		httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
		return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
	}

	public todosAtivos(idTipoOcorrencia: number): any {
		return this.http.get<MotivoRetorno[]>(this.url + '/ativos/' + idTipoOcorrencia, this.options());
	}

	public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
		return this.http.post<SelectItemModel>(this.url + '/select-itens-tipo-ocorrencia', idTipoOcorrencia, this.options());
	}
}
