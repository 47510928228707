import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {PalavraProibida} from 'app/shared/models/administracao/parametrizacao/palavra-proibida';

@Injectable()
export class PalavraProibidaService extends CrudHttpClientService<PalavraProibida> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ajuda/palavra-proibida', http);
  }

  public carregarPalavraProibida(): any {
    return this.http.get<PalavraProibida[]>(this.url);
  }

  consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  consultarPorTipoOcorrencia(idTipoOcorrencia: number): any {
    return this.http.get<PalavraProibida[]>(this.url + '/consultar-por-tipo-ocorrencia/' + idTipoOcorrencia, this.options());
  }

  deletePalavra(palavraProibida: PalavraProibida): Observable<boolean> {
    return this.http.post<boolean>(this.url + '/delete-palavra/' , palavraProibida, this.options());
  }

}
