import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CrudHttpClientService } from "app/arquitetura/shared/services/crud-http-client.service";
import { Problema } from "app/shared/models/administracao/parametrizacao/problema";
import { Produto } from "app/shared/models/administracao/parametrizacao/produto";
import { FiltroPaginado } from "app/shared/models/filtroPaginado";
import { Feriado } from "app/shared/models/ocorrencia/feriado";
import { OcorrenciaExterna } from "app/shared/models/ocorrencia/ocorrencia-externa";
import { Observable } from "rxjs/Rx";
import { RegistroTarefaDTO } from "../../models/ocorrencia/registro-tarefa-dto";
import { SelectItemModel } from "./../../models/select-item";

@Injectable()
export class OcorrenciaOuvidoriaService extends CrudHttpClientService<OcorrenciaExterna> {
  constructor(protected http: HttpClient) {
    super("ocorrencia/ocorrencia-ouvidoria", http);
  }

  consultarOcorrenciasSolicitantePaginado(
    filtro: FiltroPaginado
  ): Observable<any> {
    return this.http.post(
      this.url + "/historico-paginado/",
      filtro,
      this.options()
    );
  }

  consultarQuantidadeOcorrenciaVencidaEDentroPrazo(
    filtro: FiltroPaginado
  ): Observable<any> {
    return this.http.post(
      this.url + "/quantidade-ocorrencia-vencida-e-dentro-prazo/",
      filtro,
      this.options()
    );
  }

  consultarQtdOcorrenciasSolicitante(cpfCnpj: number): Observable<any> {
    return this.http.get(this.url + "/quantidade-historico/" + cpfCnpj);
  }

  consultarPaginadoOcorrenciaExterna(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(
      this.url + "/consultar-paginado-ocorrencia-externa",
      filtro,
      this.options()
    );
  }

  consultarPaginadoOcorrenciaExternaTelefone(
    filtro: FiltroPaginado
  ): Observable<any> {
    return this.http.post(
      this.url + "/consultar-paginado-ocorrencia-telefone",
      filtro,
      this.options()
    );
  }

  responder(ocorrencia: OcorrenciaExterna) {
    return this.http.post(
      this.url + "/" + ocorrencia.id + "/responder",
      ocorrencia,
      this.options()
    );
  }

  salvarMinuta(ocorrenciaExterna: OcorrenciaExterna) {
    return this.http.post(
      this.url + "/salvar-minuta",
      ocorrenciaExterna,
      this.options()
    );
  }

  salvarMinutaRecurso(ocorrenciaExterna: OcorrenciaExterna) {
    return this.http.post(
      this.url + "/salvar-minuta-recurso",
      ocorrenciaExterna,
      this.options()
    );
  }

  consultarParecer(): Observable<any> {
    return this.http.get<SelectItemModel[]>(this.url + "/consultar-parecer");
  }

  consultarApontamento(): Observable<any> {
    return this.http.get<SelectItemModel[]>(
      this.url + "/consultar-apontamento"
    );
  }

  responderRecurso(ocorrencia: OcorrenciaExterna) {
    return this.http.post(
      this.url + "/responder-recurso",
      ocorrencia,
      this.options()
    );
  }

  avaliarOcorrencia(ocorrencia: OcorrenciaExterna) {
    return this.http.post(
      this.url + "/avaliar-ocorrencia",
      ocorrencia,
      this.options()
    );
  }

  pesquisaProdutoEstoqueOcorrenciaPorProblema(
    idProblema: number
  ): Observable<any> {
    return this.http.get<Produto[]>(this.url + "/produtos/" + idProblema);
  }

  pesquisaTodosProdutoEstoqueOcorrencia(): Observable<any> {
    return this.http.get<Produto[]>(this.url + "/produtos/");
  }

  pesquisaProblemaEstoqueOcorrenciaPorProduto(
    idProduto: number
  ): Observable<any> {
    return this.http.get<Problema[]>(this.url + "/problemas/" + idProduto);
  }

  pesquisaTodosProblemaEstoqueOcorrencia(): Observable<any> {
    return this.http.get<Problema[]>(this.url + "/problemas/");
  }

  penalizarOcorrencia(ocorrencia: OcorrenciaExterna) {
    return this.http.post(
      this.url + "/penalizar-ocorrencia",
      ocorrencia,
      this.options()
    );
  }

  cancelarOcorrencia(idOcorrencia: number) {
    return this.http.put(
      this.url + "/cancelar-ocorrencia",
      idOcorrencia,
      this.options()
    );
  }

  redistribuirOcorrencia(
    idsOcorrencia: number[],
    idEquipe: number,
    numeroMatricula: number
  ) {
    const paramMap = {
      idsOcorrencia: idsOcorrencia,
      idEquipe: idEquipe,
      numeroMatricula: numeroMatricula,
    };
    return this.http.post(`${this.url}/redistribuir`, paramMap, this.options());
  }

  priorizarOcorrencia(
    idOcorrencia: number,
    idEquipe: number,
    prioriza: string
  ) {
    return this.http.put(
      `${this.url}/priorizar/${idOcorrencia}/${idEquipe}/${prioriza}`,
      null,
      this.options()
    );
  }

  consultarPaginadoOcorrenciaSupervisorRedistribuir(
    filtro: FiltroPaginado
  ): Observable<any> {
    return this.http.post(
      this.url + "/consultar-paginado-ocorrencia-supervisor-redistribuir",
      filtro,
      this.options()
    );
  }

  obterDatasFeriados(feriado: Feriado) {
    return this.http.post<any>(
      `${this.url}/obter-data-prorrogacao/`,
      feriado,
      this.options()
    );
  }

  cancelarOcorrenciaJustificativa(idOcorrencia: number, justificativa: string) {
    return this.http.put(
      `${this.url}/cancelar-ocorrencia-justificativa/${idOcorrencia}/${justificativa}`,
      null,
      this.options()
    );
  }

  reabrirOcorrencia(idOcorrencia: number) {
    return this.http.put(this.url + "/reabrir-ocorrencia", idOcorrencia);
  }

  selecionaUnidade(idOcorrencia: number) {
    return this.http.post(`${this.url}/selecao-unidade`, idOcorrencia);
  }

  avaliarRespostaTarefa(idOcorrencia: number, idTarefa: number) {
    let paramMap = { idOcorrencia: idOcorrencia, idTarefa: idTarefa };
    return this.http.post(`${this.url}/avalia-resposta`, paramMap);
  }

  avaliarProrrogacaoTarefa(
    idOcorrencia: number,
    idTarefa: number,
    acatada: string
  ) {
    let paramMap = {
      idOcorrencia: idOcorrencia,
      idTarefa: idTarefa,
      acatada: acatada,
    };
    return this.http.post(`${this.url}/avalia-prorrogacao-tarefa`, paramMap);
  }

  salvarMovimentacaoAlteraCliente(ocorrencia: OcorrenciaExterna) {
    return this.http.post(`${this.url}/altera-dados-cliente`, ocorrencia);
  }

  salvarMovimentacaoCancelaTarefa(tarefa: RegistroTarefaDTO) {
    return this.http.post(
      `${this.url}/justificativa-cancelamento-tarefa`,
      tarefa
    );
  }

  consultarPaginadoOcorrenciaTerceirizado(
    filtro: FiltroPaginado
  ): Observable<any> {
    return this.http.post(
      this.url + "/consultar-paginado-ocorrencia-terceirizado",
      filtro,
      this.options()
    );
  }

  consultarTempoEmSituacao(idOcorrencia): Observable<any> {
    return this.http.get<any>(this.url + "/tempo-situacao/" + idOcorrencia);
  }

  consultarPorId(idOcorrencia: number): Observable<OcorrenciaExterna> {
    return this.http.get<OcorrenciaExterna>(`${this.url}/${idOcorrencia}`);
  }

  reenviarEmailCliente(ocorrenciaExterna: OcorrenciaExterna) {
    return this.http.post(`${this.url}/reenvia-resposta-cliente`, ocorrenciaExterna);
  }

  public removerClassificacaoOuv(ocorrencia: OcorrenciaExterna) {
    return this.http.post(this.url + '/remover-classificacao-ouv', ocorrencia, this.options());
  }

  exportarOcorrenciasSolicitanteXLS(filtro: FiltroPaginado) {
    return this.http.post(this.url + "/exportar-por-solicitante-xls/", filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }
}
