import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {JustificativaProrrogacao} from 'app/shared/models/administracao/parametrizacao/justificativa-prorrogacao';

@Injectable()
export class JustificativaProrrogacaoService extends CrudHttpClientService<JustificativaProrrogacao> {
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/parametrizacao/justificativa-prorrogacao', http);
  }

  public searchAutocomplete(nome: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('nome', nome);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());

    return this.http.get(`${this.url}/search-autocomplete/`, this.options({params: httpParams}));
  }

  public consultarTodos(): any {
    return this.http.get<JustificativaProrrogacao[]>(this.url, this.options());
  }

  public consultarPorTipoOcorrencia(idTipoOcorrencia: number): Observable<any> {
    return this.http.get<JustificativaProrrogacao[]>(this.url + '/consultar-tipo-ocorrencia/' + idTipoOcorrencia, this.options());
  }

}
