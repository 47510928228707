import {CustomTableCol} from './custom-table-col';
import {CustomTableRow} from './custom-table-row';
import {CustomTable} from './custom-table';

export class CustomTableCreator<T> {

  // INICIO - Funções para geração da tabela
  addHeader(id: string, text: string, width: number, clazz: any, style: any): CustomTableCol {
    return this.newCustomHeader(id, text, width, clazz, style);
  }

  addCol(idTable: string, index: number, idCol: string, text: any, clazz: any, style: any, object: any) {
    text = text + '';
    return this.newDefaultCustomTableCol(this.getIdColTable(idTable, index, idCol), text, clazz, style, object);
  }

  addRow(idTable: string, index: number, cols: CustomTableCol[], clazz: any, style: any, object: any) {
    return this.newDefaultCustomTableRow(this.getIdRowTable(idTable, index), cols, clazz, style, object);
  }

  addRowNone(idTable: string, index: number, cols: CustomTableCol[], clazz: any, style: any, object: any) {
    return this.newCustomTableRow(this.getIdRowTable(idTable, index), cols, clazz, style, false, false, false, false, null, null, object);
  }

  addRowAll(idTable: string, index: number, cols: CustomTableCol[], clazz: any, style: any, object: any) {
    return this.newDefaultAllCustomTableRow(this.getIdRowTable(idTable, index), cols, clazz, style, object);
  }

  addRowDetail(idTable: string, index: number, cols: CustomTableCol[], clazz: any, style: any, object: any) {
    return this.newDefaultDetailCustomTableRow(this.getIdRowTable(idTable, index), cols, clazz, style, null, object);
  }

  addRowDetailModal(idTable: string, index: number, cols: CustomTableCol[], clazz: any, style: any, idModal: string, object: any) {
    return this.newDefaultDetailCustomTableRow(this.getIdRowTable(idTable, index), cols, clazz, style, idModal, object);
  }

  addRowAllDetailModal(idTable: string, index: number, cols: CustomTableCol[], clazz: any, style: any, idModal: string, object: any) {
    return this.newDefaultAllDetailCustomTableRow(this.getIdRowTable(idTable, index), cols, clazz, style, idModal, object);
  }

  addRowDetailSelectModal(idTable: string, index: number, coluns: CustomTableCol[], clazz: any, style: any, idModal: string, object: any) {
    return this.newDefaultDetailSelectCustomTableRow(this.getIdRowTable(idTable, index), coluns, clazz, style, idModal, object);
  }

  addRowSelect(idTable: string, index: number, cols: CustomTableCol[], clazz: any, style: any, idModal: string, object: any) {
    return this.newDefaultSelectCustomTableRow(this.getIdRowTable(idTable, index), cols, clazz, style, idModal, object);
  }

  addRowDelete(idTable: string, index: number, cols: CustomTableCol[], clazz: any, style: any, object: any) {
    return this.newDefaultDeleteCustomTableRow(this.getIdRowTable(idTable, index), cols, clazz, style, object);
  }

  newCustomHeader(id: string, text: string, width: number, clazz: any, style: any): CustomTableCol {
    return this.newCustomTableCol(id, text, width, clazz, style, null);
  }

  newDefaultCustomTableCol(id: string, text: string, clazz: any, style: any, object: any): CustomTableCol {
    return this.newCustomTableCol(id, text, null, clazz, style, object);
  }

  newCustomTableCol(id: string, text: string, width: number, clazz: any, style: any, object: any): CustomTableCol {
    width = !width ? 100 : width;
    clazz = clazz === null ? '' : clazz;
    style = style === null ? '' : style;
    return new CustomTableCol(id, text, width, clazz, style, object);
  }

  newDefaultCustomTableRow(id: string, coluns: CustomTableCol[], clazz: any, style: any, object: any) {
    return this.newCustomTableRow(id, coluns, clazz, style, null, null, false, false, null, null, object);
  }

  newDefaultDetailCustomTableRow(id: string, coluns: CustomTableCol[], clazz: any, style: any, idModal: string, object: any) {
    return this.newCustomTableRow(id, coluns, clazz, style, false, false, null, false, null, idModal, object);
  }

  newDefaultAllDetailCustomTableRow(id: string, coluns: CustomTableCol[], clazz: any, style: any, idModal: string, object: any) {
    return this.newCustomTableRow(id, coluns, clazz, style, null, null, null, false, null, idModal, object);
  }

  newDefaultDetailSelectCustomTableRow(id: string, coluns: CustomTableCol[], clazz: any, style: any, idModal: string, object: any) {
    return this.newCustomTableRow(id, coluns, clazz, style, false, false, null, null, null, idModal, object);
  }

  newDefaultSelectCustomTableRow(id: string, coluns: CustomTableCol[], clazz: any, style: any, idModal: string, object: any) {
    return this.newCustomTableRow(id, coluns, clazz, style, false, false, false, null, null, idModal, object);
  }

  newDefaultDeleteCustomTableRow(id: string, coluns: CustomTableCol[], clazz: any, style: any, object: any) {
    return this.newCustomTableRow(id, coluns, clazz, style, false, null, false, false, null, null, object);
  }

  newDefaultAllCustomTableRow(id: string, coluns: CustomTableCol[], clazz: any, style: any, object: any) {
    return this.newCustomTableRow(id, coluns, clazz, style, null, null, null, false, null, null, object);
  }

  newCustomTableRow(id: string, coluns: CustomTableCol[], clazz: any, style: any, showEdit: boolean,
    showDelete: boolean, showDetail: boolean, showSelect: boolean, showHover: boolean, idModal: string,
    object: any) {
    showEdit = showEdit === null ? true : showEdit;
    showDelete = showDelete === null ? true : showDelete;
    showDetail = showDetail === null ? true : showDetail;
    showSelect = showSelect === null ? true : showSelect;
    showHover = showHover === null ? true : showHover;
    clazz = clazz === null ? '' : clazz;
    style = style === null ? '' : style;
    return new CustomTableRow(id, coluns, clazz, style, showEdit, showDelete, showDetail, showSelect, showHover,
      idModal, object);
  }

  newDefaultCustomTable(id: string, headers: CustomTableCol[], rows: CustomTableRow[], clazz: any, style: any,
    listObject: any[]): CustomTable {
    return this.newCustomTable(id, null, headers, rows, clazz, style, null, null, null, null, listObject);
  }

  newCustomTableNoPagination(id: string, headers: CustomTableCol[], rows: CustomTableRow[], clazz: any, style: any,
    listObject: any[]): CustomTable {
    return this.newCustomTable(id, null, headers, rows, clazz, style, false, null, null, null, listObject);
  }

  newCustomTableTitle(id: string, title: string, headers: CustomTableCol[], rows: CustomTableRow[], clazz: any, style: any,
    listObject: any[]): CustomTable {
    return this.newCustomTable(id, title, headers, rows, clazz, style, null, null, null, null, listObject);
  }

  newCustomTable(id: string, title: string, headers: CustomTableCol[], rows: CustomTableRow[], clazz: any, style: any, showPagination: boolean,
    numberPage: number, numberItems: number, total: number, listObject: any[]): CustomTable {
    numberPage = !numberPage ? 1 : numberPage;
    numberItems = !numberItems ? 5 : numberItems;
    total = !total ? listObject.length : total;
    clazz = clazz === null ? '' : clazz;
    style = style === null ? '' : style;
    showPagination = showPagination === null ? true : showPagination;
    return new CustomTable(id, title, headers, rows, clazz, style, showPagination, numberPage, numberItems, total, listObject);
  }

  getIdRowTable(idTable: string, index: number): string {
    return idTable + '_row_' + index;
  }

  getIdColTable(idTable: string, index: number, idCol: string): string {
    return this.getIdRowTable(idTable, index) + '_col_' + idCol;
  }
  // FIM - Funções para geração da tabela

}
