import { InativarFormaDTO } from './../../../models/administracao/parametrizacao/inativar-forma-dto';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {CrudHttpClientService} from "app/arquitetura/shared/services/crud-http-client.service";
import {FormaRecebimentoResposta} from 'app/shared/models/administracao/parametrizacao/forma-recebimento-resposta';
import {SelectItemModel} from "app/shared/models/select-item";
import {Observable} from "rxjs";
import { FormaRecebimentoVinculo } from "../../../models/administracao/parametrizacao/forma-recebimento-vinculo-dto";
import {
    CadastrarVinculoFormaRecebimentoRequest
} from "../../../models/administracao/parametrizacao/cadastrar-vinculo-forma-recebimento-request-dto";

@Injectable()
export class FormaRecebimentoRespostaService extends CrudHttpClientService<FormaRecebimentoResposta> {
  constructor(protected http: HttpClient) {
    super('administracao/parametrizacao/formarecebimentoresposta', http);
  }

  public searchAutocomplete(nome: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('nome', nome);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

  public todosAtivos(idTipoOcorrencia: number): any {
		return this.http.get<FormaRecebimentoResposta[]>(this.url + '/ativos/idTipoOcorrencia' + idTipoOcorrencia, this.options());
	}

	public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
		return this.http.get<SelectItemModel>(this.url + '/select-itens-tipo-ocorrencia/' + idTipoOcorrencia, this.options());
	}

  public ativarInativar(formaRecebimentoResposta: FormaRecebimentoResposta) {
    return this.http.put(this.url + '/ativar-inativar', formaRecebimentoResposta, this.options());
  }

  public ativarInativarV2(inativarFormaDTO: InativarFormaDTO) {
    return this.http.put(this.url + '/ativar-inativar-v2', inativarFormaDTO, this.options());
  }


  public consultarVinculos(idForma: number): Observable<any> {
		return this.http.get<FormaRecebimentoVinculo[]>(this.url + '/consultar-vinculos/' + idForma, this.options());
	}

  public cadastrarVinculo(novoVinculo: CadastrarVinculoFormaRecebimentoRequest): Observable<any> {
    return this.http.post(this.url + '/cadastrar-vinculo', novoVinculo, this.options());
  }

  public excluirVinculo(vinculo: FormaRecebimentoVinculo): Observable<any> {
    return this.http.post(this.url + '/excluir-vinculo', vinculo, this.options());
  }

    public consultarFormaPeloIdOrigem(idOrigem: number): Observable<any> {
        return this.http.get<FormaRecebimentoVinculo[]>(this.url + '/consultar-forma/' + idOrigem, this.options());
    }
}
