import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {PedidoCliente} from 'app/shared/models/administracao/parametrizacao/pedido-cliente';
import {SelectItemModel} from 'app/shared/models/select-item';

@Injectable()
export class PedidoClienteService extends CrudHttpClientService<PedidoCliente> {
	constructor(protected http: HttpClient) {
		super('administracao/parametrizacao/pedido-cliente', http);
	}

  public searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

  public searchAutocompleteObjects(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete-objects/', this.options({params: httpParams}));
  }

	public todosAtivos(): any {
		return this.http.get<PedidoCliente[]>(this.url + '/ativos', this.options());
	}

	public consultarSelectItens(perfis: number[]): Observable<any> {
		return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis', perfis, this.options());
	}

    public excluirPedidoOcorrencia(idPedido: number, idOcorrencia: number): Observable<any> {
        return this.http.delete(this.url + '/pedido-ocorrencia/'+idPedido+'/'+idOcorrencia, this.options());
    }

    public excluirPedidoReabertura(idPedido: number, idOcorrencia: number): Observable<any> {
        return this.http.delete(this.url + '/pedido-reabertura/'+idPedido+'/'+idOcorrencia, this.options());
    }
}
