import {DatePipe} from '@angular/common';
import { NomeSituacaoOcorrencia } from 'app/shared/enums/nome-situacao-ocorrencia.enum';
import { Origem } from 'app/shared/models/administracao/parametrizacao/origem';
import { OcorrenciaExterna } from 'app/shared/models/ocorrencia/ocorrencia-externa';
import { SelectItem } from 'primeng/primeng';
import {isNullOrUndefined} from 'util';

export class Util {

    public static pt_BR = {
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'manio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        today: 'Hoje',
        clear: 'Limpar'
    };

    private static contadorParaDiaAbertura: number = 0;

    public static isDefined(dado: any): boolean {
        return (dado) && (dado !== 'undefined') && (dado !== 'null');
    }

    public static isBlank(dado: any): boolean {
        const text: string = dado + '';
        return !text.length || !text.trim().length;
    }

    public static isEmpty(dado: any): boolean {
        return (!Util.isDefined(dado)) || (this.isBlank(dado));
    }

    public static notIsEmpty(dado: any): boolean {
        return !Util.isEmpty(dado);
    }

    public static dateToString(d: Date): string {
        const datePipe = new DatePipe('en-US');

        return datePipe.transform(d, 'yy-MM-dd\'T\'HH:mm:ss');
    }

    public static dateToStringEnUsWithoutHour(d: Date): string {
        const datePipe = new DatePipe('en-US');

        return datePipe.transform(d, 'yyyy-MM-dd');
    }

    public static dateToStringBr(d: Date): string {
        const datePipe = new DatePipe('pt-BR');
        return datePipe.transform(d, 'dd/MM/yyyy');
    }

    public static getHour(d: Date): string {
        const datePipe = new DatePipe('pt-BR');
        return datePipe.transform(d, 'HH:mm:ss');
    }

    public static diffMeses(inicio, fim, considerarDia = true) {
        let meses = this.ajustaMesAno(fim) - this.ajustaMesAno(inicio);

        if (considerarDia && inicio.getDate() + 1 > fim.getDate()) {
            meses--;
        }

        return meses;
    }

    public static ajustaMesAno(data) {
        return data.getFullYear() * 12 + data.getMonth();
    }

    public static convertSecsToMins(time: number): number {
        return Math.floor(time / 60);
    }

    /**
     * Converte o JSON para String
     * @param entity
     */
    public static objetoParaJson(entity: any): string {
        return JSON.stringify(entity);
    }

    /**
     * Converte a String no formato JSON para Objeto
     * @param entity
     */
    public static jsonParaObjeto(str: string): any {
        return JSON.parse(str);
    }

    public static sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * dateToJson - Converte a Data para objeto Json
     * @param date
     */
    public static dateToJson(d: Date) {
        return {
            date: {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            }
        };
    }

    /**
     * Converte a string de SIM ('S') e NAO ('N') para boolean.
     * @param
     */
    public static convertSNtoBool(valor: any): boolean {
        if (typeof valor === 'boolean') {
            return valor;
        }
        if (typeof valor === 'string') {
            return valor === 'S';
        }
        return false;
    }

    /**
     * Converte o boolean para string de SIM ('S') e NAO ('N').
     * @param
     */
    public static convertBoolToSN(valor: any): string {
        const sim = 'S';
        const nao = 'N';
        if (typeof valor === 'boolean') {
            return valor ? sim : nao;
        }
        if (typeof valor === 'string') {
            return valor === 'true' ? sim : nao;
        }
        return nao;
    }

    public static removerHtml(value: string): string {
        if (isNullOrUndefined(value)) {
            return '';
        }
        return value.replace(/<[^>]*>?/gm, '');
    }

    public static isString(value): boolean {
        return typeof value === 'string' || value instanceof String;
    }

    public static somenteNumeros(value: string): string {
        return value.replace(/\D/g, '');
    }

    public static zeroFill(value: number, width: number) {
        width -= value.toString().length;
        if (width > 0) {
            return new Array(width + (/\./.test(value + '') ? 2 : 1)).join('0') + value;
        }
        return value + ''; // always return a string
    }


    public static utf8Decode(utf8String): string {

        if (typeof utf8String != 'string') {
            throw new TypeError('parameter ‘utf8String’ is not a string');
        }
        // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
        const unicodeString = utf8String.replace(
            /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
            function (c) {  // (note parentheses for precedence)
                let cc = ((c.charCodeAt(0) & 0x0f) << 12) | ((c.charCodeAt(1) & 0x3f) << 6) | (c.charCodeAt(2) & 0x3f);
                return String.fromCharCode(cc);
            }
        ).replace(
            /[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
            function (c) {  // (note parentheses for precedence)
                let cc = (c.charCodeAt(0) & 0x1f) << 6 | c.charCodeAt(1) & 0x3f;
                return String.fromCharCode(cc);
            }
        );
        return unicodeString;
    }

    public static clone(object: any): any {
        return JSON.parse(JSON.stringify(object));
    }

    public static cpfValido(cpf: string): boolean {
        if (cpf == null) {
            return false;
        }
        if (cpf.length != 11) {
            return false;
        }
        if ((cpf == '00000000000') ||
            (cpf == '22222222222') ||
            (cpf == '33333333333') ||
            (cpf == '44444444444') ||
            (cpf == '55555555555') ||
            (cpf == '66666666666') ||
            (cpf == '77777777777') ||
            (cpf == '88888888888') ||
            (cpf == '99999999999')) {
            return false;
        }

        let numero: number = 0;
        let caracter: string = '';
        let numeros: string = '0123456789';
        let j: number = 10;
        let somatorio: number = 0;
        let resto: number = 0;
        let digito1: number = 0;
        let digito2: number = 0;
        let cpfAux: string = '';
        cpfAux = cpf.substring(0, 9);
        for (let i: number = 0; i < 9; i++) {
            caracter = cpfAux.charAt(i);
            if (numeros.search(caracter) == -1) {
                return false;
            }
            numero = Number(caracter);
            somatorio = somatorio + (numero * j);
            j--;
        }
        resto = somatorio % 11;
        digito1 = 11 - resto;
        if (digito1 > 9) {
            digito1 = 0;
        }
        j = 11;
        somatorio = 0;
        cpfAux = cpfAux + digito1;
        for (let i: number = 0; i < 10; i++) {
            caracter = cpfAux.charAt(i);
            numero = Number(caracter);
            somatorio = somatorio + (numero * j);
            j--;
        }
        resto = somatorio % 11;
        digito2 = 11 - resto;
        if (digito2 > 9) {
            digito2 = 0;
        }
        cpfAux = cpfAux + digito2;
        if (cpf != cpfAux) {
            return false;
        }
        else {
            return true;
        }
    }

    public static format(texto: string, argumentos: any[]): string {
        let textoFormatado = '';
        if (texto && argumentos.length > 0) {
            argumentos.forEach((value, index) => {
              textoFormatado = texto.replace(new RegExp('\\{' + index + '\\}', 'g'), value);
              texto = textoFormatado;
            });
        }
        return textoFormatado;
    }

    public static replaceFirstZero(value: string): any {
        if (value) {
            value =  value.replace(/^(-?)0+/, '').match(/[1-9]?[0-9]*/)[0];
            return value;
        }
    }

    public static dateToLocaleDateString(data: Date): string {
        if (data) {
          return Util.isString(data) ? new Date(data).toLocaleDateString() : data.toLocaleDateString();
        }
        return '';
    }

    public static retirarMascaraIdentificacaoOcorrencia(infoOcorrencia: number): string {
        if (infoOcorrencia) {
          return infoOcorrencia.toString().trim().toUpperCase().replace('DDMMAA9999999', '');
        }
        return '';
    }

    public static retornaValorOrUndefined(obj: any, attribute: string) {
      if (obj) {
        return isNullOrUndefined(obj[attribute]) ? undefined : obj[attribute];
      }

      return undefined;
    }


    /**
     * Calcula a pascoa com base no ano
     * @param ano
     * @returns Date
     */
    public static calcularPascoa(ano: number): Date {
      let X = 0;
      let Y = 0;

      if (ano >= 1900 && ano <= 2099) {
          X = 24;
          Y = 5;
      } else if (ano >= 2100 && ano <= 2199) {
          X = 24;
          Y = 6;
      } else if (ano >= 2200 && ano <= 2299) {
          X = 25;
          Y = 7;
      }

      let a = ano % 19;
      let b = ano % 4;
      let c = ano % 7;
      let d = (19 * a + X) % 30;
      let e = (2 * b + 4 * c + 6 * d + Y) % 7;

      let dia: number;
      let mes: number;

      if (d + e < 10) {
          dia = d + e + 22;
          mes = 3; // março
      } else {
          dia = d + e - 9;
          mes = 4; // abril
          if (dia == 26) {
              dia = 19;
          }
          if (dia == 25 && d == 28 && e == 6 && a > 10) {
              dia = 18;
          }
      }

      return new Date(ano, mes - 1, dia);
  }

  /**
   * Função que verifica se uma data é um feriado nacional brasileiro
   * Fonte: https://www.calendarr.com/brasil/calendario-2023/
   * @param date Date
   * @return boolean
   */
   public static isHoliday(date: Date): boolean {
      /**
       *  Lista de feriados fixos
       */
      const holidays: Date[] = [
          new Date(date.getFullYear(), 0, 1), // Ano Novo
          new Date(date.getFullYear(), 3, 21), // Tiradentes
          new Date(date.getFullYear(), 4, 1), // Dia do Trabalho
          new Date(date.getFullYear(), 8, 7), // Independência do Brasil
          new Date(date.getFullYear(), 9, 12), // Nossa Senhora Aparecida
          new Date(date.getFullYear(), 10, 2), // Finados
          new Date(date.getFullYear(), 10, 15), // Proclamação da República
          new Date(date.getFullYear(), 11, 25), // Natal
      ];

      // Data da Páscoa
      const pascoa: Date = this.calcularPascoa(date.getFullYear());

      // Lista de feriados móveis
      const moveableHolidays: Date[] = [
          pascoa, // Páscoa
          new Date(pascoa.getTime() - 48 * 60 * 60 * 1000), // Sexta-feira Santa
          new Date(pascoa.getTime() + 60 * 24 * 60 * 60 * 1000), // Corpus Christi
          new Date(pascoa.getTime() - 47 * 24 * 60 * 60 * 1000), // Carnaval
      ];

      // Verifica se a data é igual a algum dos feriados fixos ou móveis
      for (let holiday of holidays.concat(moveableHolidays)) {
          if (
              date.getDate() === holiday.getDate() &&
              date.getMonth() === holiday.getMonth() &&
              date.getFullYear() === holiday.getFullYear()
          ) {
              return true; // É feriado
          }
      }

      return false; // Não é feriado
  }

  /**
   * Verifica se não é final de semana ou feriado
   * em caso de dias uteis
   * @param dataFinal
   * @returns boolean
   */
  public static isDiaUlteis(dataFinal: Date): boolean {
    return (
      dataFinal.getDay() !== 0 &&
      dataFinal.getDay() !== 6 &&
      !Util.isHoliday(dataFinal)
    );
  }


  /**
   *  Calculo do prazo final
   * @param dataAbertura: Date
   * @param origem: Origem
   * @param ocorrencia?: OcorrenciaExterna
   * @returns Date
   */
   public static calcularDataFinal(dataAbertura: Date, origem: Origem, ocorrencia?: OcorrenciaExterna): Date {

    // Cria uma cópia da data inicial
    let dataFinal: Date = new Date(dataAbertura.getTime());
    // Define se o incremento é positivo ou negativo
    let incremento: number = origem.prazoResposta > 0 ? 1 : -1;
    // Contador de dias adicionados ou subtraídos
    let contador: number = origem.isDiaAbertura === 'S' ? 1 : 0;

    if((ocorrencia && (ocorrencia.nomeSituacao == NomeSituacaoOcorrencia.REABERTA))) {
      const dt = ocorrencia.prazoFinal.split('-')
      const ano = Number(dt[0]);
      const mes = Number(dt[1])
      const dia = Number(dt[2])
      dataFinal = new Date(ano, mes, dia);
    }


    if (origem.isDiaAbertura === "N") {
        this.contadorParaDiaAbertura = 1;
      // Se não deve contar o dia de abertura
      if (origem.tipoPrazoResposta === 'U' || origem.tipoPrazoReabertura === 'U') {
        // Se a contagem é por dia útil
        if (Util.isDiaUlteis(dataFinal)) {
          // Se a data inicial não é domingo, sábado ou feriado
          contador += incremento;
        }
      } else {
        // Se a contagem é por dia corrido
        contador += incremento;
      }
    }

    while (contador !== origem.prazoResposta + this.contadorParaDiaAbertura) {
      // Enquanto o contador não atingir o número de dias desejado
      dataFinal.setDate(dataFinal.getDate() + incremento);
      if (origem.tipoPrazoResposta === 'U' || origem.tipoPrazoReabertura === 'U') {
        // Se a contagem é por dia útil
        if (Util.isDiaUlteis(dataFinal)) {
          // Se a data final não é domingo, sábado ou feriado
          contador += incremento;
        }
      } else {
        // Se a contagem é por dia corrido
        contador += incremento;
      }
    }

    if (origem.tipoPrazoResposta === 'C') {
      if (dataFinal.getDay() === 0) {
        dataFinal.setDate(dataFinal.getDate() - 1);
      }
      if (dataFinal.getDay() === 6) {
        dataFinal.setDate(dataFinal.getDate() - 2);
      }
    }

    // Retorna a data final calculada
    return dataFinal;
  }

  /**
   * Verifica se não é final de semana ou feriado
   * em caso de dias uteis
   * @param values
   * @returns SelectItem[]
   */
  public static ordenaSelectItem(values: SelectItem[]): SelectItem[] {
    return values.sort(function(a,b) {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
    });
  }


  public static validarEmail(email: any): boolean {
    const check=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(check.test(email.value)){
      return true;
    }
    else {
      return false;
    }

  }




}
