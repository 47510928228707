import {AnexoOcorrencia} from 'app/shared/models/ocorrencia/anexo-ocorrencia';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {CrudHttpClientService} from "../../../arquitetura/shared/services/crud-http-client.service";

//SERVICE SOMENTE APENAS PARA ANEXO OCORRENCIA OUVIDORIA
@Injectable()
export class AnexoOcorrenciaOuvidoriaService extends CrudHttpClientService<AnexoOcorrencia> {

    constructor(protected http: HttpClient) {
        super('ocorrencia/ocorrencia-ouvidoria', http);
    }

    private anexoExcluidoSubject$ = new Subject<AnexoOcorrencia>();
    anexoExcluido$ = this.anexoExcluidoSubject$.asObservable();

    private anexosSubsidiariaOcorrenciaSubject$ = new Subject<AnexoOcorrencia[]>();
    anexosSubsidiaria$ = this.anexosSubsidiariaOcorrenciaSubject$.asObservable();

    emitExcluirAnexos(anexoOcorrenciaExcluido: AnexoOcorrencia) {
        this.anexoExcluidoSubject$.next(anexoOcorrenciaExcluido);
    }

    emiteAnexosSubsidiariaOcorrenciaSubject(anexosOcorrencia: AnexoOcorrencia[]) {
        this.anexosSubsidiariaOcorrenciaSubject$.next(anexosOcorrencia);
    }

    validarArquivoCarta(arquivo: AnexoOcorrencia): Observable<boolean> {
        return this.http.post<boolean>(this.url+'/validar-anexo', arquivo, this.options());
    }

}