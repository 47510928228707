import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'situacaoSolicitacaoUnidade' })
export class SituacaoSolicitacaoUnidadePipe implements PipeTransform {
	transform(value: string) {
		if (value === 'R') { return 'Não Acatada - Permite Réplica'; }
		if (value === 'N') { return 'Não Acatada'; }
		if (value === 'E') { return 'Em Análise'; }
		if (value === 'T') { return 'Transferido'; }
		if (value === 'A') { return 'Acatada'; }
		if (value === 'C') { return 'Não Contestada'; }
		return value;
	}
}
