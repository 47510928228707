import {EnumTipoOcorrencia} from './../../../../shared/enums/EnumTipoOcorrencia';
import {MenuItem} from 'primeng/primeng';
import {PerfilModulo} from 'app/shared/models/perfil-modulo';
import {SessaoService} from '../../services/seguranca/sessao.service';
import {MenuLateralComponent} from '../menu-lateral/menu-lateral.component';
import {PerfilUtils} from '../../util/perfil-utils';

export class ItensMenuOuvidoria {

    constructor(
      private menuParent: MenuLateralComponent,
      private sessaoService: SessaoService
    ) {}

    private hasPermission(roles: string[]): boolean {
        let isApresenta: boolean = false;
        let usuarioExterno = this.sessaoService.getUsuario().usuarioExterno;
        roles.forEach(role => {
            if (this.sessaoService.getPerfilUsuarioSelecionado().perfil === role
                || (role === 'SISOU_UNID_CAIXA' && !usuarioExterno)) {
                isApresenta = true;
            }
        });
        return isApresenta;
    }

    public mostraRegistrar(){
        if(
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_ESTRATEGIA' ||
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'SISOU_UNID_CAIXA' ||
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_2INSTANCIA' ||
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_SUPERVISOR' ||
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_CURADORIA' ||
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_GESTORSISTEMA'
        ){
          return true;
        }else{
          return false;
        }
    }

    public mostraResponder(){
        if(
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_ESTRATEGIA' ||
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_OPERADOR' ||
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_2INSTANCIA' ||
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_SUPERVISOR' ||
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_CURADORIA' ||
        this.sessaoService.getPerfilUsuarioSelecionado().perfil == 'OUV_GESTORSISTEMA'
        ){
          return true;
        }else{
          return false;
        }
    }

    public get itens(): MenuItem[] {
        return [
            // Ouvidoria
            {
                label: "Ouvidoria",
                icon: "fa fa-cogs",
                visible: this.hasPermission([
                    PerfilModulo.OUV_GESTORSISTEMA.value,
                    PerfilModulo.OUV_CURADORIA.value,
                    PerfilModulo.OUV_ESTAGIARIOMENOR.value,
                    PerfilModulo.OUV_ESTRATEGIA.value,
                    PerfilModulo.OUV_OPERADOR.value,
                    PerfilModulo.OUV_SUPERVISOR.value,
                    PerfilModulo.SISOU_UNID_CAIXA.value,
                    PerfilModulo.SOU_TERCEIRIZADAGE.value,
                    PerfilModulo.SOU_TERCEIRIZADAOP.value,
                ]),
                items: [
                    {
                        label: "Parametrização",
                        icon: "fa fa-angle-double-right",
                        visible: this.hasPermission([
                            PerfilModulo.OUV_GESTORSISTEMA.value,
                            PerfilModulo.OUV_CURADORIA.value,
                            PerfilModulo.OUV_SUPERVISOR.value]),
                        items: [
                            {
                                label: "Tabelas de Domínio",
                                icon: "fa fa-angle-double-right",
                                visible: this.hasPermission([
                                    PerfilModulo.OUV_GESTORSISTEMA.value,
                                    PerfilModulo.OUV_CURADORIA.value]),
                                items: [
                                    {
                                        label: "Origem",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/origem/",
                                        visible: this.hasPermission([PerfilModulo.OUV_GESTORSISTEMA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Natureza",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/natureza/",
                                        visible: this.hasPermission([PerfilModulo.OUV_GESTORSISTEMA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Produto",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/produto/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_CURADORIA.value,
                                            PerfilModulo.OUV_GESTORSISTEMA.value
                                        ]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Problema",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/problema/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_CURADORIA.value,
                                            PerfilModulo.OUV_GESTORSISTEMA.value
                                        ]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Canal",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/canal/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_CURADORIA.value,
                                            PerfilModulo.OUV_GESTORSISTEMA.value
                                        ]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Pedido do Cliente",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/pedido-cliente/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value
                                        ]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Problema de Comunicação",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/problema-comunicacao/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                            PerfilModulo.OUV_CURADORIA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Tipo de Atendimento 0800",
                                        icon: "fa fa-angle-double-right",
                                        routerLink:
                                            "/administracao/parametrizacao/tipo-atendimento-zero-oitocentos/",
                                        visible: this.hasPermission([PerfilModulo.OUV_GESTORSISTEMA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                      label: "Tipo de Atendimento Procon",
                                      icon: "fa fa-angle-double-right",
                                      routerLink: "/administracao/parametrizacao/tipo-atendimento-procon/",
                                      visible: this.hasPermission([
                                          PerfilModulo.OUV_GESTORSISTEMA.value,
                                          PerfilModulo.OUV_CURADORIA.value]),
                                      command: event => {
                                          this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                      }
                                    },
                                    {
                                        label: "Terceirizada",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/terceirizada/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                            PerfilModulo.OUV_CURADORIA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                      label: "Habilitar Equipe Tratamento",
                                      icon: "fa fa-angle-double-right",
                                      routerLink: "/administracao/parametrizacao/habilitar-equipe-tratamento/",
                                      visible: this.hasPermission([
                                        PerfilModulo.OUV_GESTORSISTEMA.value,
                                        PerfilModulo.OUV_SUPERVISOR.value]),
                                      command: event => {
                                          this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                      }
                                  }
                                ]
                            },
                            {
                                label: "Tabelas de Vínculos",
                                icon: "fa fa-angle-double-right",
                                visible: this.hasPermission([
                                    PerfilModulo.OUV_GESTORSISTEMA.value,
                                    PerfilModulo.OUV_CURADORIA.value,
                                    PerfilModulo.OUV_SUPERVISOR.value]),
                                items: [
                                    {
                                        label: "Categorização",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/categorizacao-ocorrencia-ouvidoria/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_CURADORIA.value,
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                        ]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Equipe Tratamento",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/equipe-tratamento/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_CURADORIA.value,
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                            PerfilModulo.OUV_SUPERVISOR.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Forma de Recebimento de Resposta",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/forma-recebimento-resposta-vinculo/",
                                        visible: this.hasPermission([PerfilModulo.OUV_GESTORSISTEMA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Orientação Atendimento",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/orientacao-atendimento/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_CURADORIA.value,
                                            PerfilModulo.OUV_GESTORSISTEMA.value
                                        ]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Correio Eletrônico",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/mensagem-email/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                            PerfilModulo.OUV_CURADORIA.value
                                        ]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Template",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/template/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_CURADORIA.value,
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                        ]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Dados de Procon",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/dados-procon/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                            PerfilModulo.OUV_CURADORIA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Posto de Atendimento Procon",
                                        icon: "fa fa-angle-double-right",
                                        routerLink:
                                            "/administracao/parametrizacao/posto-atendimento-procon/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                            PerfilModulo.OUV_CURADORIA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "SMS",
                                        icon: "fa fa-angle-double-right",
                                        routerLink:
                                            "/administracao/parametrizacao/sms/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Unidade Bloqueada",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/unidade-bloqueada-so/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_CURADORIA.value,
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                        ]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                ]
                            },
                            {
                                label: "Tabelas Acessórias",
                                icon: "fa fa-angle-double-right",
                                visible: this.hasPermission([
                                    PerfilModulo.OUV_GESTORSISTEMA.value,
                                    PerfilModulo.OUV_CURADORIA.value]),
                                items: [
                                    {
                                        label: "Penalidade",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/penalidade-ouv/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Causa Raiz",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/especificacoes/causa-raiz/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                            PerfilModulo.OUV_CURADORIA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: 'Justificativa de Prorrogação',
                                        icon: 'fa fa-angle-double-right',
                                        routerLink: '/administracao/parametrizacao/justificativa-prorrogacao/',
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_CURADORIA.value,
                                            PerfilModulo.OUV_GESTORSISTEMA.value]),
                                        command: (event) => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Palavra Proibida",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/palavra-proibida/",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                            PerfilModulo.OUV_CURADORIA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Motivo Retorno",
                                        icon: "fa fa-angle-double-right",
                                        routerLink: "/administracao/parametrizacao/motivo-retorno/",
                                        visible: this.hasPermission([PerfilModulo.OUV_GESTORSISTEMA.value,
                                            PerfilModulo.OUV_CURADORIA.value]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    },
                                    {
                                        label: "Ajuda",
                                        icon: "fa  fa-question-circle",
                                        routerLink: "/subsidiaria/ajuda",
                                        visible: this.hasPermission([
                                            PerfilModulo.OUV_GESTORSISTEMA.value,
                                            PerfilModulo.OUV_CURADORIA.value,
                                        ]),
                                        command: event => {
                                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        label: "Console Atendente",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/ocorrencia/ocorrencia-ouvidoria",
                        visible: this.hasPermission([PerfilModulo.OUV_OPERADOR.value]),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Console Supervisor",
                        icon: "fa fa-angle-double-right",
                        routerLink:
                            "/ocorrencia/ocorrencia-ouvidoria/supervisor",
                        visible: this.hasPermission([
                            PerfilModulo.OUV_SUPERVISOR.value,
                            PerfilModulo.OUV_CURADORIA.value]),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Console Terceirizada",
                        icon: "fa fa-angle-double-right",
                        routerLink:
                            "/ocorrencia/ocorrencia-ouvidoria/terceirizado",
                        visible: this.hasPermission([
                            PerfilModulo.OUV_GESTORSISTEMA.value,
                            PerfilModulo.SOU_TERCEIRIZADAGE.value,
                            PerfilModulo.SOU_TERCEIRIZADAOP.value,
                            PerfilModulo.SISOU_UNID_CAIXA.value
                        ]),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Administrar Web Service",
                        icon: "fa fa-angle-double-right",
                        visible: this.hasPermission([
                                PerfilModulo.OUV_GESTORSISTEMA.value,
                                PerfilModulo.OUV_SUPERVISOR.value
                            ]
                        ),
                        items: [
                            {
                                label: "Console Rotina Correios",
                                icon: "fa fa-angle-double-right",
                                routerLink: "/ocorrencia/ocorrencia-ouvidoria/consulta-correio",
                                visible: this.hasPermission([PerfilModulo.OUV_GESTORSISTEMA.value,
                                    PerfilModulo.OUV_SUPERVISOR.value]),
                                command: event => {
                                    this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                }
                            },
                            {
                                label: "Console Rotina BACEN",
                                icon: "fa fa-angle-double-right",
                                routerLink: "/administracao/rdr-bacen",
                                visible: this.hasPermission([PerfilModulo.OUV_GESTORSISTEMA.value]),
                                command: event => {
                                    this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                }
                            },
                            {
                                label: "Consultar Protocolo STA",
                                icon: "fa fa-angle-double-right",
                                routerLink: "/ocorrencia/satisfacao-sta",
                                visible: this.hasPermission([PerfilModulo.OUV_GESTORSISTEMA.value]),
                                command: event => {
                                    this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                                }
                            }]
                    },
                    {
                        label: "Console Gestor Sistema",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/ocorrencia/ocorrencia-ouvidoria/gestor",
                        visible: this.hasPermission([
                                PerfilModulo.OUV_GESTORSISTEMA.value
                            ]
                        ),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Tratar Tarefa Ouvidoria",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/tratar-ocorrencia/tratar-ocorrencia-ouv",
                        visible: this.hasPermission([PerfilModulo.SISOU_UNID_CAIXA.value]),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Registrar Solicitação à Ouvidoria",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/tratar-ocorrencia/solicitacao-ouv",
                        visible: true,
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Responder Solicitação à Ouvidoria",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/tratar-ocorrencia/solicitacao-ouv",
                        visible: this.mostraResponder(),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Consultar Ocorrência Ouvidoria",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/ocorrencia/ocorrencia-ouvidoria/consultar-ocorrencia",
                        visible: this.hasPermission([PerfilModulo.OUV_CURADORIA.value
                            , PerfilModulo.OUV_ESTRATEGIA.value
                            , PerfilModulo.OUV_GESTORSISTEMA.value
                            , PerfilModulo.OUV_OPERADOR.value
                        ]),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                    {
                        label: "Construir Equipe Unidade Caixa",
                        icon: "fa fa-angle-double-right",
                        routerLink: "/administracao/parametrizacao/equipe-unidade-caixa/",
                        visible: PerfilUtils.isPerfilHabilitadoUnidadeCaixa(),
                        command: event => {
                            this.menuParent.itemMenuClick(EnumTipoOcorrencia.OUVIDORIA);
                        }
                    },
                ]
            },
        ];
    }

}
