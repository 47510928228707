import { ClassificacaoPedidos } from 'app/shared/models/administracao/parametrizacao/classificacao-pedidos';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {OcorrenciaExterna} from 'app/shared/models/ocorrencia/ocorrencia-externa';
import {Observable} from 'rxjs/Rx';
import {FiltroPaginado} from '../../models/filtroPaginado';
import {TipoRelatorio} from '../../../arquitetura/shared/reports/tipo-relatorio';
import {RelatorioComFiltro} from '../../models/relatorio-com-filtro';
import {OcorrenciaExternaTransferencia} from '../../models/ocorrencia/ocorrencia-externa-transferencia';
import {OcorrenciaExternaCancelamento} from '../../models/ocorrencia/ocorrencia-externa-cancelamento';
import {OcorrenciaExternaReabertura} from 'app/shared/models/ocorrencia/ocorrencia-externa-reabertura';
import { EmpregadosOcorrenciaRequest } from 'app/shared/models/ocorrencia/empregados-ocorrencia-request';
import {RemoverClassificacao} from "../../models/ocorrencia/remover-classificacao";

@Injectable()
export class OcorrenciaSacService extends CrudHttpClientService<OcorrenciaExterna> {

    constructor(protected http: HttpClient, private messageService: MessageService) {
        super('ocorrencia/ocorrencia-sac', http);
    }

    public consultarOcorrenciasSolicitantePaginado(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/historico-paginado/', filtro, this.options());
    }

    public consultarQtdOcorrenciasSolicitante(cpf: number): Observable<any> {
        return this.http.get(this.url + '/quantidade-historico/' + cpf);
    }

    public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
    }

    public consultarPorId(id: number): Observable<any> {
        return this.http.get(this.url + '/' + id);
    }

    public consultarTarefas(id: number): Observable<any> {
        return this.http.get(this.url + '/' + id + '/tarefas');
    }

    public consultarTarefasSelectItem(id: number): Observable<any> {
        return this.http.get(this.url + '/' + id + '/tarefas-select-item');
    }

    public detalhar(id: number): Observable<any> {
        return this.http.get(this.url + '/' + id + '/detalhar');
    }

    public tratar(ocorrencia: OcorrenciaExterna) {
        return this.http.post(this.url + '/' + ocorrencia.id + '/tratar', ocorrencia, this.options());
    }

    public tratarClassificacao(classificacaoPedido: ClassificacaoPedidos) {
      return this.http.post(this.url + '/' + classificacaoPedido.idOcorrencia + '/tratar-classificacao', classificacaoPedido, this.options());
    }

    public removerClassificacao(removerClassificacao: RemoverClassificacao) {
      return this.http.post(this.url + '/remover-classificacao', removerClassificacao, this.options());
    }

    public responder(ocorrencia: OcorrenciaExterna) {
        return this.http.post(this.url + '/' + ocorrencia.id + '/responder', ocorrencia, this.options());
    }

    public transferir(id: number, transferencia: OcorrenciaExternaTransferencia) {
        return this.http.post(this.url + '/' + id + '/transferir', transferencia, this.options());
    }

    public cancelar(id: number, cancelamento: OcorrenciaExternaCancelamento) {
        return this.http.post(this.url + '/' + id + '/cancelar', cancelamento, this.options());
    }

    public definirResponsavel(id: number) {
        return this.http.post(this.url + '/' + id + '/definirResponsavel', this.options());
    }

    public liberarResponsavel(id: number) {
        return this.http.post(this.url + '/' + id + '/liberar', this.options());
    }

    public gerarRelatorio(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
        const relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
        relatorioComFiltro.tipo = TipoRelatorio[tipo];
        relatorioComFiltro.filtro = filtro;
        return this.http.post(this.url + '/gerar-relatorio', relatorioComFiltro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
    }

    public reabrir(ocorrenciaExternaReabertura: OcorrenciaExternaReabertura) {
        return this.http.post(this.url + '/reabrir', ocorrenciaExternaReabertura, this.options());
    }

    public finalizarTratamento(ocorrenciaExterna: OcorrenciaExterna) {
        return this.http.post(this.url + '/finalizarTratamento', ocorrenciaExterna, this.options());
    }


    public finalizarTratamentoIncompleto(ocorrenciaExterna: OcorrenciaExterna) {
        return this.http.post(this.url + '/finalizar-tratamento-incompleto', ocorrenciaExterna, this.options());
    }

    public buscarOcorrenciasPorEmpregadoDeUnidade(filtro: EmpregadosOcorrenciaRequest): Observable<any> {
        return this.http.post(this.url + '/buscar-ocorrencias-por-empregados', filtro)
    }

    public excluirCategorizacao(classificacaoPedido : ClassificacaoPedidos){
        const removerClassificacao = new RemoverClassificacao();
        removerClassificacao.idOcorrenciaExterna = classificacaoPedido.idOcorrencia;
        removerClassificacao.idClassificacao = classificacaoPedido.id;
        this.removerClassificacao(removerClassificacao).subscribe(
            res => {
                this.messageService.addMsgSuccess('Classificação removida com sucesso.');
            },
            err => {
                this.messageService.addMsgDanger(err.err.message);
            }
        );
    }

}
