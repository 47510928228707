import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {SolicitacaoOcorrenciaInterna} from 'app/shared/models/ocorrencia/solicitacao-ocorrencia-interna';
import {TipoRelatorio} from 'app/arquitetura/shared/reports/tipo-relatorio';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {RelatorioComFiltro} from 'app/shared/models/relatorio-com-filtro';

@Injectable()
export class SolicitacaoOcorrenciaInternaService extends CrudHttpClientService<SolicitacaoOcorrenciaInterna> {

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super('ocorrencia/solicitacao-ocorrencia-interna', http);
    }

    public gerarRelatorioConsoleEmpregado(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
        const relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
        relatorioComFiltro.tipo = TipoRelatorio[tipo];
        relatorioComFiltro.filtro = filtro;

        return this.http.post(this.url + '/gerar-relatorio-console-empregado', relatorioComFiltro, this.options(
            { observe: 'response' as 'body', responseType: 'arraybuffer' }));
    }

    public gerarRelatorioConsoleUnidade(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
        const relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
        relatorioComFiltro.tipo = TipoRelatorio[tipo];
        relatorioComFiltro.filtro = filtro;

        return this.http.post(this.url + '/gerar-relatorio-console-unidade', relatorioComFiltro, this.options(
            { observe: 'response' as 'body', responseType: 'arraybuffer' }));
    }

    public gerarRelatorioConsoleAdministrador(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
        const relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
        relatorioComFiltro.tipo = TipoRelatorio[tipo];
        relatorioComFiltro.filtro = filtro;

        return this.http.post(this.url + '/gerar-relatorio-console-administrador', relatorioComFiltro, this.options(
            { observe: 'response' as 'body', responseType: 'arraybuffer' }));
    }

    public consultarPaginadoHistorico(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/consultar-historico', filtro, this.options());
    }
}
