import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {SelectItemModel} from 'app/shared/models/select-item';
import {TipoSolicitacaoOcorrenciaInterna} from 'app/shared/models/ocorrencia/tipo-solicitacao-ocorrencia-interna';

@Injectable()
export class TipoSolicitacaoOcorrenciaInternaService extends CrudHttpClientService<TipoSolicitacaoOcorrenciaInterna> {

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super('ocorrencia/tipo-solicitacao-ocorrencia-interna', http);
    }

    public consultarTodos(): Observable<any> {
        return this.http.get(this.url + '/', this.options());
    }

    public consultarSelectItens(): Observable<any> {
        return this.http.post<SelectItemModel>(this.url + '/select-itens', this.options());
    }

    public consultarPorTipo(icTipo: string): Observable<any> {
        return this.http.post<SelectItemModel>(this.url + '/select-itens-tipo', icTipo, this.options());
      }
}
