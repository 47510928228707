export class FiltroPaginado {
    public pageNumber: number = 0;
    public pageSize: number = 50;
    public token?: string;

    public orderField: string;
    public order: number;
    public all: boolean = false;
    public filtro: any;

    merge(filtroPaginado: FiltroPaginado) {
        this.pageNumber = filtroPaginado.pageNumber;
        this.pageSize = filtroPaginado.pageSize;
        this.token = filtroPaginado.token;
        this.orderField = filtroPaginado.orderField;
        this.order = filtroPaginado.order;
        this.filtro = filtroPaginado.filtro;
      }
}
