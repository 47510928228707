import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {DiarioBordo} from 'app/shared/models/ocorrencia/diario-bordo';
import {Observable, Subject} from 'rxjs/Rx';

@Injectable()
export class DiarioBordoService extends CrudHttpClientService<DiarioBordo> {

  private diarioBordoSubject$ = new Subject<DiarioBordo>();
  diarioBordoIncluido$ = this.diarioBordoSubject$.asObservable();

  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/diario-bordo', http);
  }

  public consultar(filtro: DiarioBordo): Observable<any> {
    return this.http.post<DiarioBordo>(this.url + '/consultar', filtro, this.options());
  }

  public excluir(idDiario: number) {
    return this.http.delete(this.url + '/' + idDiario, this.options());
  }

  emiteDiarioBordoBehaviorSubject(diarioBordo: DiarioBordo) {
    this.diarioBordoSubject$.next(diarioBordo);
  }
}
