import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class LoaderService {
    loaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    loader$: Observable<boolean>;

    constructor() {
        this.loader$ = this.loaderSubject.asObservable();
    }

    setLoading(state: boolean) {
      setTimeout(() => this.loaderSubject.next(state), 0);

    }

    clearMessage() {
      setTimeout(() => this.loaderSubject.next(null), 0);
    }

    isLoading(): any {
        this.loader$.subscribe(res => {
            return res;
        })
    }
}
