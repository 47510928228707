import { RespostaComPromessa } from './ocorrencia-externa-resposta-promessa';
import { NomeSituacaoOcorrencia } from 'app/shared/enums/nome-situacao-ocorrencia.enum';
import { CausaRaizOcorrenciaExterna } from 'app/shared/models/ocorrencia/causa-raiz-ocorrencia-externa';
import { ClassificacaoPedidos } from '../administracao/parametrizacao/classificacao-pedidos';
import { PedidoCliente } from '../administracao/parametrizacao/pedido-cliente';
import { OrientacaoAtendimento } from './../administracao/parametrizacao/orientacao-atendimento';
import { AnexoOcorrencia } from './anexo-ocorrencia';
import { ApontamentoOcorrencia } from './apontamento-ocorrencia';
import { AssinaturaParecer } from './assinatura-parecer';
import { Cabecalho } from './cabecalho';
import { OcorrenciaExternaMotivoRetornoDTO } from './ocorrencia-externa-motivo-retorno-dto';
import { OcorrenciaExternaResposta } from './ocorrencia-externa-resposta';
import { ParecerOcorrenciaExterna } from './parecer-ocorrencia-externa';
import { SolicitacaoPenalidade } from './penalidade-tarefa';
import { ReaberturaMotivoRetorno } from "./reabertura-motivo-retorno";
import { ReaberturaOcorrenciaExterna } from "./reabertura-ocorrencia-externa";
import { ReaberturaResposta } from "./reabertura-resposta";
import { Solicitante } from './solicitante';
import { Tarefa } from './tarefa';
import {EmailParametrizacaoAtendimentoZeroOitocentosModel} from "../../../administracao/parametrizacao/tipo-atendimento-zero-oitocentos/models/email-parametrizacao-atendimento-zero-oitocentos.model";

export class OcorrenciaExterna {
  // obrigatorios
  id: number;
  identificacaoOcorrencia?: number;
  idSituacaoOcorrencia?: number;
  idTipoOcorrencia?: number;
  idOrigem?: number;
  idNatureza?: number;
  dataInicioOcorrenciaExterna?: Date;
  dataFinalOcorrenciaExterna?: Date;
  manifesto?: string;
  observacaoOuvidoria?: string;
  // fim obrigatorios
  providencia?: string;

  numeroLote?: number;
  dataFinalFormatada?: string;
  codigoRastreio?: string;
  noResponsavel?: string;

  //Fila Acolhe
  isFilaAcolhe?: string;
  emailFilaAcolhe?: EmailParametrizacaoAtendimentoZeroOitocentosModel

  anexos?: AnexoOcorrencia[];
  // dificuldade
  grauDificuldade?: string;
  formaRecebimentoResposta?: number;
  motivosRetornoOcorrencia?: OcorrenciaExternaMotivoRetornoDTO[] = [];
  classificacoes?: ClassificacaoPedidos[];
  pedidosCliente?: PedidoCliente[];
  rascunho?: boolean;

  // cabecalho
  cabecalhoDTO?: Cabecalho;
  isOcorrenciaCliente?: boolean;
  noOrigem?: string;
  noNatureza?: string;

  prazoOcorrenciaExterna?: number;
  noSolicitante?: string;
  cpfSolicitante?: string;
  cnpjSolicitante?: string;
  solicitante: Solicitante;

  noTipoOcorrencia?: string;
  noSituacaoOcorrencia?: string;
  // aba resposta
  resposta?: string;
  respostaTemplate?: boolean;
  dataPromessaResposta?: Date;
  dataAbertura?: Date;
  dataIdentificadora?: Date;

  noUnidadeTratamento;
  noMatriculaTratamento;
  nuMatriculaTratamento?: string;
  nuUnidadeTratamento?: number;
  tarefas?: Tarefa[];

  minutaResposta?: string;
  alcadaResposta?: string;
  dataResposta?: Date;
  respostas?: OcorrenciaExternaResposta[];
  causasRaizesOcorrenciaExterna?: CausaRaizOcorrenciaExterna[];
  idProduto?: number;
  idProblema?: number;

  dataRecebimento?: Date;
  dataRespostaOcorrencia?: Date;

  orientacoes?: OrientacaoAtendimento[];

  parecerOcorrenciaExterna?: ParecerOcorrenciaExterna[];
  apontamentosOcorrencia?: ApontamentoOcorrencia[];

  //resposta com promessa
  respostaComPromessa: RespostaComPromessa;
  permiteRecurso?: string;
  observacaoParecer?: string;
  descricaoRecursoOcorrencia?: string;
  assinaturaParecer?: AssinaturaParecer;
  pertinente?: string;
  justificativaPertinente?: string;
  observacaoApontamento?: string;
  //para controlar Registrar, Registrar Tratar e Registrar Responder
  isRegistrar?: boolean;
  isRegistrarTratar?: boolean;
  isRegistrarResponder?: boolean;
  isAvaliar?: boolean;
  //para controlar Registrar Tratar e Registrar Responder

  solicitacoesPenalidadeTarefa?: SolicitacaoPenalidade[];

  permiteReabertura?: boolean = false;
  dataLimiteReabertura?: Date;

  idVinculadas?: string;
  noProduto?: string;
  noProblema?: string;
  noGrupo?: string;
  idGrupo?: number;

  nuGestorResponsavel?: number;
  noGestorResponsavel?: string;

  preOcorrencia?: number;
  dataRegistroPreOcorrenciaExterna?: Date;

  noProdutoPreOcorrencia?: string;
  noProblemaPreOcorrencia?: string;

  codigoConta?: string;
  codigoOperacao?: string;
  numeroNaturalAgencia?: number;
  numeroUnidadeAgencia?: number;

  nomeAnalistaExterno?: string;

  idMotivoEncerramento?: number;

  anexosEmail?: AnexoOcorrencia[];

  notaAvaliacaoAtendimento?: number;
  notaAvaliacaoSolucao?: number;

  perfilAdministrativo?: string;
  visualizarPor?: string;
  nuUnidadeGrupo?: number;

  //Reabertura
  reabertura: boolean = false;
  manifestoReabertura: string;
  respostasReabertura?: ReaberturaResposta[];
  grauDificuldadeReabertura: string;
  motivosRetornoReabertura?: ReaberturaMotivoRetorno[];
  minutaRespostaReabertura: string;
  alcadaRespostaReabertura: string;
  reaberturas?: ReaberturaOcorrenciaExterna[];
  prazoReabertura: number;
  dtRespotaReabertura: Date;
  reaberturaRecurso: boolean = false;
  reabeturaComplementar: boolean = false;
  qtdReabertura: number = 0;
  adiantarVoltar: number = 0;
  arrSituacaoOcorrencia?: number[];

  nuTarefaPendente?: number;
  nuTarefaVencida?: number;
  nuTarefaRespondida?: number;
  nomeSituacao: (string | NomeSituacaoOcorrencia);
  prazoFinal: string;

  constructor(init?: Partial<OcorrenciaExterna>) {
    Object.assign(this, init);
    this.cabecalhoDTO = new Cabecalho();
  }
}
