import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'nomeFonte', pure: false })
export class NomeFontePipe implements PipeTransform {

  fontes = {
    'B': 'Bacen',
    'P': 'Procon',
    'C': 'Caixa',
    'S': 'SAC',
    'O': 'Ouvidoria'
  };

  transform(value: string) {
    return (value) ? this.fontes[value.toString().toUpperCase()] : '';
  }

}
