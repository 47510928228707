import {MenuItem} from 'primeng/primeng';
import {MenuLateralComponent} from '../menu-lateral/menu-lateral.component';

export class ItensMenuRelatorios {

  constructor(private menuParent: MenuLateralComponent) {}

  public get itens(): MenuItem[] {
    return [
      {
        label: "Relatórios",
        icon: "fa fa-cogs",
        routerLink: "/relatorios/",
        command: event => {
          this.menuParent.itemMenuClick(null);
        }
      }
    ];
  }

}
