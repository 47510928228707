import { TipoUsuario } from './../../../models/comum/tipo-usuario';
import {Injectable} from '@angular/core';
import {GrupoTratamento} from 'app/shared/models/administracao/parametrizacao/grupo-tratamento';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {HttpClient} from '@angular/common/http';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs';
import {SelectItemModel} from 'app/shared/models/select-item';
import {RelatorioComFiltro} from 'app/shared/models/relatorio-com-filtro';
import {TipoRelatorio} from 'app/arquitetura/shared/reports/tipo-relatorio';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';

@Injectable()
export class GrupoTratamentoService extends CrudHttpClientService<GrupoTratamento> {
    constructor(
        protected http: HttpClient,
        protected messageService: MessageService
    ) {
        super('administracao/parametrizacao/grupo-tratamento', http);
    }

    public incluir(grupoTratamento: GrupoTratamento): Observable<any> {
        return this.post(grupoTratamento);
    }

    public alterar(grupoTratamento: GrupoTratamento): Observable<any> {
        return this.put(grupoTratamento);
    }

    salvarGrupoAlteracao(grupoTratamento: GrupoTratamento): any {
        return this.put(grupoTratamento);
    }

    consultarSelectItensPorUnidade(): Observable<any> {
        return this.http.get<SelectItemModel>(this.url + '/select-grupos-por-unidade', this.options());
    }

    consultarTravamento(idGrupo): Observable<any> {
        return this.http.get<SelectItemModel>(this.url + '/consultarTravamento/' + idGrupo, this.options());
    }

    consultarSelectItensPorUsuario(perfilLogado): Observable<any> {
        return this.http.get<SelectItemModel>(this.url + '/select-grupos-por-usuario/'+perfilLogado, this.options());
    }

    consultarSelectItensUnidades(idTipoOcorrencia: number): Observable<any> {
        return this.http.get<SelectItemModel>(`${this.url}` + `/select-unidades/` + idTipoOcorrencia, this.options());
    }

    consultarSelectItensGrupos(idTipoOcorrencia: number): Observable<any> {
        return this.http.get<SelectItemModel>(`${this.url}` + `/select-grupos/`, this.options());
    }

    consultarSelectItensGruposUnidade(unidade: string): Observable<any> {
        return this.http.get<SelectItemModel>(`${this.url}` + `/select-grupos-por-unidade/`+unidade, this.options());
    }

    /**
     * Gerar relatório
     */
    public gerarRelatorio(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
        let relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
        relatorioComFiltro.tipo = TipoRelatorio[tipo];
        relatorioComFiltro.filtro = filtro;

        return this.http.post(this.url + '/gerar-relatorio', relatorioComFiltro, this.options(
            { observe: 'response' as 'body', responseType: 'arraybuffer' }));
    }

    public gerarRelatorioSac(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
        let relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
        relatorioComFiltro.tipo = TipoRelatorio[tipo];
        relatorioComFiltro.filtro = filtro;

        return this.http.post(this.url + '/gerar-relatorio-sac', relatorioComFiltro, this.options(
            { observe: 'response' as 'body', responseType: 'arraybuffer' }));
    }

    ativarInativar(grupoTratamento: GrupoTratamento) {
        return this.http.put(this.url + '/ativar-inativar', grupoTratamento, this.options());
    }

    consultarTipoUsuario(matricula: string, grupo: number) {
        return this.http.get<TipoUsuario>(`${this.url}` + `/tipo-usuario/`+matricula+'/'+grupo, this.options());
    }

    public alterarMensagemEquipe(grupoTratamento: GrupoTratamento): Observable<any> {
        return this.http.post(this.url + '/alterar-mensagem-equipe', grupoTratamento, this.options());
    }

    consultarMensagem(grupo: number) {
        return this.http.get<GrupoTratamento>(`${this.url}` + `/mensagem/`+grupo, this.options());
    }

    isClassificacaoCadastradaGrupoTratamentoSAC(grupoTratamento: GrupoTratamento): Observable<any> {
        return this.http.post(this.url + '/is-classificacao-cadastrada-grupo-tratamento-sac', grupoTratamento, this.options());
    }
}
