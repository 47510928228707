import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Natureza} from 'app/shared/models/administracao/parametrizacao/natureza';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {SelectItemModel} from 'app/shared/models/select-item';
import {Pesquisa} from 'app/shared/models/comum/pesquisa';
import {ParametroEquipeUnidade} from "../../../models/administracao/parametrizacao/parametro-equipe-unidade";

@Injectable()
export class NaturezaService extends CrudHttpClientService<Natureza> {
  constructor(
    protected http: HttpClient,
    private messageService: MessageService
  ) {
    super('administracao/parametrizacao/natureza', http);
  }

  public consultarTodos(): Observable<any> {
    return this.http.get<any>(this.url + '/todos', this.options());
  }

  public todosAtivos(): any {
    return this.http.get<Natureza[]>(this.url + '/ativos', this.options());
  }

  public verificarDuplicidade(natureza: Natureza): any {
    return this.http.post<boolean>(this.url + '/verificar/duplicidade', natureza, this.options());
  }

  public callListarNaturezasPorTipoOcorrenciaAndTipoOrigem(idTipoOcorrencia: number, idTipoOrigem: number,
    next?: (naturezas: Natureza[]) => void): void {
    this.callGET<Natureza[]>('/itens/tipoocorrencia/' + idTipoOcorrencia + '/origem/' + idTipoOrigem, next,
      error => {
        this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar naturezas.');
        console.log('Erro ao chamar o servico. Erro: ' + error);
      }
    );
  }

  public callListarNaturezasPorTipoOrigem(idTipoOrigem: number,
    next?: (naturezas: Natureza[]) => void): void {
    this.callGET<Natureza[]>('/itens/origem/' + idTipoOrigem, next,
      error => {
        this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar naturezas por origem.');
        console.log('Erro ao chamar o servico. Erro: ' + error);
      }
    );
  }

  public ativarInativar(natureza: Natureza): Observable<any> {
    return this.http.put<Natureza>(this.url + "/ativar-inativar", natureza, this.options());
  }

  public consultar(natureza: Natureza): any {
    return this.http.post<boolean>(this.url + '/consultar', natureza, this.options());
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

  public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis/', idTipoOcorrencia, this.options());
  }

  public consultarSelectItensPorOrigem(idOrigem: number): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-origem', idOrigem, this.options());
  }

  public consultarSelectItensPorDescricaoEPerfil(idNatureza: number, perfil: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('idNatureza', idNatureza.toString());
    httpParams = httpParams.set('perfil', perfil.toString());
    return this.http.get<SelectItemModel>(this.url + '/select-itens-descricao-perfil', this.options({params: httpParams}));
  }

  public consultarSelectItensPorEquipe(idEquipeSelecionada: number, perfil: number): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens-equipe/' + idEquipeSelecionada + '/' + perfil, this.options());
  }

  public consultarSelectItensNaCategorizacao(pesquisa: Pesquisa): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-categorizacao', pesquisa, this.options());
  }

  public consultarSelectItensCategorizadoOcorrenciaInterna(): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens-categorizados-ocorrencia-interna/', this.options());
  }

  public consultarSelectItensNaCategorizacaoEquipeUnidade(parametroEquipeUnidade: ParametroEquipeUnidade): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-categorizacao-equipe-unidade', parametroEquipeUnidade, this.options());
  }

  public consultarSelectItensSemTipoOcorrencia(): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-sem-tipo-ocorrencia/', this.options());
  }

}
