import {Feriado} from './../../models/ocorrencia/feriado';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {ProrrogacaoOcorrenciaOuvidoria} from '../../models/ocorrencia/prorrogacao-ocorrencia-ouvidoria';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {TipoProrrogacaoEnum} from "../../enums/tipo-prorrogacao.enum";
import * as moment from 'moment'

@Injectable()
export class ProrrogacaoOcorrenciaService extends CrudHttpClientService<ProrrogacaoOcorrenciaOuvidoria> {

  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/ocorrencia-ouvidoria/prorrogacao', http);
  }

  public consultarPaginadoHistoricoProrrogacao(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado-historico-prorrogacao', filtro, this.options());
  }

  public incluir(prorrogacao: ProrrogacaoOcorrenciaOuvidoria){
   return this.http.post<void>(this.url + '/', prorrogacao, this.options());
  }

  public verificarRespostaBacenProrrogacao(idOcorrenciaExterna: number): Observable<any> {
    return this.http.get<any>(`${this.url}/verificar-resposta-bacen/${idOcorrenciaExterna}`,this.options());
  }

  obterDataFuturaProrrogacao(feriado: Feriado, tipoCalculo: TipoProrrogacaoEnum): Observable<Date> {
    if (TipoProrrogacaoEnum.DIAS_CORRIDOS === tipoCalculo ) {
      const dataFutura = moment(feriado.dataBase).utcOffset(0).set({hour: 0, minute: 0, second: 0 }).add(feriado.quantidadeDias, 'days');
      return Observable.of(moment(`${dataFutura.format('YYYY-MM-DD')}T00:00:00.000`).toDate());
    } else if (TipoProrrogacaoEnum.DIAS_UTEIS === tipoCalculo) {
      return this.http.post<any>(`${this.url}/obter-data-prorrogacao/`, feriado, this.options());
    }
  }

}
