import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SelectItemModel} from 'app/shared/models/select-item';
import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';
import {UsuarioService} from 'app/arquitetura/shared/services/cadastrobasico/usuario.service';
import {KeycloakService} from 'app/arquitetura/shared/services/seguranca/keycloak.service';
import {Usuario} from 'app/arquitetura/shared/models/cadastrobasico/usuario';
import {Router} from '@angular/router';
import {CabecalhoService} from 'app/arquitetura/shared/services/cabecalho.service';
import {Base} from '../base';
import {MessageService} from "app/shared/components/messages/message.service";
import {PerfilModulo} from "app/shared/models/perfil-modulo";
import {CabecalhoPadraoComponent} from "../../../arquitetura/shared/templates/cabecalho-padrao/cabecalho-padrao.component";

@Component({
    selector: 'app-modal-perfis',
    templateUrl: './modal-perfis.component.html',
    styleUrls: ['./modal-perfis.component.scss']
})
export class ModalPerfisComponent extends Base implements OnInit {
    formulario: FormGroup;
    displayModal: boolean;
    perfis: SelectItemModel[];
    somentePerfil: SelectItemModel[];
    @Input("botaoPerfil") botaoPerfil: boolean = false;
    @Output() emit = new EventEmitter();

    constructor(
        protected messageService: MessageService,
        private formBuilder: FormBuilder,
        private router: Router,
        private sessaoService: SessaoService,
        private cabelhoService: CabecalhoService
    ) {
        super(messageService);
    }

    ngOnInit() {
        this.displayModal = false;
        this.createForm();
        this.carregarListaPerfis();
    }

    createForm() {
        this.formulario = this.formBuilder.group({
            perfil: [null, Validators.required]
        });
    }

    carregarListaPerfis() {
        this.perfis = [];
        this.somentePerfil = [];

        let opcaoSelecione = new SelectItemModel();
        opcaoSelecione.label = "Selecione";
        this.perfis.unshift(opcaoSelecione);

        if (SessaoService.usuario.perfisParaSelecao.includes('SISOU_UNID_CAIXA')) {
            SessaoService.usuario.perfisParaSelecao.splice(
                SessaoService.usuario.perfisParaSelecao.indexOf('SISOU_UNID_CAIXA'), 1);
        }

        // INICIO DO CÓDIGO TEMPORÁRIO
        // ESSE CODIGO SERÁ REMOVIDO APÓS AJUSTE DE USUÁRIOS
        // ESSE AJUSTA CONSISTE EM REMOVER OS PERFIS VIVA_SOLI, OUV_SUBSIDIARIA E SAC_OPER_CAIXA, SAC_TERC_N1

        let seTemVivaSoliAntigo: boolean = SessaoService.usuario.perfisParaSelecao.includes('VIVA_SOLI');
        let seTemOuvSubsidiariaAntigo: boolean = SessaoService.usuario.perfisParaSelecao.includes('OUV_SUBSIDIARIA');
        let seTemSacOperCaixa: boolean = SessaoService.usuario.perfisParaSelecao.includes('SAC_OPER_CAIXA');
        let seTemTercN1: boolean = SessaoService.usuario.perfisParaSelecao.includes('SAC_TERC_N1');
        let seTemConsulta:  boolean = SessaoService.usuario.perfisParaSelecao.includes('SAC_CONSULTA');

        if (seTemVivaSoliAntigo) {
            SessaoService.usuario.perfisParaSelecao.splice(
                SessaoService.usuario.perfisParaSelecao.indexOf('VIVA_SOLI'), 1);
        }

        if (seTemOuvSubsidiariaAntigo) {
            SessaoService.usuario.perfisParaSelecao.splice(
                SessaoService.usuario.perfisParaSelecao.indexOf('OUV_SUBSIDIARIA'), 1);
        }

        if (seTemSacOperCaixa) {
            SessaoService.usuario.perfisParaSelecao.splice(
                SessaoService.usuario.perfisParaSelecao.indexOf('SAC_OPER_CAIXA'), 1);
        }

        if (seTemTercN1) {
            SessaoService.usuario.perfisParaSelecao.splice(
                SessaoService.usuario.perfisParaSelecao.indexOf('SAC_TERC_N1'), 1);
        }

        if (seTemConsulta) {
            SessaoService.usuario.perfisParaSelecao.splice(
                SessaoService.usuario.perfisParaSelecao.indexOf('SAC_CONSULTA'), 1);
        }
        // FIM DO CÓDIGO TEMPORÁRIOS

        if (SessaoService.usuario.perfisParaSelecao.length == 0 && !SessaoService.usuario.usuarioExterno) {
            SessaoService.usuario.perfisParaSelecao.push('SISOU_UNID_CAIXA');
        }


        if (SessaoService.usuario.perfisParaSelecao) {
            SessaoService.usuario.perfisParaSelecao.forEach(perfil => {
                if (!perfil.includes('SISOU_UNID_CAIXA')) {
                    let opcao = new SelectItemModel();
                    opcao.label = perfil;
                    opcao.value = perfil;
                    this.perfis.push(opcao);
                    this.somentePerfil.push(opcao);
                }
            });
        }

        if (this.sessaoService.isPerfilUsuarioSelecionado() && this.botaoPerfil) {
            this.displayModal = true;
        } else if (this.sessaoService.isPerfilUsuarioSelecionado() || (SessaoService.usuario.perfisParaSelecao &&
            (SessaoService.usuario.perfisParaSelecao.length == 1))) {
            this.displayModal = false;
            if (this.sessaoService.isPerfilUsuarioSelecionado()) {
                if(this.sessaoService.getPerfilUsuarioSelecionado().cnpj == null){
                    this.formulario.get('perfil').setValue(this.sessaoService.getPerfilUsuarioSelecionado().perfil);
                }else{
                    let perfilExterno = "CONV_SISOU_U_"+this.sessaoService.getPerfilUsuarioSelecionado().cnpj+"_"+this.sessaoService.getPerfilUsuarioSelecionado().perfil;
                    this.formulario.get('perfil').setValue(perfilExterno);
                }
            } else {
                this.formulario.get('perfil').setValue(SessaoService.usuario.perfisParaSelecao[0]);
            }
            this.acessar();
        } else {
            this.displayModal = true;
        }


    }

    acessar(): void {
        let perfilSelecionado: string = this.formulario.get('perfil').value;
        if (this.formulario.valid) {
            UsuarioService.consultarPorLogin(KeycloakService.getUsername(), perfilSelecionado)
                .then((usuario: Usuario) => {
                    SessaoService.usuario = usuario;

                    // Atualiza Sessão
                    this.sessaoService.atualizarPerfilSessao(perfilSelecionado);

                    // retorna para Home
                    if(sessionStorage.getItem('SISOU_REDIRECT')!='true'){
                      this.definirHomePerfil(perfilSelecionado);
                    }

                    // Fecha Modal
                    this.displayModal = false;

                    // Atualiza Cabecalho
                    this.cabelhoService.emiteReloadCabecalho();

                    // Atualiza Menu
                    this.emit.emit();
                });
        } else {
            this.forceValidateAllFormFields(this.formulario);
        }
    }

    acessarSemRedirect(): void {
        let perfilSelecionado: string = this.formulario.get('perfil').value;
        if (this.formulario.valid) {
            UsuarioService.consultarPorLogin(KeycloakService.getUsername(), perfilSelecionado)
                .then((usuario: Usuario) => {
                    SessaoService.usuario = usuario;

                    // Atualiza Sessão
                    this.sessaoService.atualizarPerfilSessao(perfilSelecionado);

                    // Atualiza Cabecalho
                    this.cabelhoService.emiteReloadCabecalho();

                    // Atualiza Menu
                    this.emit.emit();

                    // Fecha Modal
                    this.displayModal = false;

                    this.definirHomePerfil(perfilSelecionado)
                });
        } else {
            this.forceValidateAllFormFields(this.formulario);
        }
    }

    /**
     * @description falta definir as telas iniciais de cada perfil
     * @param perfilSelecionado
     */
    definirHomePerfil(perfilSelecionado) {
        perfilSelecionado = this.verificarPerfilExterno(perfilSelecionado);
        switch (perfilSelecionado) {
            case PerfilModulo.OUV_SUPERVISOR.value:
                this.router.navigate(['/ocorrencia/ocorrencia-ouvidoria/supervisor']);
                break;
            case PerfilModulo.OUV_OPERADOR.value:
                this.router.navigate(['/ocorrencia/ocorrencia-ouvidoria']);
                break;
            case PerfilModulo.OUV_CURADORIA.value:
                this.router.navigate(['/home']);
                break;
            case PerfilModulo.OUV_GESTORSISTEMA.value:
                this.router.navigate(['/home']);
                break;
            default:
                 this.router.navigate(['/home']);
                 break;

        }
    }

    fechar(event: any) {
        // Atualiza Cabecalho
        this.cabelhoService.emiteReloadCabecalho();

        // Atualiza Menu
        this.emit.emit();

        // Fecha Modal
        this.displayModal = false;
    }

    private verificarPerfilExterno(perfilSelecionado: string) {
        if (perfilSelecionado != null) {
            if (perfilSelecionado.includes(PerfilModulo.SOU_TERCEIRIZADAOP.value)) {
                return PerfilModulo.SOU_TERCEIRIZADAOP.value;
            }
            if (perfilSelecionado.includes(PerfilModulo.SOU_TERCEIRIZADAGE.value)) {
                return PerfilModulo.SOU_TERCEIRIZADAGE.value;
            }
        }
        return perfilSelecionado;
    }
}
