import {Injectable} from "@angular/core";
import {CrudHttpClientService} from "../../../../arquitetura/shared/services/crud-http-client.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/Rx";
import {SelectItemModel} from "../../../models/select-item";
import {EnvioOcorrencia} from "../../../../administracao/rdr-bacen/model/envio-ocorrencia";

@Injectable()
export class RdrBacenService extends CrudHttpClientService<any> {

    constructor(protected http: HttpClient) {
        super("administracao/envio-ocorrencia", http);
    }

    public listarTipos(): Observable<SelectItemModel[]> {
        return this.http.get<SelectItemModel[]>(this.url + "/tipos", this.options());
    }

    public listarSituacao(): Observable<SelectItemModel[]> {
        return this.http.get<SelectItemModel[]>(this.url + "/situacoes", this.options());
    }

    consultarPorId(id: number): Observable<EnvioOcorrencia> {
        return this.http.get(`${this.url}/${id}`, this.options());
    }

    reenviar(id: number): Observable<EnvioOcorrencia> {
        return this.http.get(`${this.url}/${id}/reenviar`, this.options());
    }
}