import {SelectItemModel} from 'app/shared/models/select-item';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Origem} from 'app/shared/models/administracao/parametrizacao/origem';
import {Observable} from 'rxjs';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';

@Injectable()
export class OrigemService extends CrudHttpClientService<Origem> {
	constructor(protected http: HttpClient) {
		super('administracao/parametrizacao/origem', http);
	}

  public callListarPorTipoOcorrencia(idTipoOcorrencia: number, next: (origens: Origem[]) => Origem[]): void {
    this.http.get<Origem[]>(this.url + '/itens/tipoocorrencia/' + idTipoOcorrencia, this.options())
      .subscribe(next, error => {
        console.log('Não foi possível consultar origens por tipo de ocorrencia.');
        console.error(error.error);
      });
  }

  public listarOrigemAtivaPorTipoOcorrencia(idTipoOcorrencia: number, next: (origens: Origem[]) => void): void {
    this.http.get<Origem[]>(this.url + '/itens/tipoocorrencia/origemativa/' + idTipoOcorrencia, this.options())
      .subscribe(next, error => {
        console.log('Não foi possível consultar origens por tipo de ocorrencia.');
        console.error(error.error);
      });
  }

  public consultar(origem: Origem): any {
    return this.http.post<boolean>(this.url + '/consultar', origem, this.options());
  }

  public verificarVinculo(origem: Origem): any {
    return this.http.post<boolean>(this.url + '/verificar/vinculo', origem, this.options());
  }

  public ativarInativar(origem: Origem): Observable<any> {
    return this.http.put<Origem>(this.url + "/ativar-inativar", origem, this.options());
  }

  public searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public consultarSelectItensPerfis(idTipoOcorrencia: number): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis/', idTipoOcorrencia, this.options());
  }

  public consultarSelectItensMatricula(idEquipeSelecionada: number, perfil: number): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens-matricula/' + idEquipeSelecionada + '/' + perfil, this.options());
  }

  public consultarSelectItensCategorizadoOcorrenciaInterna(): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens-categorizados-ocorrencia-interna/', this.options());
  }

  public consultarPorId(idOrigem: number): Observable<any> {
    return this.http.get<Origem>(this.url + '/' + idOrigem, this.options());
  }

  public consultarTodos(): Observable<any> {
    return this.http.get<any>(this.url + '/todos', this.options());
  }

  public todosAtivos(idTipoOcorrencia: number): any {
    return this.http.get<Origem[]>(this.url + '/ativos/' + idTipoOcorrencia, this.options());
  }

  public todosAtivosSemTipoOcorrencia(): any {
    return this.http.get<Origem[]>(this.url + '/ativos-sem-tipo-ocorrencia', this.options());
  }

  public consultarSelectItensPerfisPorTerceirizada(idTerceirizada: number): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-terceirizada/', idTerceirizada, this.options());
  }

  public consultarSelectItensPerfisPorUsuarioEquipe(matricula: string): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis-equipe-unidade/', matricula, this.options());
  }

  public alterarSituacaoLote(itens: Origem[]): Observable<any> {
    return this.http.put(this.url + '/ativar-inativar-lote', itens, this.options());
  }
}
