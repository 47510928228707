import {HttpErrorResponse} from '@angular/common/http';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';

import {Usuario} from 'app/arquitetura/shared/models/cadastrobasico/usuario';
import {TipoRelatorio} from 'app/arquitetura/shared/reports/tipo-relatorio';
import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';
import {ModuloUtils} from 'app/arquitetura/shared/util/modulo-utils';
import {Util} from 'app/arquitetura/shared/util/util';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Unidade} from 'app/shared/models/comum/unidade';
import {isArray, isDate, isNumber, isString} from 'util';
import {EnumTipoOcorrencia} from '../enums/EnumTipoOcorrencia';
import {TipoOcorrencia} from '../models/administracao/parametrizacao/tipo-ocorrencia';
import {CustomTableBuilder} from '../models/custom-table-builder';
import {CustomTableCreator} from '../models/custom-table-creator';
import {SelectItemModel} from '../models/select-item';
import {Mensagem} from './mensagem';
import * as _ from 'lodash';

export class Base {
    PAGINA_VOLTAR = 'SISOU.PAGINA_VOLTAR';
    TipoRelatorio = TipoRelatorio;
    public pt = {
        firstDayOfWeek: 0,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Out', 'Nov', 'Dez'],
        today: 'Hoje',
        clear: 'Limpar'
    };
    public home: any = {icon: 'pi pi-home', url: '#'};
    public usuario: Usuario;

    public mensagem: Mensagem = new Mensagem();

    paginacaoSelecionados: any[] = [];
    paginacaoSelecionadosAllPages: any[] = [];
    itensSelecionados: any[] = [];

    constructor(
        protected messageService: MessageService
    ) {
        this.messageService = messageService;
        this.usuario = SessaoService.usuario;
    }

    /**
     * Trata as mensagens de erro da API
     * @param response
     */
    trataMsgErroApi(response: HttpErrorResponse): string[] {
        const arrError = [];

        if (response instanceof HttpErrorResponse) {
            // Erros de validação da API
            if (response.status === 422) {

                if (typeof response.error === 'string') {
                    const msg = JSON.parse(response.error);
                    if (typeof msg.message === 'string') {
                        arrError.push(msg.message);
                    }
                }

                const objErrorValidation = response.error.errors;
                // Coloca todas as mensagens em um array
                for (const keyGroup in objErrorValidation) {
                    if (objErrorValidation.hasOwnProperty(keyGroup)) {
                        for (const key in objErrorValidation[keyGroup]) {
                            if (objErrorValidation[keyGroup].hasOwnProperty(key)) {
                                arrError.push(objErrorValidation[keyGroup][key]);
                            }
                        }
                    }
                }
            } else {
                // Verifica se é uma mensagem simples
                if (typeof response.error === 'object' && typeof response.error.message === 'string') {
                    arrError.push(response.error.message);
                }

                if (typeof response.error === 'string') {
                    const msg = JSON.parse(response.error);
                    if (typeof msg.message === 'string') {
                        arrError.push(msg.message);
                    }
                }
            }
        }

        return arrError;
    }

    getClone(object: any): any {
        return JSON.parse(JSON.stringify(object));
    }

    validaSeDataValida(formulario: FormGroup, nomeCampo: string): boolean {
        const campo: AbstractControl = formulario.get(nomeCampo);
        return (campo.touched && (campo.value.length === 0))
            || (campo.touched && (campo.value == null))
            || (campo.touched && (Util.isEmpty(campo.value)));
    }

    validaSeCampoValido(formulario: FormGroup, nomeCampo: string): boolean {
        const campo: AbstractControl = formulario.get(nomeCampo);
        return (campo.dirty || campo.touched)
            && campo.invalid
            && Util.isEmpty(campo.value);
    }

    validaSeCampoValidoLength(formulario: FormGroup, nomeCampo: string, minLength: number): boolean {
        const campo: AbstractControl = formulario.get(nomeCampo);
        const value: string = campo.value + '';
        return (campo.dirty || campo.touched)
            && (value && value.trim().length < minLength || Util.isEmpty(value));
    }

    validaSeCampoLength(formulario: FormGroup, nomeCampo: string, minLength: number): boolean {
        const campo: AbstractControl = formulario.get(nomeCampo);
        const value: string = campo.value + '';
        return (campo.dirty || campo.touched)
            && (value && value.trim().length < minLength);
    }

    aplicaClasseDeErro(formulario: FormGroup, nomeCampo: string): any {
        return {'has-danger': this.validaSeCampoValido(formulario, nomeCampo)};
    }

    validaSeCheckboxValido(formulario: FormGroup, nomeCampo: string, lista: Array<any>): boolean {
        const campo: AbstractControl = formulario.get(nomeCampo);
        return (campo.dirty || campo.touched) && lista.length === 0;
    }

    validaSeCheckboxValidoList(touched: boolean, lista: Array<any>): boolean {
        return touched && lista.length === 0;
    }

    onChangeCheckbox(value: any, isChecked: boolean, list: Array<any>): void {
        if (isChecked) {
            list.push(value);
        } else {
            const index = list.findIndex(x => this.equals(x, value));
            if (index !== -1) {
                list.splice(index, 1);
            }
        }
    }

    showPaginacao(lista: Array<any>): boolean {
        return lista && lista.length > 0;
    }


    findIndex(list: any[], value?: any, lambda?: (x: any) => boolean): number {
        let index = -1;
        if (lambda) {
            index = list.findIndex(lambda);
        } else {
            index = list.findIndex(x => this.equals(x, value));
        }
        return index;
    }

    contains(list: any[], value?: any, lambda?: (x: any) => boolean): boolean {
        let index = -1;
        if (lambda) {
            index = list.findIndex(lambda);
        } else {
            index = list.findIndex(x => this.equals(x, value));
        }
        return index !== -1;
    }

    equals(objectA: any, objectB: any): boolean {
        if (typeof objectA !== typeof objectB) {
            return false;
        }
        if (objectA === null && objectB === null) {
            return true;
        }
        const aKeys = objectA !== null && typeof objectA !== 'string' ? Object.keys(objectA) : [],
            bKeys = objectB !== null && typeof objectB !== 'string' ? Object.keys(objectB) : [];
        if (aKeys.length !== bKeys.length) {
            return false;
        }
        if (aKeys.length === 0) {
            return (objectA === objectB
                || (typeof objectA === 'object' || typeof objectB === 'object'));
        }

        const areDifferent = aKeys.some((key) => {
            return !this.equals(objectA[key], objectB[key]);
        });
        return !areDifferent;
    }

    disableField(formulario: FormGroup, nomeCampo: string): void {
        const field = this.getControl(formulario, nomeCampo);
        if (!Util.isEmpty(field)) {
            field.disable();
        }
    }

    enableField(formulario: FormGroup, nomeCampo: string): void {
        const field = this.getControl(formulario, nomeCampo);
        if (!Util.isEmpty(field)) {
            field.enable();
        }
    }

    getControl(formulario: FormGroup, nomeCampo: string): AbstractControl {
        if (!Util.isEmpty(formulario)) {
            const campo: AbstractControl = formulario.get(nomeCampo);
            if (!Util.isEmpty(campo)) {
                return formulario.get(nomeCampo);
            }
        }
        return null;
    }

    idRow(values: string[]): string {
        let id = 'row_';
        values.forEach(value => {
            id += value + '_';
        });
        return id.substring(0, id.length - 1);
    }

    idField(values: string[]): string {
        let idField = 'field_';
        values.forEach(value => {
            idField += value + '_';
        });
        return idField.substring(0, idField.length - 1);
    }

    idModal(idBase: string, idModal: string, values: string[]): string {
        let id = 'modal_' + idBase + '_' + idModal + '_';
        values.forEach(value => {
            id += value + '_';
        });
        return id.substring(0, id.length - 1);
    }

    idHashtag(id: string): string {
        return id ? '#' + id : '';
    }

    concatenarComTraco(values: any[]): string {
        let retorno = '';
        values.forEach(value => {
            retorno += value + ' - ';
        });
        return retorno.substring(0, retorno.length - 3);
    }

    getValueById(id: string): any {
        return $('#' + id).val();
    }

    setValueById(field: any, value: string): void {
        if (typeof field === 'string') {
            this.getValueById(field).val(value);
        }
    }

    getValueForm(form: FormGroup, fieldName: string): any {
        const field = this.getControl(form, fieldName);
        if (!field) {
            return null;
        }
        return field.value;
    }

    getValueArrayForm(form: FormGroup, fieldName: string, list: any[], attribute?: string): any {
        const controlArray = this.getValueForm(form, fieldName);
        if (!Util.isEmpty(controlArray)) {
            return controlArray.map((v, i) => v ? (Util.isEmpty(attribute) ? list[i] : list[i][attribute]) : null).filter(v => v !== null);
        }
        return null;
    }

    setValue(form: FormGroup, fieldName: string, value: any): void {
        const field = this.getControl(form, fieldName);
        if (!Util.isEmpty(field)) {
            field.setValue(value);
        }
    }

    // Adiciona o Validator Required de um elemento no FormGroup, recebe o form e o nome do form control.
    setAsRequired(form: FormGroup, fieldName: string): void {
        form.get(fieldName).setValidators([Validators.required]);
        form.get(fieldName).updateValueAndValidity();
    }

    // Retira o Validator Required de um elemento no FormGroup, recebe o form e o nome do form control.
    clearRequired(form: FormGroup, fieldName: string): void {
        form.get(fieldName).setValidators(null);
        form.get(fieldName).updateValueAndValidity();
    }

    // Retorna apenas a label de um SelectItem baseado no ID
    carregarLabel(numValue: number, listaSelect: SelectItemModel[]): string {
        if (numValue == null) {
            return null;
        }
        return listaSelect.find(element => element.value === numValue).label;
    };

    setValueArray(form: FormGroup, fieldName: string, value: any): void {
        const field = this.getControl(form, fieldName);
        if (!Util.isEmpty(field)) {
            form.removeControl(fieldName);
            form.addControl(
                fieldName,
                value
            );
        }
    }

    getMessageError(error: any, messageDefault: string): string {
        return typeof error.error !== 'string' ? messageDefault : error.error;
    }

    /**
     * Modelo 1 = Se possuir sigla -> 'codigo' - 'sigla' - 'uf'
     * Modelo 2 = Se possuir sigla -> 'codigo' - 'sigla' - 'uf'
     *
     * @param unidade
     * @param modelo
     */
    getUnidadeDescricao(unidade: Unidade, modelo: number): string {
        let descricao = '';
        if (!Util.isDefined(unidade)) {
            return descricao;
        }
        if (modelo === 1) {
            descricao = !Util.isEmpty(unidade.sigla) ? this.concatenarComTraco([unidade.nuUnidade, unidade.sigla, unidade.uf])
                : this.concatenarComTraco([unidade.nuUnidade, unidade.nome]);
        } else if (modelo === 2) {
            if (!Util.isEmpty(unidade.siglaLocalizacao) && !Util.isEmpty(unidade.sigla)) {
                descricao = this.concatenarComTraco([unidade.nuUnidade, unidade.sigla]) + '/' +
                    this.concatenarComTraco([unidade.siglaLocalizacao, unidade.uf]);
            } else if (Util.isEmpty(unidade.siglaLocalizacao) && !Util.isEmpty(unidade.sigla)) {
                descricao = this.concatenarComTraco([unidade.nuUnidade, unidade.sigla, unidade.uf]);
            } else {
                descricao = this.concatenarComTraco([unidade.nuUnidade, unidade.nome, unidade.uf]);
            }
        }
        return descricao;
    }

    getItemList(list: any[], position: number): any {
        return list.length > 0 ? list[position] : null;
    }

    // INICIO - validação formulario
    validarFormulario(formulario: FormGroup): boolean {
        return this.validarFormularioAll(formulario, []);
    }

    validarFormularioAll(formulario: FormGroup, lists: any[]): boolean {
        const keys = Object.keys(formulario.value);
        for (let index = 0; index < keys.length; index++) {
            const field: AbstractControl = formulario.get(keys[index]);
            const value = field.value;
            if (value && typeof value === 'string') {
                field.setValue(value.trim());
            }
            if (field.invalid) {
                return false;
            }
        }
        for (let index = 0; index < lists.length; index++) {
            if (lists[index].length === 0) {
                return false;
            }
        }
        return formulario.valid;
    }

    validarPeloMenosUm(formulario: FormGroup, keys: string[]): boolean {
        return this.validarPeloMenosUmAll(formulario, keys, []);
    }

    validarPeloMenosUmAll(formulario: FormGroup, keys: string[], lists: any[]): boolean {
        for (let index = 0; index < keys.length; index++) {
            const field: AbstractControl = formulario.get(keys[index]);
            const value = field.value;
            if (value && typeof value === 'string') {
                field.setValue(value.trim());
            }
            if (!Util.isEmpty(value)) {
                return true;
            }
        }
        for (let index = 0; index < lists.length; index++) {
            if (lists[index].length !== 0) {
                return true;
            }
        }
        return false;
    }

    trim(event) {
        if (event.target.value) {
            return event.target.value = event.target.value.trimLeft();
        }
    }

    onlyNumber(obj) {
        const val = obj.srcElement.value.replace(/[^\d]+/g, '');
        obj.target.value = val;
    }

    // FIM - validação formulario

    newDefaultCustomTableFromBuilder<T>(builder: CustomTableBuilder<T>) {
        builder.render();
        return new CustomTableCreator().newCustomTable(builder.tableId, null, builder.headers, builder.rows, builder.clazz, builder.style, null, null, null, null, builder.tableData);
    }

    removeList(list: any[], item: any, lambda?: (value: any) => boolean): any[] {
        return list.filter((value) => {
            return lambda ? lambda(value) : !this.equals(value, item);
        });
    }

    // INICIO - Tratamento de modais
    showBsModal(idModal: string, showCallback?: () => void, showCallbacke?: (e) => void): void {
        this.funcaoModal(idModal, 'show.bs.modal', showCallback, showCallbacke);
    }

    shownModal(idModal: string, showCallback?: () => void, showCallbacke?: (e) => void): void {
        this.funcaoModal(idModal, 'shown.bs.modal', showCallback, showCallbacke);
    }

    hiddenModal(idModal: string, callback?: () => void, callbacke?: (e) => void): void {
        this.funcaoModal(idModal, 'hidden.bs.modal', callback, callbacke);
    }

    funcaoModal(idModal: string, functionName: string, callback?: () => void, callbacke?: (e) => void): void {
        $(this.idHashtag(idModal)).on(functionName, (e) => {
            if (e.target.id === e.currentTarget.id) {
                if (callback) {
                    callback();
                }
                if (callbacke) {
                    callbacke(e);
                }
            }
        });
    }

    showModal(id: string): void {
        (<any>$(this.idHashtag(id))).modal('show');
    }

    hideModal(id: string): void {
        (<any>$(this.idHashtag(id))).modal('hide');
    }

    // FIM - Tratamento de modais


    //novos usados atualmente
    protected forceValidateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.forceValidateAllFormFields(control);
            }
        });
    }

    protected disableAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.disable();
            } else if (control instanceof FormGroup) {
                this.disableAllFormFields(control);
            }
        });
    }

    protected isFormularioAoMenosUmItemPreenchido(formGroup: FormGroup): boolean {
        for (let field of Object.keys(formGroup.controls)) {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                if (this.validarCampos(control.value)) {
                    return true;
                }
            }
        }

        return false;
    }

    private validarCampos(obj: any): boolean {
        if (isString(obj)) {
            if (obj != null && obj.length > 0) {
                return true;
            }
        } else if (isArray(obj)) {
            if (obj != null && obj.length > 0) {
                return true;
            }
        } else if (isNumber(obj)) {
            if (obj != null) {
                return true;
            }
        } else if (isDate(obj)) {
            if (obj != null) {
                return true;
            }
        } else if (obj != null) {
            for (let key of Object.keys(obj)) {
                if ("_$visited" != key) {
                    return this.validarCampos(obj[key]);
                }
            }
        }
    }

    protected limparValidacoesCampo(control: AbstractControl): void {
        if (control) {
            control.markAsPristine();
            control.updateValueAndValidity();
        }
    }

    protected limparTodoFormulario(formGroup: FormGroup): void {
        formGroup.reset();
        formGroup.markAsPristine();
        formGroup.markAsUntouched();
        formGroup.updateValueAndValidity();
    }

    protected limparValidacoesFormulario(formGroup: FormGroup): void {
        formGroup.markAsPristine();
        formGroup.markAsUntouched();
        formGroup.updateValueAndValidity();
    }

    public criarSelectItemSelecione(): SelectItemModel {
        let selecione: SelectItemModel = new SelectItemModel();
        selecione.label = "Selecione";
        selecione.value = undefined;
        return selecione;
    }

  public criarSelectItem(label: string, value: any): SelectItemModel {
    let selecione: SelectItemModel = new SelectItemModel();
    selecione.label = label;
    selecione.value = value;
    return selecione;
  }

  public somenteNumeros(value: string): string {
    const newValue = value.replace(/\D/g, '');
    return newValue;
  }

    protected getTipoOcorrencia(): TipoOcorrencia {
        return new TipoOcorrencia({id: this.usuario.idTipoOcorrencia})
    }

    public isModuloSacOuvidoria() {
        return ModuloUtils.isModuloOuvidoria() || ModuloUtils.isModuloSac();
    }

    public isModuloInterna() {
        return (ModuloUtils.isModuloVivaVoz() && SessaoService.usuario.frenteAtual == null) || SessaoService.usuario.frenteAtual == EnumTipoOcorrencia.INTERNA;
    }

    public isModuloOuvidoria() {
        return (ModuloUtils.isModuloOuvidoria() && SessaoService.usuario.frenteAtual == null) || SessaoService.usuario.frenteAtual == EnumTipoOcorrencia.OUVIDORIA;
    }

    public isModuloSac() {
        return (ModuloUtils.isModuloSac() && SessaoService.usuario.frenteAtual == null) || SessaoService.usuario.frenteAtual == EnumTipoOcorrencia.SAC;
    }

    public replaceAll(str: string, needle: string, replacement: string) {
        return str.split(needle).join(replacement);
    }

    onPaste(event: ClipboardEvent, maxlength: number, form: FormGroup, nameElement: string) {
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        pastedText = pastedText.slice(0, maxlength);
        this.setValue(form, nameElement, pastedText);
    }

    handleHeaderCheckboxToggle(event: any, arrPagina: any[], pageNumber: number) {
        const isChecked = event.checked;

        if (isChecked) {
            // @ts-ignore
            this.paginacaoSelecionados[pageNumber] = _.cloneDeep(arrPagina);
        } else {
            this.paginacaoSelecionados[pageNumber] = [];
        }

        this.onRowSelectPaginate();

    }

    onRowSelectPaginate() {
        // @ts-ignore
        this.paginacaoSelecionadosAllPages = _.cloneDeep(this.paginacaoSelecionados);
        this.itensSelecionados = [];
        // @ts-ignore
        _.forEach(this.paginacaoSelecionadosAllPages, (value) => {
            if (value) {
                if (value.length > 0) {
                    // @ts-ignore
                    _.forEach(value, (v) => {
                        this.itensSelecionados.push(v);
                    });
                }
            }
        });
    }

    onRowUnselectPaginate() {
        // @ts-ignore
        this.paginacaoSelecionadosAllPages = _.cloneDeep(this.paginacaoSelecionados);
        this.itensSelecionados = this.paginacaoSelecionadosAllPages[0];
    }

    clearSelectedPage() {
        this.paginacaoSelecionadosAllPages = [];
        this.paginacaoSelecionados = [];
        this.itensSelecionados = [];
    }

}
