import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {OcorrenciaExterna} from 'app/shared/models/ocorrencia/ocorrencia-externa';

@Injectable()
export class PreOcorrenciaService extends CrudHttpClientService<OcorrenciaExterna> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/pre-ocorrencia', http);
  }

  public encerrar(ocorrencia: OcorrenciaExterna) {
    return this.http.post(this.url + '/' + ocorrencia.id + '/encerrar', ocorrencia, this.options());
  }

  public registarSAC(ocorrencia: OcorrenciaExterna) {
    return this.http.post(this.url + '/registarSAC', ocorrencia, this.options());
  }

  public enviarOuvidoria(ocorrencia: OcorrenciaExterna) {
    return this.http.post(this.url + '/enviarOuvidoria', ocorrencia, this.options());
  }
}
