import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {ProrrogacaoTarefa} from 'app/shared/models/ocorrencia/prorrogacao-tarefa';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Feriado} from 'app/shared/models/ocorrencia/feriado';

@Injectable()
export class ProrrogacaoTarefaService extends CrudHttpClientService<ProrrogacaoTarefa> {

  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/prorrogacao-tarefa', http);
  }

  public consultarPorTarefa(idTarefa: number): Observable<any> {
    return this.http.get(this.url + '/tarefa/' + idTarefa);
  }

  public obterDataProrrogacaoPorIncrementoDiasUteis(feriado: Feriado): Observable<any> {
    return this.http.post(this.url + '/obter-data-prorrogacao/', feriado, this.options());
  }

  public inserirMovimentacao(prorrogacaoTarefa: ProrrogacaoTarefa): Observable<any> {
    return this.http.post(this.url + '/inserir-movimentacao', prorrogacaoTarefa, this.options());
  }

}
