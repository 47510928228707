export enum SituacaoOcorrenciaEnum {

    //OUVIDORIA
    PENDENTE_REGISTRO = 1,
    PENDENTE_TRATAMENTO = 2,
    EM_TRATAMENTO = 3,
    PENDENTE_RESPOSTA = 4,
    RESPONDIDA_PENDENTE_JULGAMENTO = 5,
    RESPONDIDA_COM_PROMESSA_OUV = 6,
    PENDENTE_POSTAGEM = 7,
    RESPONDIDA_POSTADA = 8,
    RESPONDIDA_COM_JULGAMENTO = 20,
    RESPONDIDA_COM_RECURSO_ABERTO = 21,
    RESPONDIDA_COM_RECURSO_ANDAMENTO = 22,
    RESPONDIDA_COM_RECURSO_JULGADO = 23,
    RESPONDIDA_OUV = 24,
    REABERTA_OUV = 25,
    CANCELADA_OUV = 27,
    REABERTA_PEDIDO_COMPLEMENTAR_OUV = 29,
    REABERTA_PROVENIENTE_RECURSO = 30,

    //INTERNA
    ABERTA_INTERNA = 9,
    RESPONDIDA_INTERNA = 10,
    CANCELADA_INTERNA = 11,
    REABERTA_INTERNA = 12,
    VENCIDA_INTERNA = 13,
    AVALIADA_INTERNA = 26,
    
    //SAC
    ABERTA_SAC = 14,
    CANCELADA_SAC = 15,
    ENCERRADA = 16,
    REABERTA_SAC = 17,
    RESPONDIDA_SAC = 18,
    RESPONDIDA_COM_PROMESSA_SAC = 19,
    PENDENTE_REGISTRO_SAC = 28

}