import {FiltroPaginado} from '../models/filtroPaginado';
import {Base} from './base';
import {MessageService} from './messages/message.service';
import {Observable, Subject} from 'rxjs';
import {Pageable} from '../models/pageable';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs/Rx';
import {distinctUntilChanged, take} from 'rxjs/operators';
import {StoredPagination} from '../models/stored-pagination';
import {StoredPaginationUtil} from '../util/stored-pagination-util';
import {FormGroup} from '@angular/forms';
import {DataTable, Paginator} from 'primeng/primeng';

@Component({
  selector: 'app-base-pageable',
  template: ``,
})
export abstract class BasePageable extends Base implements OnInit, OnDestroy {
    public totalRegistros: number = 0;
    public rowsPerPage: number = 50;
    public pageOptions: number[] = [10, 20, 30, 50];
    public loading = false;
    public filtroPaginado: FiltroPaginado = new FiltroPaginado();
    public subscription: Subscription;
    public formulario: FormGroup;
    @ViewChild('dt') dataTable: DataTable;
    @ViewChild('paginator') paginator: Paginator;
    @ViewChild('paginatorTop') paginatorTop: Paginator;


    constructor(
        protected messageService: MessageService) {
        super(messageService);
    }

    ngOnInit() {
        this.recuperarFiltroPesquisa();
        this.consultarPaginado();
    }

    abstract consultarPaginado(): void;

    protected getFiltroPaginado(filtro: any): FiltroPaginado {
        this.filtroPaginado.filtro = filtro;
        return this.filtroPaginado;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    protected searchPageble(observable: Observable<any>): Observable<any> {

        let sub = new Subject();
        this.loading = true;

        this.subscription = observable.pipe(distinctUntilChanged(), take(1)).subscribe(
            (pageable: Pageable) => {
                this.totalRegistros = pageable.total;
                this.loading = false;
                sub.next(pageable.list);
            },
            error => {
                this.loading = false;
                sub.error(error);
            });

        return sub;
    }

    protected paginar(event: any) {
        this.recuperarFiltroPesquisa();
        this.filtroPaginado.pageNumber = event.page;
        this.filtroPaginado.pageSize = event.rows;
        this.consultarPaginado();
    }

    protected ordenarPaginado(event: any): any {
        this.recuperarFiltroPesquisa();
        if (event.sortField !== undefined || event.field !== undefined) {
            this.filtroPaginado.orderField = event.sortField || event.field;
            this.filtroPaginado.order = event.sortOrder || event.order;
        }
        this.consultarPaginado();
        return null;
    }

    protected recuperarFiltroPesquisa() {
        const recoveredPagination: StoredPagination = StoredPaginationUtil.recoverPagination();
        if (recoveredPagination) {
            if (recoveredPagination.getFormValues() && this.formulario) {
                for (let formValue of recoveredPagination.getFormValues()) {
                    this.formulario.controls[formValue.name].setValue(formValue.value);
                }
            }
            if (recoveredPagination.getFiltroPaginado()) {
                this.filtroPaginado.merge(recoveredPagination.getFiltroPaginado());
            }
        }
    }

    clearAndSearch() {
        StoredPaginationUtil.clearStoredPagination();
        this.filtroPaginado = new FiltroPaginado();
        this.dataTable.reset();
        this.paginator.first = 0;
        this.paginatorTop.first = 0;
        if (this.getControl(this.formulario, 'id')) {
            this.setValue(this.formulario, 'id', null);
        }
        if (this.getControl(this.formulario, 'listId')) {
            this.setValue(this.formulario, 'listId', null);
        }
        this.consultarPaginado();
    }

    populateTablePaginatorFilter(filtro: FiltroPaginado) {
        this.dataTable._sortField = filtro.orderField;
        this.dataTable._sortOrder = filtro.order;
        if (filtro.pageNumber === 0) {
            this.dataTable.first = 0;
            if(this.paginator){
              this.paginator.first = 0;
            }
            if(this.paginatorTop){
              this.paginatorTop.first = 0;
            }
        } else {
            this.dataTable.first = filtro.pageSize * filtro.pageNumber + 1;
            if(this.paginator){
              this.paginator.first = filtro.pageSize * filtro.pageNumber + 1;
            }
            if(this.paginatorTop){
              this.paginatorTop.first = filtro.pageSize * filtro.pageNumber + 1;
            }
        }
    }
}
