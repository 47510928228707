import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {Base} from '../base';
import {MessageService} from '../messages/message.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Util} from 'app/arquitetura/shared/util/util';

@Component({
    selector: 'app-input-date-local',
    templateUrl: './input-date-local.component.html',
    styleUrls: ['./input-date-local.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputDateLocalComponent),
            multi: true
        }
    ]
})
export class InputDateLocalComponent extends Base implements OnInit, ControlValueAccessor {

    @Output() public onChange: EventEmitter<any> = new EventEmitter();
    @Input() _value: any;
    @Input() showTime: boolean;
    @Input() inputStyleClass: string;
    @Input() isDisabled: boolean;

    pt: any;

    constructor(protected messageService: MessageService) {
        super(messageService);
    }

    ngOnInit() {
        if (!this.showTime) {
            this.showTime = false;
        }
        if (!this.inputStyleClass) {
            this.inputStyleClass = '';
        }
        this.pt = {
            firstDayOfWeek: 0,
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Out', 'Nov', 'Dez'],
            today: 'Hoje',
            clear: 'Limpar'
        };
    }

    get value() {
        return this._value;
    }

    set value(val) {
        this._value = val;
        this._onChange(this._value);
    }

    writeValue(value: any) {
        if (value !== undefined) {
            if (Util.isString(value)) {
                this.value = new Date(value);
            } else {
                this.value = value;
            }
        }
    }

    _onChange(value: any) {
        return;
    }

    onChangeCallback(event: any) {
        this.onChange.emit(event);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }
}
