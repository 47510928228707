export class CustomTableCol {
    constructor (
        public id: string,
        public text: string,
        public width: number,
        public clazz: any,
        public style: any,
        public object: any,
        public callback?: (object: any) => any
    ) {}
}
