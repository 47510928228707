import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoaderService} from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) { }

  private filaRequisicoes = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.filaRequisicoes === 0 && !this.loaderService.isLoading()) {
      this.loaderService.setLoading(true)
    }

    this.filaRequisicoes++;
    return next.handle(req).pipe(
      finalize(() => this.stopLoader())
    );
  }

  stopLoader() {
    this.filaRequisicoes--;
    if (this.filaRequisicoes === 0) {
       this.loaderService.setLoading(false);
    }
  }
}
