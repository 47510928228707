import {Injectable} from '@angular/core';
import {CorrespondenteUnidade} from 'app/shared/models/comum/correspondente-unidade.model';
import {Subject} from 'rxjs';

@Injectable()
export class CorrespondenteUnidadeService {

  private correspondenteUnidadeSubject$ = new Subject<CorrespondenteUnidade>();
  correspondenteUnidade$ = this.correspondenteUnidadeSubject$.asObservable();

  emiteCorrespondenteUnidadeSubject(correspondente: CorrespondenteUnidade) {
    this.correspondenteUnidadeSubject$.next(correspondente);
  }
}
