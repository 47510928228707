import {EnumTipoOcorrencia} from './../../../../shared/enums/EnumTipoOcorrencia';
import {MenuItem} from 'primeng/primeng';
import {MenuLateralComponent} from '../menu-lateral/menu-lateral.component';
import {PerfilModulo} from 'app/shared/models/perfil-modulo';
import {SessaoService} from '../../services/seguranca/sessao.service';

export class ItensMenuSac {

  constructor(private menuParent: MenuLateralComponent, private sessaoService: SessaoService) {
  }

  private hasPermission(roles: string[]): boolean {
    let isApresenta: boolean = false;
    let usuarioExterno = this.sessaoService.getUsuario().usuarioExterno;
    roles.forEach(role => {
      if (this.sessaoService.getPerfilUsuarioSelecionado().perfil === role) {
        isApresenta = true;
      }
    });
    return isApresenta;
  }

  public get itens(): MenuItem[] {
    return [
      // SAC
      {
        label: "SAC",
        icon: "fa fa-cogs",
        visible: this.hasPermission([
          PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
          PerfilModulo.SAC_AUDITORIA.value,
          PerfilModulo.SAC_GEST_UNID_ATEND.value,
          PerfilModulo.SAC_OPER_UNID_ATEND.value,
          PerfilModulo.SAC_OPER_N1.value,
          PerfilModulo.SAC_OPER_N2.value,
          PerfilModulo.SAC_ESTAGIARIO.value,
          PerfilModulo.OUV_ESTAGIARIOMENOR.value
        ]),
        items: [
          {
            label: "Parametrização",
            icon: "fa fa-angle-double-right",
            visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                                        PerfilModulo.SAC_GEST_UNID_ATEND.value,
                                        PerfilModulo.SAC_AUDITORIA.value,
                                        PerfilModulo.SAC_OPER_N1.value,
                                        PerfilModulo.SAC_OPER_N2.value,
                                        PerfilModulo.SISOU_UNID_CAIXA.value,
                                        PerfilModulo.SAC_ESTAGIARIO.value,
                                        PerfilModulo.OUV_ESTAGIARIOMENOR.value
                                      ]),
            items: [
              {
                label: "Origem",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/origem/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                                            PerfilModulo.SAC_AUDITORIA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Natureza",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/natureza/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                                            PerfilModulo.SAC_AUDITORIA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Produto",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/produto/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                                            PerfilModulo.SAC_AUDITORIA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Problema",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/problema/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                                              PerfilModulo.SAC_AUDITORIA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Categorização",
                icon: "fa fa-angle-double-right",
                routerLink:
                  "/administracao/parametrizacao/categorizacao-ocorrencia-sac/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                  PerfilModulo.SAC_AUDITORIA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Canal",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/canal/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                  PerfilModulo.SAC_AUDITORIA.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Orientação Atendimento",
                icon: "fa fa-angle-double-right",
                routerLink:
                  "/administracao/parametrizacao/orientacao-atendimento/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                                            PerfilModulo.SAC_GEST_UNID_ATEND.value,
                                            PerfilModulo.SAC_AUDITORIA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Pedido do Cliente",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/pedido-cliente/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                                            PerfilModulo.SAC_GEST_UNID_ATEND.value,
                                            PerfilModulo.SAC_AUDITORIA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Grupo Responsável por Tratamento",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/grupo-tratamento-sac/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                                            PerfilModulo.SAC_GEST_UNID_ATEND.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Equipe Responsável",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/equipe-responsavel/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                                            PerfilModulo.SAC_GEST_UNID_ATEND.value,
                                            PerfilModulo.SAC_AUDITORIA.value,
                                            PerfilModulo.SAC_OPER_N1.value,
                                            PerfilModulo.SAC_OPER_N2.value,
                                            PerfilModulo.SISOU_UNID_CAIXA.value,
                                            PerfilModulo.SAC_ESTAGIARIO.value,
                                            PerfilModulo.OUV_ESTAGIARIOMENOR.value
                                          ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Parâmetro Avaliação",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/parametro-avaliacao-sac/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);

                }
              },
              {
                label: "Penalidade",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/penalidade-sac-ouv/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value, PerfilModulo.OUV_GESTORSISTEMA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);

                }
              },
              {
                label: "SMS",
                icon: "fa fa-angle-double-right",
                routerLink:
                  "/administracao/parametrizacao/sms/",
                visible: this.hasPermission([
                  PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                  PerfilModulo.SAC_OPER_UNID_ATEND.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);

                }
              },
              {
                label: "Unidade Bloqueada",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/unidade-bloqueada-so/",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Ajuda",
                icon: "fa  fa-question-circle",
                routerLink: "/subsidiaria/ajuda",
                visible: this.hasPermission([PerfilModulo.SAC_GEST_ADM_SISTEMA.value]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
            ]
          },
          {
            label: "Registra Ocorrência",
            icon: "fa fa-angle-double-right",
            routerLink: "/ocorrencia/ocorrencia-sac",
            visible: this.hasPermission([
                PerfilModulo.SAC_GEST_UNID_ATEND.value,
                PerfilModulo.SAC_AUDITORIA.value,
                PerfilModulo.SAC_ESTAGIARIO.value,
                PerfilModulo.SAC_OPER_N1.value,
                PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
                // PerfilModulo.SISOU_UNID_CAIXA.value,
                PerfilModulo.SAC_OPER_UNID_ATEND.value,
                PerfilModulo.SAC_OPER_N2.value,
            ]),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);

            }
          },
          {
            label: "Painel de Controle",
            icon: "fa fa-angle-double-right",
            routerLink: "/tratar-ocorrencia/tratar-ocorrencia-sac/painel-controle",
            visible: this.hasPermission([
              PerfilModulo.SAC_GEST_UNID_ATEND.value,
              PerfilModulo.SAC_AUDITORIA.value,
              PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
              PerfilModulo.SISOU_UNID_CAIXA.value,
              PerfilModulo.SAC_OPER_UNID_ATEND.value,
              PerfilModulo.SAC_OPER_N2.value,
              PerfilModulo.SAC_OPER_N1.value,
              PerfilModulo.SAC_ESTAGIARIO.value
            ]),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
            }
          },
          {
            label: "Painel Avaliação",
            icon: "fa fa-angle-double-right",
            routerLink: "/tratar-ocorrencia/tratar-ocorrencia-sac/painel-avaliacao",
            visible: this.hasPermission([
              PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
              PerfilModulo.SAC_GEST_UNID_ATEND.value,
              PerfilModulo.SAC_OPER_UNID_ATEND.value
            ]),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
            }
          },
          {
            label: "Gestão da Avaliação",
            icon: "fa fa-angle-double-right",
            routerLink: "/tratar-ocorrencia/tratar-ocorrencia-sac/gestao-avaliacao",
            visible: this.hasPermission([
              PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
              PerfilModulo.SAC_GEST_UNID_ATEND.value,
            ]),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
            }
          },
          {
            label: "Pré-Ocorrencia",
            icon: "fa fa-angle-double-right",
            routerLink: "/ocorrencia/pre-ocorrencia",
            visible: this.hasPermission([
              PerfilModulo.SAC_GEST_UNID_ATEND.value,
              PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
              PerfilModulo.SAC_OPER_UNID_ATEND.value,
              PerfilModulo.SAC_OPER_N2.value,
              PerfilModulo.SAC_OPER_N1.value,
              PerfilModulo.SAC_ESTAGIARIO.value
            ]),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
            }
          }
        ]
      },
    ];
  }

}

