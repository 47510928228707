import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {

  @Input('data') data: string[] = [];
  @Input('config') config = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1
  };
  @Input('pageChange') pageChange = function (event) {
    this.config.currentPage = event;
    return event;
  };

}
