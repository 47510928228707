import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from 'app/arquitetura/shared/guards/security/auth.guard';
import {DadosUsuarioGuard} from 'app/arquitetura/shared/guards/security/dados-usuario.guard';
import { AguardarAtualizacaoPaginaComponent } from './arquitetura/shared/templates/aguardar-atualizacao-pagina/aguardar-atualizacao-pagina.component';


const appRoutes: Routes = [
	{
		path: 'home',
		// loadChildren: 'app/arquitetura/home/home.module#HomeModule',
    loadChildren: 'app/subsidiaria/subsidiaria.module#SubsidiariaModule',
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'subsidiaria',
		loadChildren: 'app/subsidiaria/subsidiaria.module#SubsidiariaModule',
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'administracao',
		loadChildren: 'app/administracao/administracao.module#AdministracaoModule',
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'ocorrencia',
		loadChildren: 'app/ocorrencia/ocorrencia.module#OcorrenciaModule',
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'relatorios',
		loadChildren: 'app/relatorios/relatorios.module#RelatoriosModule',
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'tratar-ocorrencia',
		loadChildren: 'app/tratar-ocorrencia/tratar-ocorrencia.module#TratarOcorrenciaModule',
		/*canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]*/
	},
	{
		path: 'ura',
		loadChildren: 'app/ura/ura.module#UraModule',
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	/*CAST_MAKER_ROTA_MODULO*/
	{ path: '', pathMatch: 'full', redirectTo: '/home' },
	{ path: '**', component: AguardarAtualizacaoPaginaComponent, canActivate: [AuthGuard] }
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule {
	constructor(private router: Router) {
	}
}
