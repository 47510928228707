import {Component, Input, OnInit} from '@angular/core';
import {CrudUtils} from './../../../arquitetura/shared/util/crud-utils';
import {EnumClienteCategorizacao} from './../../enums/EnumClienteCategorizacao';
import {EnumFalhaCaixa} from './../../enums/EnumFalhaCaixa';
import {ClassificacaoPedidos} from './../../models/administracao/parametrizacao/classificacao-pedidos';
import {OcorrenciaExterna} from './../../models/ocorrencia/ocorrencia-externa';
import {RegistroTarefaDTO} from './../../models/ocorrencia/registro-tarefa-dto';
import {ArquivoService} from './../../services/comum/arquivo.service';


@Component({
  selector: 'app-modal-visualizar-ocorrencia',
  templateUrl: './modal-visualizar-ocorrencia.component.html',
  styleUrls: ['./modal-visualizar-ocorrencia.component.scss']
})
export class ModalVisualizarOcorrenciaComponent {

  display: boolean = false;
  @Input() ocorrencia: OcorrenciaExterna;

  dataResposta: Date;
  emailPrincipal: string;
  telefonePrincipal: number;

  constructor(private arquivoService: ArquivoService) { }

  public show(): void {
    if(!this.ocorrencia.cabecalhoDTO.nomeCliente && this.ocorrencia.solicitante && this.ocorrencia.solicitante.id){

      let telefoneSolicitantes = this.ocorrencia.solicitante.telefonesDTO.filter(tel => tel.tipo == 'P');
      if(telefoneSolicitantes && telefoneSolicitantes.length > 0){
        this.telefonePrincipal = telefoneSolicitantes[0].numero;
      }

      let emailSolicitantes = this.ocorrencia.solicitante.emailsDTO.filter(email => email.selecionado);
      if(emailSolicitantes && emailSolicitantes.length > 0){
        this.emailPrincipal = emailSolicitantes[0].padrao;
      }
    }

    this.display = true;
  }

  get dataRecebimento() {
    return this.formataData(this.ocorrencia.dataInicioOcorrenciaExterna);
  }
  get dataFim() {
    return this.formataData(this.ocorrencia.dataFinalOcorrenciaExterna);
  }

  get endereco() {
    let endereco;

    return '';
  }

  get pedidoVinculado() {
    if (this.classificacao) {
      return this.classificacao.pedidosCliente[0] ? this.classificacao.pedidosCliente[0].nome : null;
    }
    return ''
  }

  getPenalidade(tarefa: RegistroTarefaDTO): string {
    let penalidade = tarefa.penalidades[0] ? tarefa.penalidades[0].penalidade : null;
    return penalidade ? penalidade.nome : '';
  }

  get classificacao(): ClassificacaoPedidos {
    if (this.ocorrencia) {
      return this.ocorrencia.classificacoes[0] ? this.ocorrencia.classificacoes[0] : null;
    }
    return null;
  }

  get orientacaoClassificacao(): string {
    if (this.ocorrencia) {
      if(this.ocorrencia.classificacoes[0]){
        if( this.ocorrencia.classificacoes[0].orientacoes[0]){
          return  this.ocorrencia.classificacoes[0].orientacoes[0].texto;
        }
      }
    }
    return ''
  }

  getPrazo(tarefa: RegistroTarefaDTO){
    return this.workday_count(tarefa.dataAbertura,tarefa.dataPrazo);
  }


  workday_count(startDate:Date, end:Date) {
    let count = 0;
    let curDate = new Date(startDate);
    let endDate = new Date(end);
    while (curDate.getTime() <= endDate.getTime()) {
        let dayOfWeek = curDate.getDay();
        if(!((dayOfWeek == 6) || (dayOfWeek == 0))){
          count++;
        }
        curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

get orientacaoOcorrencia(): string{
  if (this.ocorrencia) {
    return this.ocorrencia.classificacoes[0] ? this.ocorrencia.classificacoes[0].orientacoes[0].texto : null
  }
  return '';
}


get tarefas(){
  let tarefas: RegistroTarefaDTO[];
  if (this.ocorrencia) {
    tarefas = this.ocorrencia.classificacoes[0].tarefas;
  }
  return tarefas;
}

get header(): string {
  let header;
  let classificacao: ClassificacaoPedidos;
  if (this.ocorrencia)
    classificacao = this.ocorrencia.classificacoes[0];
  if (classificacao) {
    const labelCanal = classificacao.noCanal ? ` > ${classificacao.noCanal}` : '';
    header = `${classificacao.noProduto} > ${classificacao.noProblema}${labelCanal}`;
  }
  return header;
}

get falhaCaixa(): string{
  if (this.classificacao) {
    return this.classificacao.falhaCaixa == EnumFalhaCaixa.PROCEDENTE ? 'Procedente' : 'Não Procedente';
  }
  return ''
}

get paraCliente(): string{
  if (this.classificacao) {
    return this.classificacao.satisfacaoCliente == EnumClienteCategorizacao.RESOLVIDO ? 'Resolvido' : 'Não resolvido'
  }
  return null;
}

get orientacoes(): string[]{
  let orientacoesRetorno = [];
  if (this.ocorrencia) {
    orientacoesRetorno = this.ocorrencia.orientacoes.map(orientacao => {
      return orientacao.texto;
    })
  }
  return orientacoesRetorno;
}

isOrigemProcon(): boolean{
  if (this.ocorrencia) {
    return this.ocorrencia.noOrigem == "PROCON"
  }
}

isOrigemBACEN(): boolean{
  if (this.ocorrencia) {
    return this.ocorrencia.noOrigem == "BACEN"
  }
}

isTipoNotificacaoInstitucional(): boolean{
  if (this.ocorrencia) {
    return this.ocorrencia.cabecalhoDTO.tipoAtendimentoPosto == "Notificação Institucional";
  }
}

isTipoEntidadeCivil(): boolean{
  if (this.ocorrencia) {
    return this.ocorrencia.cabecalhoDTO.tipoAtendimentoPosto == "Entidades Civis";
  }
}

isTipoAtendimentoPreliminar(): boolean {
  if (this.ocorrencia) {
    return this.ocorrencia.cabecalhoDTO.tipoAtendimentoPosto == "Atendimento Preliminar";
  }
}


downloadArquivo(codGed: string) {
  CrudUtils.downloadArquivo(this.arquivoService, codGed);
}

formataData(data) : string {
  let dataRetorno = new Date(data);
  let dia;
  let mes;
  let ano;

  dia = dataRetorno.getDate();
  mes = dataRetorno.getMonth() + 1;
  ano = dataRetorno.getFullYear();

  mes = mes > 9 ? mes : '0' + mes;
  dia = dia > 9 ? dia : '0' + dia;

  return dia + '/' + mes + '/' + ano;
}
}
