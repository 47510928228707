import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CausaRaizService} from './administracao/especificacoes/causa-raiz.service';
import {AssuntoService} from './administracao/parametrizacao/assunto.service';
import {AvaliacaoNaturezaService} from './administracao/parametrizacao/avaliacao-natureza.service';
import {CanalService} from './administracao/parametrizacao/canal.service';
import {CategorizacaoOcorrenciaService} from './administracao/parametrizacao/categorizacao-ocorrencia.service';
import {
    ClassificacaoOcorrenciaExternaService
} from './administracao/parametrizacao/classificacao-ocorrencia-externa.service';
import {DadosProconService} from './administracao/parametrizacao/dados-procon.service';
import {EquipeService} from './administracao/parametrizacao/equipe.service';
import {FormaRecebimentoRespostaService} from './administracao/parametrizacao/forma-recebimento-resposta.service';
import {GrupoTratamentoMetaService} from './administracao/parametrizacao/grupo-tratamento-meta.service';
import {GrupoTratamentoService} from './administracao/parametrizacao/grupo-tratamento.service';
import {IndicadoresMembroService} from './administracao/parametrizacao/indicadores.membro.service';
import {ItemService} from './administracao/parametrizacao/item.service';
import {JustificativaProrrogacaoService} from './administracao/parametrizacao/justificativa-prorrogacao.service';
import {JustificativaService} from './administracao/parametrizacao/justificativa.service';
import {MensagemEmailService} from './administracao/parametrizacao/mensagem-email.service';
import {MotivoRetornoService} from './administracao/parametrizacao/motivo-retorno.service';
import {MotivoService} from './administracao/parametrizacao/motivo.service';
import {NaturezaService} from './administracao/parametrizacao/natureza.service';
import {OrientacaoAtendimentoService} from './administracao/parametrizacao/orientacao-atendimento.service';
import {OrigemService} from './administracao/parametrizacao/origem.service';
import {PalavraProibidaService} from './administracao/parametrizacao/palavra-proibida.service';
import {ParametroAvaliacaoService} from './administracao/parametrizacao/parametro-avaliacao.service';
import {ParametroConsoleService} from './administracao/parametrizacao/parametro-console.service';
import {ParametroSistemaService} from './administracao/parametrizacao/parametro-sistema.service';
import {PedidoClienteService} from './administracao/parametrizacao/pedido-cliente.service';
import {PerguntaAvaliacaoService} from './administracao/parametrizacao/pergunta-avaliacao.service';
import {PostoAtendimentoProconService} from './administracao/parametrizacao/posto-atendimento-procon.service';
import {PriorizacaoDistribuicaoService} from './administracao/parametrizacao/priorizacao-distribuicao.service';
import {ProblemaComunicacaoService} from './administracao/parametrizacao/problema-comunicacao.service';
import {ProblemaService} from './administracao/parametrizacao/problema.service';
import {ProdutoService} from './administracao/parametrizacao/produto.service';
import {SatisfacaoStaService} from './administracao/parametrizacao/satisfacao-sta.service';
import {TemplateService} from './administracao/parametrizacao/template.service';
import {TextoComplementarService} from './administracao/parametrizacao/texto-complementar.service';
import {TipoAtendimentoProconService} from './administracao/parametrizacao/tipo-atendimento-procon.service';
import {
    TipoAtendimentoZeroOitocentosService
} from '../../administracao/parametrizacao/tipo-atendimento-zero-oitocentos/services/tipo-atendimento-zero-oitocentos.service';
import {TipoDirecionamentoService} from './administracao/parametrizacao/tipo-direcionamento.service';
import {TipoEventoService} from './administracao/parametrizacao/tipo-evento.service';
import {
    TipoMovimentacaoTextoComplementarService
} from './administracao/parametrizacao/tipo-movimentacao-texto-complementar.service';
import {TipoMovimentacaoService} from './administracao/parametrizacao/tipo-movimentacao.service';
import {TipoOcorrenciaService} from './administracao/parametrizacao/tipo-ocorrencia.service';
import {UnidadeBloqueadaService} from './administracao/parametrizacao/unidade-bloqueada.service';
import {CartilhaService} from './ajuda/cartilha.service';
import {ManualNormativoService} from './ajuda/manual-normativo.service';
import {PerguntaRespostaService} from './ajuda/pergunta-resposta.service';
import {PrazoSistemaService} from './ajuda/prazo-sistema.service';
import {ArquivoService} from './comum/arquivo.service';
import {ComboService} from './comum/combo.service';
import {ComumService} from './comum/comum.service';
import {CorrespondenteUnidadeService} from './comum/correspondente-unidade.service';
import {CorrespondenteService} from './comum/correspondente.service';
import {EmpregadoService} from './comum/empregado.service';
import {LocalidadeService} from './comum/localidade.service';
import {UnidadeService} from './comum/unidade.service';
import {AnexoOcorrenciaOuvidoriaService} from './ocorrencia/anexo-ocorrencia-ouvidoria.service';
import {AvaliacaoOcorrenciaExternaService} from './ocorrencia/avaliacao-ocorrencia-externa.service';
import {AvaliacaoOcorrenciaService} from './ocorrencia/avaliacao-ocorrencia.service';
import {DiarioBordoService} from './ocorrencia/diario-bordo.service';
import {EquipeOcorrenciaService} from './ocorrencia/equipe-ocorrencia.service';
import {MotivoAvaliacaoService} from './ocorrencia/motivo-avaliacao.service';
import {MotivoEncerramentoService} from './ocorrencia/motivo-encerramento.service';
import {MovimentacaoOcorrenciaService} from './ocorrencia/movimentacao-ocorrencia.service';
import {OcorrenciaExternaService} from './ocorrencia/ocorrencia-externa.service';
import {OcorrenciaGestorTempoMedioService} from "./ocorrencia/ocorrencia-gestor-tempo-medio.service";
import {OcorrenciaInternaService} from './ocorrencia/ocorrencia-interna.service';
import {OcorrenciaOuvidoriaService} from './ocorrencia/ocorrencia-ouvidoria.service';
import {OcorrenciaSacService} from './ocorrencia/ocorrencia-sac.service';
import {PaginaInicialSubsidiariaService} from './ocorrencia/pagina-inicial-subsidiaria.service';
import {PainelControleSacService} from './ocorrencia/painel-controle-sac.service';
import {PenalidadeTarefaService} from './ocorrencia/penalidade-tarefa.service';
import {PreOcorrenciaService} from './ocorrencia/pre-ocorrencia.service';
import {ProrrogacaoOcorrenciaService} from './ocorrencia/prorrogacao-ocorrencia.service';
import {ProrrogacaoTarefaService} from './ocorrencia/prorrogacao-tarefa.service';
import {SituacaoOcorrenciaService} from './ocorrencia/situacao-ocorrencia.service';
import {SituacaoTarefaService} from './ocorrencia/situacao-tarefa.service';
import {SolicitacaoOcorrenciaInternaService} from './ocorrencia/solicitacao-ocorrencia-interna.service';
import {SolicitanteService} from './ocorrencia/solicitante.service';
import {SubsidioOcorrenciaService} from './ocorrencia/subsidio-ocorrencia.service';
import {TarefaSacService} from './ocorrencia/tarefa-sac.service';
import {TarefaService} from './ocorrencia/tarefa.service';
import {TipoSolicitacaoOcorrenciaInternaService} from './ocorrencia/tipo-solicitacao-ocorrencia-interna.service';
import {TratamentoOcorrenciaExternaService} from './ocorrencia/tratamento-ocorrencia-externa.service';
import {SolicitacaoUnidadeService} from './solicitacao/solicitacao-unidade.service';
import {MovimentacaoOcorrenciaInternaService} from './tratar-ocorrencia/movimentacao-ocorrencia-interna.service';
import {TratarOcorrenciaInternaService} from './tratar-ocorrencia/tratar-ocorrencia-interna.service';
import {MensagemSmsService} from './administracao/parametrizacao/mensagem-sms.service';
import {TipoMensagemSmsService} from './administracao/parametrizacao/tipo-mensagem-sms.service';
import {RdrBacenService} from "./administracao/rdr-bacen/rdr-bacen.service";
import {UsuarioExternoService} from './comum/usuario-externo.service';
import {EmpregadoVinculadoService} from './administracao/parametrizacao/empregao-vinculado.service';
import { RelatorioService } from './ocorrencia/relatorio.service';


/*CAST_MAKER_SERVICE_IMPORT*/
/**
 * Modulo responsável por prover os serviços de integração e de apoio
 * específicos da aplicação
 */
@NgModule({
    imports: [CommonModule],
    declarations: []
})
export class AppServiceModule {
    /**
     * Convenção usada para que o módulo 'app' disponibilize as instâncias 'providers'
     * como singleton para todos os modulos da aplicação.
     */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppServiceModule,
            providers: [
                ArquivoService,
                ComumService,
                ComboService,
                EmpregadoService,
                LocalidadeService,
                UnidadeService,
                AssuntoService,
                AvaliacaoNaturezaService,
                CanalService,
                CategorizacaoOcorrenciaService,
                CausaRaizService,
                ClassificacaoOcorrenciaExternaService,
                DadosProconService,
                FormaRecebimentoRespostaService,
                GrupoTratamentoService,
                ItemService,
                MensagemEmailService,
                MensagemSmsService,
                TipoMensagemSmsService,
                MotivoRetornoService,
                MotivoService,
                NaturezaService,
                OrientacaoAtendimentoService,
                OrigemService,
                PedidoClienteService,
                PerguntaAvaliacaoService,
                PostoAtendimentoProconService,
                ProblemaService,
                ProdutoService,
                SatisfacaoStaService,
                TemplateService,
                TipoAtendimentoProconService,
                TipoDirecionamentoService,
                TipoEventoService,
                TipoMovimentacaoService,
                TipoMovimentacaoTextoComplementarService,
                TextoComplementarService,
                TipoOcorrenciaService,
                UnidadeBloqueadaService,
                OcorrenciaExternaService,
                OcorrenciaInternaService,
                OcorrenciaOuvidoriaService,
                OcorrenciaSacService,
                PainelControleSacService,
                SolicitanteService,
                TarefaService,
                TarefaSacService,
                MotivoAvaliacaoService,
                MovimentacaoOcorrenciaInternaService,
                TratarOcorrenciaInternaService,
                JustificativaService,
                EquipeService,
                JustificativaProrrogacaoService,
                ProrrogacaoTarefaService,
                SubsidioOcorrenciaService,
                PaginaInicialSubsidiariaService,
                AvaliacaoOcorrenciaService,
                SolicitacaoUnidadeService,
                PenalidadeTarefaService,
                ProrrogacaoOcorrenciaService,
                TratamentoOcorrenciaExternaService,
                ManualNormativoService,
                PerguntaRespostaService,
                PrazoSistemaService,
                CartilhaService,
                SituacaoOcorrenciaService,
                PalavraProibidaService,
                TipoSolicitacaoOcorrenciaInternaService,
                SolicitacaoOcorrenciaInternaService,
                ParametroAvaliacaoService,
                PriorizacaoDistribuicaoService,
                TipoAtendimentoZeroOitocentosService,
                DiarioBordoService,
                AvaliacaoOcorrenciaExternaService,
                GrupoTratamentoMetaService,
                IndicadoresMembroService,
                EquipeOcorrenciaService,
                ProblemaComunicacaoService,
                CorrespondenteService,
                CorrespondenteUnidadeService,
                MovimentacaoOcorrenciaService,
                AnexoOcorrenciaOuvidoriaService,
                SituacaoTarefaService,
                PreOcorrenciaService,
                MotivoEncerramentoService,
                OcorrenciaGestorTempoMedioService,
                ParametroConsoleService,
                ParametroSistemaService,
                RdrBacenService,
                UsuarioExternoService,
                EmpregadoVinculadoService,
                RelatorioService,
            ]
        };
    }
}

