import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'tipoPrazo' })
export class TipoPrazoPipe implements PipeTransform {
	transform(value: string) {
		if (value === 'U') {
			return 'Dias Úteis';
		}
		if (value === 'C') {
			return 'Dias Corridos';
		}
		return value;
	}
}