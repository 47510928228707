import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {RetornoCorreioCancelamento} from 'app/shared/models/ocorrencia/RetornoCorreioCancelamento';
import {RetornoCorreioCancelamentoResposta} from 'app/shared/models/ocorrencia/RetornoCorreioCancelamentoResposta';
import {RetornoCorreioDadosArquivo} from 'app/shared/models/ocorrencia/RetornoCorreioDadosArquivo';
import {RetornoCorreioInconsistencia} from 'app/shared/models/ocorrencia/RetornoCorreioInconsistencia';
import {RetornoCorreioNotificacao} from 'app/shared/models/ocorrencia/RetornoCorreioNotificacao';
import {RetornoCorreioRastreamentoData} from 'app/shared/models/ocorrencia/RetornoCorreioRastreamentoData';
import {RetornoCorreioRastreamentoFinal} from 'app/shared/models/ocorrencia/RetornoCorreioRastreamentoFinal';
import {RetornoCorreioRespostaNotificacao} from 'app/shared/models/ocorrencia/RetornoCorreioRespostaNotificacao';
import {RetornoCorreio} from 'app/shared/models/ocorrencia/webserviceCorreio';
import {OcorrenciaGestaoWebservicesService} from 'app/shared/services/ocorrencia/ocorrencia-gestao-webservices.service';
import {MessageService} from '../messages/message.service';
import {RetornoCorreioDadosOcorrencia} from "../../models/ocorrencia/RetornoCorreioDadosOcorrencia";
import {BasePageable} from "../base-pageable";
import {FiltroPaginado} from "../../models/filtroPaginado";
import {CrudUtils} from "../../../arquitetura/shared/util/crud-utils";
import {TipoRelatorio} from "../../../arquitetura/shared/reports/tipo-relatorio";
import {WebUtil} from "../../../arquitetura/shared/util/web-util";
import {ArquivoService} from "../../services/comum/arquivo.service";

@Component({
    selector: 'app-modal-detalhar-correio',
    templateUrl: './modal-detalhar-correio.component.html',
    styleUrls: ['./modal-detalhar-correio.component.scss']
})
export class ModalDetalharCorreioComponent extends BasePageable {

    colsDadArqu1: any[] = [
        {field: 'codCliente', header: 'Código do Objeto Cliente'},
        {field: 'numeroLote', header: 'Número do Lote'},
        {field: 'cartaoPostagem', header: 'Cartão de Postagem'},
        {field: 'numeroContrato', header: 'Número do Contrato'},
        {field: 'servicoAdicional', header: 'Serviço Adicional'},
        {field: 'identificadorArquivoSpool', header: 'Identificador do Arquivo Spool'},
        {field: 'nomeArquivoSpool', header: 'Nome do Arquivo Spool'},
        {field: 'nomeDestinatario', header: 'Nome do Destinatário'},
        {field: 'enderecoDestinatario', header: 'Endereço do Destinatário'},
        {field: 'numeroEnderecoDestinatario', header: 'Número do Endereço Destinatário'},
        {field: 'complementoEnderecoDestinatario', header: 'Complemento do Endereço do Destinatário'},
        {field: 'bairroDestinatario', header: 'Bairro do Destinatário'},
        {field: 'cidadeDestinatario', header: 'Cidade do Destinatário'},
        {field: 'ufDestinatario', header: 'UF do Destinatário'},
        {field: 'cepDestinatario', header: 'CEP do Destinatário'},
        {field: 'identificarArquivoComplementar', header: 'Identificador do Arquivo Complementar'},
        {field: 'nomeArquivoComplementar', header: 'Nome do Arquivo Complementar'}
    ];


    colsOcorrencia: any[] = [
        {field: 'nuIdentificacaoOcorrencia', header: 'Ocorrência'},
        {field: 'noOrigem', header: 'Origem'},
        {field: 'noNatureza', header: 'Natureza'},
        {field: 'dtOcorrencia', header: 'Data Ocorrência'},
        {field: 'cpfCnpjCliente', header: 'CPF/CNPJ Cliente'},
        {field: 'noCliente', header: 'Nome'},
        {field: 'dtRespostaOuvidoria', header: 'Data Resposta Ouvidoria'},
        {field: 'deResposta', header: 'Resposta'},
        {field: 'anexo', header: 'Anexo'}
    ];

    colsDadoArquivoNoti: any[] = [
        {field: 'codCliente', header: 'Código do Objeto Cliente'},
        {field: 'numeroLote', header: 'Número do Lote'},
        {field: 'numeroEtiqueta', header: 'Número da Etiqueta'},
        {field: 'informacaoLimitePostagem', header: 'Informação sobre Limite de Postagem'},
    ];

    colsDadoArquivoIncon: any[] = [
        {field: 'codObjetoClieIncons', header: 'Código do Objeto Cliente Inconsistente'},
        {field: 'identiTipoIncon', header: 'Identificador Tipo Inconsistência Objeto'},
        {field: 'mensagemTipoInco', header: 'Mensagem Tipo Inconsistência Objeto'},
    ];

    colsDadoRespostaNotif: any[] = [
        {field: 'numeroLote', header: 'Número do Lote'},
        {field: 'respostaNotifi', header: 'Resposta a Notificação'},
    ];

    colsRastreamentoAntecipadoData1: any[] = [
        {field: 'numeroLote', header: 'Número do Lote'},
        {field: 'etiquetaRegistro', header: 'Etiqueta de Registro'},
        {field: 'tipoPostal', header: 'Tipo Postal'},
        {field: 'servicoContratado', header: 'Serviço Contratado'},
        {field: 'categoriaServico', header: 'Categoria do Serviço'},
        {field: 'siglaEventoSRO', header: 'Sigla do Evento no SRO'},
        {field: 'codigoEvento', header: 'Código do Evento'},
        {field: 'dataPostagem', header: 'Data de Postagem'},
        {field: 'horaPostagem', header: 'Hora da Postagem'},
        {field: 'eventoSRO', header: 'Evento no SRO'},
        {field: 'unidadeCorreio', header: 'Unidade dos Correios'},
        {field: 'cepCorreio', header: 'CEP da Unidade dos Correios'},
        {field: 'cidadeCorreio', header: 'Cidade da Unidade dos Correios'},
        {field: 'ufCorreio', header: 'UF da Unidade dos Correios'},
        {field: 'dataEstimativapriEntrega', header: 'Data estimada da primeira tentativa de entrega'},
    ];


    colsRastreamentoAntecipadoDataFinal1: any[] = [
        {field: 'numeroLote', header: 'Número do Lote'},
        {field: 'etiquetaRegistro', header: 'Etiqueta de Registro'},
        {field: 'tipoPostal', header: 'Tipo Postal'},
        {field: 'servicoContratado', header: 'Serviço Contratado'},
        {field: 'categoriaServico', header: 'Categoria do Serviço'},
        {field: 'siglaEventoSRO', header: 'Sigla do Evento SRO'},
        {field: 'codigoEvento', header: 'Código do Evento'},
        {field: 'dataPostagem', header: 'Data da Entrega'},
        {field: 'horaPostagem', header: 'Hora da Entrega'},
        {field: 'eventoSRO', header: 'Evento no SRO'},
        {field: 'unidadeCorreio', header: 'Unidade dos Correios'},
        {field: 'cepCorreio', header: 'CEP da Unidade dos Correios'},
        {field: 'cidadeCorreio', header: 'Cidade da Unidade dos Correios'},
        {field: 'ufCorreio', header: 'UF da Unidade dos Correios'},
        {field: 'dataEstimativapriEntrega', header: 'Data estimada da postagem'},
    ];

    colsArquivoCancelamento: any[] = [
        {field: 'codigoObjetoCliente', header: 'Código do Objeto Cliente'},
        {field: 'numeroLote', header: 'Número do Lote'},
    ];

    colsArquivoCancelamentoResposta: any[] = [
        {field: 'codigoObjetoCliente', header: 'Código do Objeto Cliente'},
        {field: 'numeroLote', header: 'Número do Lote'},
        {field: 'codigoCancelamento', header: 'Código do Cancelamento'},
        {field: 'mensagem', header: 'Mensagem'},
    ];

    colsDadosConsulta: any[] = [
        {field: 'mesAno', header: 'Mês/Ano'},
        {field: 'numeroLote', header: 'Nº Lote'},
        {field: 'dataTransmissão', header: 'Data da Transmissão'},
        {field: 'dataProcessamento', header: 'Data do Processamento'},
        {field: 'tipoArquivo', header: 'Tipo do Arquivo'},
        {field: 'situacao', header: 'Situação'},
        {field: 'nomeArquivo', header: 'Nome do Arquivo'},
    ];

    display: boolean = false;
    displayDetalhada: boolean = false;
    displayNotificacao: boolean = false;
    displayInconsistencia: boolean = false;
    displayRespostaNotificacao: boolean = false;
    displayRastreamentoData: boolean = false;
    displayRastreamentoFinal: boolean = false;
    displayCancelamento: boolean = false;
    displayRespostaCancelamento: boolean = false;

    idRetorno: any;
    retorno: RetornoCorreio[] = [];
    retornoGrid: RetornoCorreio = null;
    retornoDadosArquivo: RetornoCorreioDadosArquivo[] = [];
    retornoDadosOcorrencia: RetornoCorreioDadosOcorrencia[] = [];
    retornoNotificacao: RetornoCorreioNotificacao[] = [];
    retornoInconsistencia: RetornoCorreioInconsistencia[] = [];
    retornoRespostaNotificacao: RetornoCorreioRespostaNotificacao[] = [];
    retornoRastreamentoData: RetornoCorreioRastreamentoData[] = [];
    retornoRastreamentoFinal: RetornoCorreioRastreamentoFinal[] = [];
    retornoCancelamento: RetornoCorreioCancelamento[] = [];
    retornoCancelamentoResposta: RetornoCorreioCancelamentoResposta[] = [];
    private mostraExcel: boolean;
    private tipoRelatorio: number;

    constructor(
        private formBuilder: FormBuilder,
        protected messageService: MessageService,
        private arquivoService: ArquivoService,
        private ocorrenciaGestaoWebservicesService: OcorrenciaGestaoWebservicesService
    ) {
        super(messageService);
    }

    public show(retorno): void {
        this.retorno = retorno;
        this.display = true;
        this.consultarDadosArquivo(retorno);

        if (retorno.numeroTipoArquivo == "1") {
            this.displayDetalhada = this.display;
            this.consultaDadosOcorrencia(retorno);
        }
        if (retorno.numeroTipoArquivo == "2") {
            this.displayDetalhada = this.display;
            this.consultarNotificacao(retorno);
        }
        if (retorno.numeroTipoArquivo == "3") {
            this.displayDetalhada = this.display;
            this.consultarCancelamento(retorno);
        }
        if (retorno.numeroTipoArquivo == "4") {
            this.displayDetalhada = this.display;
            this.consultarRespostaNotificacao(retorno);
        }
        if (retorno.numeroTipoArquivo == "5") {
            this.displayDetalhada = this.display;
            this.consultarInconsistencia(retorno);
        }
        if (retorno.numeroTipoArquivo == "6") {
            this.displayDetalhada = this.display;
            this.consultarCancelamentoResposta(retorno);
        }

        if (retorno.numeroTipoArquivo == "7") {
            this.displayDetalhada = this.display;
            this.consultarRastreamentoData(retorno);
        }

        if (retorno.numeroTipoArquivo == "8") {
            this.displayDetalhada = this.display;
            this.consultarRastreamentoFinal(retorno);
        }


    }

    private consultarCancelamentoResposta(retorno: any): void {
        const filtro: FiltroPaginado = this.getFiltroPaginado(retorno);
        this.rowsPerPage = filtro.pageSize;
        this.searchPageble(this.ocorrenciaGestaoWebservicesService.consultarCancelamentoResposta(filtro)).subscribe(
            (resp: RetornoCorreioCancelamentoResposta[]) => {
                this.retornoCancelamentoResposta = resp;
            },
            error => {
                this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar.');
            });
    }

    private consultarCancelamento(retorno: any): void {
        const filtro: FiltroPaginado = this.getFiltroPaginado(retorno);
        this.rowsPerPage = filtro.pageSize;
        this.searchPageble(this.ocorrenciaGestaoWebservicesService.consultarCancelamento(filtro)).subscribe(
            (resp: RetornoCorreioCancelamento[]) => {
                if (resp && resp.length > 0) {
                    this.retornoCancelamento = [];
                    this.retornoCancelamento.push(resp[0]);
                }
            },
            error => {
                this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar.');
            });
    }

    private consultarRastreamentoFinal(retorno: any): void {
        const filtro: FiltroPaginado = this.getFiltroPaginado(retorno);
        this.rowsPerPage = filtro.pageSize;
        this.searchPageble(this.ocorrenciaGestaoWebservicesService.consultarRastreamentoFinal(filtro)).subscribe(
            (resp: RetornoCorreioRastreamentoFinal[]) => {
                if (resp && resp.length > 0) {
                    this.retornoRastreamentoFinal = resp;
                }
            },
            error => {
                this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar.');
            });
    }

    private consultarRastreamentoData(retorno: any): void {
        const filtro: FiltroPaginado = this.getFiltroPaginado(retorno);
        this.rowsPerPage = filtro.pageSize;
        this.searchPageble(this.ocorrenciaGestaoWebservicesService.consultarRastreamentoData(filtro)).subscribe(
            (resp: RetornoCorreioRastreamentoData[]) => {
                if (resp && resp.length > 0) {
                    this.retornoRastreamentoData = resp;
                }
            },
            error => {
                this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar.');
            });
    }

    private consultarRespostaNotificacao(retorno: any): void {
        const filtro: FiltroPaginado = this.getFiltroPaginado(retorno);
        this.rowsPerPage = filtro.pageSize;
        this.searchPageble(this.ocorrenciaGestaoWebservicesService.consultarRespostaNotificacao(filtro)).subscribe(
            (resp: RetornoCorreioRespostaNotificacao[]) => {
                this.retornoRespostaNotificacao = resp;
            },
            error => {
                this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar.');
            });
    }

    private consultarInconsistencia(retorno: any): void {
        const filtro: FiltroPaginado = this.getFiltroPaginado(retorno);
        this.rowsPerPage = filtro.pageSize;
        this.searchPageble(this.ocorrenciaGestaoWebservicesService.consultarInconsistencia(filtro)).subscribe(
            (resp: RetornoCorreioInconsistencia[]) => {
                this.retornoInconsistencia = resp;
            },
            error => {
                this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar.');
            });
    }

    private consultarNotificacao(retorno: any): void {
        const filtro: FiltroPaginado = this.getFiltroPaginado(retorno);
        this.rowsPerPage = filtro.pageSize;
        this.populateTablePaginatorFilter(filtro);
        this.searchPageble(this.ocorrenciaGestaoWebservicesService.consultarNotificacao(filtro)).subscribe(
            (resp: RetornoCorreioNotificacao[]) => {
                if (resp && resp.length > 0) {
                    this.retornoNotificacao = [];
                    this.retornoNotificacao.push(resp[0]);
                }
            },
            error => {
                this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar.');
            });
    }

    private consultarDadosArquivo(retorno: any): void {
        const filtro: FiltroPaginado = this.getFiltroPaginado(retorno);
        this.rowsPerPage = filtro.pageSize;
        this.searchPageble(this.ocorrenciaGestaoWebservicesService.consultarDadosArquivo(filtro)).subscribe(
            (retorno: RetornoCorreioDadosArquivo[]) => {
                this.retornoDadosArquivo = retorno;
            },
            error => {
                this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar.');
            });
    }

    private consultaDadosOcorrencia(retorno: any) {

        const filtro: FiltroPaginado = this.getFiltroPaginado(retorno);
        this.rowsPerPage = filtro.pageSize;
        this.searchPageble(this.ocorrenciaGestaoWebservicesService.consultarDadosOcorrencia(filtro)).subscribe(
            (retorno: RetornoCorreioDadosOcorrencia[]) => {
                this.retornoDadosOcorrencia = retorno;
            },
            error => {
                this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar.');
            });
    }

    consultarPaginado(): void {
    }

    gerarRelatorio(tipo: TipoRelatorio): void {
        const filtro: FiltroPaginado = this.getFiltroPaginado(this.retorno);
        filtro.filtro.tipoRelatorio = this.tipoRelatorio;
        this.ocorrenciaGestaoWebservicesService.gerarRelatorio(tipo, filtro)
            .subscribe(
                response => {
                    WebUtil.downLoadFile(response);
                },
                err => {
                    this.messageService.addMsgDanger(err.error);
                }
            );
    }

    verificaBotaoExcel(event) {
        let tab = event.index
        if (tab == 0) {
            this.mostraExcel = false;
        } else {
            this.mostraExcel = true;
        }
        this.tipoRelatorio = tab;
    }

    downloadArquivo(codGed: string) {
        CrudUtils.downloadArquivo(this.arquivoService, codGed);
    }
}
