import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {EquipeOcorrencia} from 'app/shared/models/ocorrencia/equipe-ocorrencia';
import {FiltroPaginado} from "../../models/filtroPaginado";
import {Observable} from 'rxjs';
import {ContestacaoVerificaAtendimento} from "../../models/ocorrencia/contestacao-verifica-atendimento";

@Injectable()
export class EquipeOcorrenciaService extends CrudHttpClientService<EquipeOcorrencia> {

	constructor(protected http: HttpClient) {
		super('ocorrencia/equipe-ocorrencia', http);
	}

	consultarOcorrenciasMaiorTempo(filtro: FiltroPaginado) {
		return this.http.post(this.url + '/ocorrencias-maior-tempo', filtro, this.options());
	}

	consultarUsuariosTratamento(idOrigemEquipe: number): Observable<any>{
		return this.http.get(this.url + '/consultar-usuarios-tratamento/' + idOrigemEquipe, this.options());
	}

	getPrazoAtendimento(idEquipe: number): Observable<any>{
		return this.http.get(this.url + '/buscar-equipe-etapa-grupo/' + idEquipe, this.options());
	}

	getAtuacaoAtendimento(idEquipe: number): Observable<any>{
		return this.http.get(this.url + '/buscar-atuacao-grupo/' + idEquipe, this.options());
	}

	consultarEquipePorGestor() {
		return this.http.post(this.url + '/buscar-equipe-por-gestor', null, this.options());
	}

	iniciarTratamento(idOcorrencia:number): Observable<any> {
		return this.http.get(this.url + '/iniciar-tratamento/' +idOcorrencia, this.options());
	}

	finalizarTratamento(idOcorrencia:number, idSituacao:number): Observable<any> {
		return this.http.get(this.url + '/finalizar-tratamento/' +idOcorrencia+'/'+idSituacao, this.options());
	}

	finalizarTratamentoIncompleto(idOcorrencia:number, idSituacao:number): Observable<any> {
		return this.http.get(this.url + '/finalizar-tratamento-incompleto/' +idOcorrencia+'/'+idSituacao, this.options());
	}

	iniciarTratamentoContestacao(idSolicitacaoPenalidade:number, matricula:string, situacao:string): Observable<ContestacaoVerificaAtendimento> {
		return this.http.get<ContestacaoVerificaAtendimento>(this.url + '/iniciar-tratamento-contestacao/' +idSolicitacaoPenalidade+"/"+matricula+'/'+situacao, this.options());
	}
	finalizarTratamentoContestacao(idSolicitacaoPenalidade:number): Observable<ContestacaoVerificaAtendimento> {
		return this.http.get<ContestacaoVerificaAtendimento>(this.url + '/finalizar-tratamento-contestacao/' +idSolicitacaoPenalidade, this.options());
	}
}
