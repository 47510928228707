import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'tipoParametroConsole' })
export class TipoParametroConsolePipe implements PipeTransform {
	transform(value: string) {

		if (value === 'T') {
			return 'Telefone';
		}
		if (value === 'D') {
			return 'Demais Origens';
		}
		if (value === 'B') {
			return 'BACEN';
		}
		return value;
	}
}