import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {SelectItemModel} from 'app/shared/models/select-item';
import {MotivoAvaliacao} from 'app/shared/models/ocorrencia/motivo-avaliacao';

@Injectable()
export class MotivoAvaliacaoService extends CrudHttpClientService<MotivoAvaliacao> {

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super('ocorrencia-externa/tarefa-sac/motivo-avaliacao', http);
    }

    public consultarSelectItensTipo(tipo: string): Observable<any> {
        return this.http.post<SelectItemModel>(this.url + '/select-itens', tipo, this.options());
    }

    public searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.set('value', value);
        httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
        return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
    }

    public consultarSelectItensPerfil(idTipoOcorrencia: number): Observable<any> {
        return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis', idTipoOcorrencia, this.options());
    }

    public alterarSituacao(motivo: MotivoAvaliacao): Observable<any> {
        return this.http.put(this.url + '/ativar-inativar', motivo, this.options());
    }

    public possuiVinculo(id: number): any {
        return this.http.get<boolean>(this.url + '/possui-vinculo/' + id, this.options());
    }
}
