import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {EquipeUsuario} from 'app/arquitetura/shared/models/cadastrobasico/equipe-usuario';
import {PainelEquipeOrigemService} from './painel-equipe-origem.service';
import {Origem} from 'app/shared/models/administracao/parametrizacao/origem';
import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';

@Injectable({ providedIn: 'root' })
export class EquipeConsoleOcorrenciaService {

  private equipeOuvSubject$ = new Subject<EquipeUsuario>();
  equipeOuvChanged$ = this.equipeOuvSubject$.asObservable();

  constructor(
    private equipePainelService: PainelEquipeOrigemService,
    private sessaoService: SessaoService
  ) { }

  emiteEquipeUsuarioOuvChanged(equipe: EquipeUsuario) {
    this.equipePainelService.consultarOrigemPorEquipe(equipe.idEquipe).subscribe((origem: Origem) => {
      equipe.origem = origem;
      this.sessaoService.setEquipeUsuario(equipe);
    }, error => {
      this.sessaoService.setEquipeUsuario(equipe);
      console.error('Não foi possível obter a origem para a equipe', equipe.nomeEquipe, error);
    }, () => {
      this.equipeOuvSubject$.next(equipe);
    })
  }

}
