import {BackgroundModuloUtils} from './shared/util/background-modulo-utils';
// /-------------------------------------------------\
// | artefatos da biblioteca angular                 |
// \-------------------------------------------------/
import {Component, ViewChild, ViewEncapsulation} from '@angular/core';

import {Router} from '@angular/router';
import {Usuario} from './arquitetura/shared/models/cadastrobasico/usuario';
import {CabecalhoService} from './arquitetura/shared/services/cabecalho.service';
import {UsuarioService} from './arquitetura/shared/services/cadastrobasico/usuario.service';
import {KeycloakService} from './arquitetura/shared/services/seguranca/keycloak.service';
import {projet} from './shared/const/project-version';
import {MenuLateralComponent} from './arquitetura/shared/templates/menu-lateral/menu-lateral.component';
import {SessaoService} from './arquitetura/shared/services/seguranca/sessao.service';
import {LoaderService} from "./arquitetura/shared/services/loader.service";

// /--------------------------------------------------\
// | app.component.ts                                 |
// |--------------------------------------------------|
// | Componente root da aplicação                     |
// \--------------------------------------------------/

const closedClass = "menusidebar is-closed";
const openedClass = "menusidebar is-open";
const toggledClass = "toggled";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AppComponent {

	public show: boolean;
	public currentClass: string;
	public wrapperClass: string;
  	versao = projet.version;

	@ViewChild("menuLateral")
	menuLateral: MenuLateralComponent;

	constructor(
		private sessaoService: SessaoService,
		private cabelhoService: CabecalhoService,
    	protected router: Router,
    	private backgroundModuloUtils: BackgroundModuloUtils,
		private loaderService: LoaderService
    ) {
		this.sessaoService.inicializarSessao();
    	this.backgroundModuloUtils = new BackgroundModuloUtils(this.router);
	}

	ngOnInit() {
		this.show = false;
		this.currentClass = closedClass;
		this.atualizarDados();
	}

	atualizarDados() {
		// atualizo os dados da Sessão quanto ocorre o F5
		if (this.sessaoService.getPerfilUsuarioSelecionado() !== null) {

			let perfilSelecionado = this.sessaoService.getPerfilUsuarioSelecionado().perfil;
			UsuarioService.consultarPorLogin(KeycloakService.getUsername(), perfilSelecionado)
			.then((usuario: Usuario) => {
				SessaoService.usuario = usuario;

				// Atualiza Sessão
				this.sessaoService.atualizarPerfilSessao(perfilSelecionado);

				// Atualizo Menu
				this.menuLateral.load();

				// Atualiza Cabecalho
				this.cabelhoService.emiteReloadCabecalho();

			});
		}
	}

	toggleMenu() {
		if (this.show) {
			this.show = false;
			this.currentClass = closedClass;
			this.wrapperClass = "";
		} else {
			this.show = true;
			this.currentClass = openedClass;
			this.wrapperClass = toggledClass;
		}
	}

	emitModalPerfis() {
		this.menuLateral.load();
	}

	public get classeBorda(): string {

		if (this.backgroundModuloUtils.isOuvidoria()) {
			return "col-md-12 margin-top-50 id_borda_ouvidoria";
		} else if (this.backgroundModuloUtils.isSAC()) {
			return "col-md-12 margin-top-50 id_borda_sac";
		} else if (this.backgroundModuloUtils.isVivaVoz()) {
			return "col-md-12 margin-top-50 id_borda_vivavoz";
		}

		return "col-md-12 margin-top-50";
	}
}
