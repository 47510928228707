import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Entity} from '../../../arquitetura/shared/models/entity';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators'
import {CorrespondenteUnidade} from 'app/shared/models/comum/correspondente-unidade.model';

@Injectable()
export class CorrespondenteService extends CrudHttpClientService<Entity> {

  constructor(protected http: HttpClient) {
    super('comum/correspondente', http);
  }

  public consultarCorrespondentesPorUnidade(codigoUnidade: number): any {
    return this.http.get(`${this.url}/unidade/${codigoUnidade}`);
  }

  public consultarCorrespondente(codigoUnidade: number, codigoCorrespondente: number): any {
    return this.http.get(`${this.url}/unidade/${codigoUnidade}/correspondente/${codigoCorrespondente}`)
      .pipe(this.mapearCorrespondenteUnidade());
  }

  public consultarCorrespondentePorFiltro(parametros: any): any {
    return this.http.post<any>(`${this.url}/`, parametros, this.options());
  }

  private mapearCorrespondenteUnidade() {
    return map((resp) => <CorrespondenteUnidade>{
      numeroConvenio: resp['codigo-correspondente'],
      numeroDvConvenio: resp['digito-verificador'],
      numeroCnpj: resp['cnpj'],
      nomeEmpresa: resp['empresa'],
      nomeFantasia: resp['nome-fantasia'],
      nomeEmail: resp['email'],
      tipoConvenio: resp['tipo-situacao-convenio'],
    });
  }
}
