import {
    ChangeDetectorRef,
    ComponentFactoryResolver,
    Directive,
    Input,
    OnDestroy,
    OnInit,
    ViewContainerRef
} from "@angular/core";
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    ChildrenOutletContexts,
    OutletContext,
    Route,
    RouterOutlet
} from "@angular/router";
import {CabecalhoPadraoComponent} from "app/arquitetura/shared/templates/cabecalho-padrao/cabecalho-padrao.component";
import {RodapePadraoComponent} from "app/arquitetura/shared/templates/rodape-padrao/rodape-padrao.component";

/**
 * Diretiva que permite implementar um outlet da mesma forma que a tag 'router-outlet' o faz.
 * Por exemplo, ao invés de escrever no template do componente:
 *   <router-outlet name="header"></router-outlet>
 * Pode ser definido como:
 *   <named-outlet name="header"></named-outlet>
 *
 * Nesse caso, ao utilizar esta diretiva ao invés do outlet padrão, não será mais necessário
 * definir a rota filha de cabeçalho e rodapé padrão em cada componente. Portanto, o
 * roteamento:
 * 	 path: '',
 *   component: ExemploComponent,
 *   children: [
 *	   { path: '', outlet: 'header', component: CabecalhoPadraoComponent },
 *	   { path: '', outlet: 'footer', component: RodapePadraoComponent }
 *   ]
 * Poderá simplesmente ser definido como:
 * 	 path: '',
 *   component: ExemploComponent
 *
 * Uso no template da página:
 *   <named-outlet name="[nome-outlet]"></named-outlet>
 */
@Directive({
	selector: 'named-outlet',
	exportAs: 'outlet'
})
export class NamedOutletDirective implements OnInit, OnDestroy {
	@Input() public name: string;
	public outlet: RouterOutlet;

	constructor(
		private parentContexts: ChildrenOutletContexts,
		private location: ViewContainerRef,
		private resolver: ComponentFactoryResolver,
		private changeDetector: ChangeDetectorRef
	) { }

	ngOnInit() {
		// Cria um outlet
		this.outlet = new RouterOutlet(this.parentContexts, this.location, this.resolver,
			this.name, this.changeDetector);

		// Se o outlet não está ativo (definido no roteamento), mas possui um dos
		// nomes previstos de terem um roteamento padrão ...
		if ((!this.outlet.isActivated) &&
			  ((this.name == 'header') || (this.name == 'footer'))) {
			// Cria uma rota com os valores padrões iniciais
			let route: Route = {};
			route.path = '';
			route.outlet = this.name;

			// Preenche o componente esperado na rota
			switch (this.name) {
				case 'header': { route.component = CabecalhoPadraoComponent; break; }
				case 'footer': { route.component = RodapePadraoComponent; break; }
			}

			// Cria um contexto para o outlet
			let context: OutletContext = this.parentContexts.getOrCreateContext(this.name);
			// Cria uma rota ativa para o contexto
			context.route = new ActivatedRoute();
			// Cria uma definição para a rota ativa
			(context.route as any)._futureSnapshot = new ActivatedRouteSnapshot();
			// Preenche a rota ativa com a rota criada anteriormente
			(context.route as any)._futureSnapshot.routeConfig = route;

			//console.log('Definido o outlet padrão do \'' + this.name + '\'.');
		}

		// Executa a inicialização padrão do outlet
		this.outlet.ngOnInit();
	}

	ngOnDestroy() {
		if (this.outlet) {
			// Executa a finalização padrão do outlet
			this.outlet.ngOnDestroy();
		}
	}
}
