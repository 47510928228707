import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Tarefa} from 'app/shared/models/ocorrencia/tarefa';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {TipoRelatorio} from '../../../arquitetura/shared/reports/tipo-relatorio';
import {RelatorioComFiltro} from '../../models/relatorio-com-filtro';
import {RespostaTarefa} from '../../models/administracao/parametrizacao/resposta-tarefa';
import {TarefaAvaliacao} from '../../models/ocorrencia/tarefa-avaliacao';

@Injectable()
export class TarefaSacService extends CrudHttpClientService<Tarefa> {

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super('ocorrencia/tarefa-sac', http);
    }

    public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
    }

    public detalhar(id: number): Observable<any> {
        return this.http.get(this.url + '/' + id + '/detalhar', this.options());
    }

    public salvar(tarefa: Tarefa) {
        return this.http.post(this.url, tarefa, this.options());
    }

    public responder(id: number, resposta: RespostaTarefa) {
        return this.http.post(this.url + '/' + id + '/responder', resposta, this.options());
    }

    public avaliar(id: number, avaliacao: TarefaAvaliacao) {
        return this.http.post(this.url + '/' + id + '/avaliar', avaliacao, this.options());
    }

    public definirResponsavel(id: number) {
        return this.http.post(this.url + '/' + id + '/definirResponsavel', this.options());
    }

    public gerarRelatorio(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
        const relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
        relatorioComFiltro.tipo = TipoRelatorio[tipo];
        relatorioComFiltro.filtro = filtro;
        return this.http.post(this.url + '/gerar-relatorio', relatorioComFiltro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
    }

}
