import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';

import {HttpClientService} from 'app/arquitetura/shared/services/http-client.service';

@Injectable()
export class SistemaService extends HttpClientService {
	constructor(protected http: HttpClient) {
		super('sobre/sistema', http);
	}

	/**
	 * Recupera informações do sistema
	 */
	public info(): Observable<string> {
		return this.http.get<string>(this.url + '/info',
			this.options({ responseType: 'text' }));
	}
}
