import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {
    TipoAtendimentoZeroOitocentos
} from 'app/shared/models/administracao/parametrizacao/tipo-atendimento-zero-oitocentos';
import {SelectItemModel} from 'app/shared/models/select-item';
import { ParametrizacaoAtendimentoZeroOitocentosModel } from '../models/parametrizacao-atendimento-zero-oitocentos.model';

@Injectable()
export class TipoAtendimentoZeroOitocentosService extends CrudHttpClientService<ParametrizacaoAtendimentoZeroOitocentosModel> {
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/parametrizacao/tipo-atendimento-zero-oitocentos', http);
  }

  public searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public ativarInativar(tipoAtendimento: TipoAtendimentoZeroOitocentos): Observable<TipoAtendimentoZeroOitocentos> {
    return this.http.put<TipoAtendimentoZeroOitocentos>(this.url + "/ativar-inativar", tipoAtendimento, this.options());
  }

  public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
		return this.http.get<SelectItemModel>(this.url + '/select-itens-perfis/' + idTipoOcorrencia, this.options());
	}
}
