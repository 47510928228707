import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Situacao} from 'app/shared/models/comum/situacao';
import {SelectItemModel} from '../../models/select-item';
import {Entity} from '../../../arquitetura/shared/models/entity';
import {Injectable} from '@angular/core';
import {SimNao} from 'app/shared/models/comum/sim-nao';

@Injectable()
export class ComumService extends CrudHttpClientService<Entity> {
  constructor(protected http: HttpClient) {
    super('comum/comum', http);
  }

  public listSelectItemSituacao(): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(this.url + '/combo/situacoes', this.options());
  }

  public listSelectItemSituacaoInclusao(): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(this.url + '/combo/situacoes-inclusao', this.options());
  }

  public listSelectItemCorrespondentes(): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(this.url + '/combo/correspondentes', this.options());
  }

  public listarSituacoes(): Observable<Situacao[]> {
    return this.http.get<Situacao[]>(this.url + '/list/situacoes', this.options());
  }

  public listarSituacoesSemOpcaoTodos(): Observable<Situacao[]> {
    return this.http.get<Situacao[]>(this.url + '/list/situacoes-inclusao', this.options());
  }

  public listarSituacoesSemOpcaoTodosEPendente(): Observable<Situacao[]> {
    return this.http.get<Situacao[]>(this.url + '/list/situacoes-sem-todos-e-pendente', this.options());
  }

  public listarSimNao(): Observable<SimNao[]> {
    return this.http.get<SimNao[]>(this.url + '/list/sim-nao', this.options());
  }

  public listSelectItemSituacaoSolicitacaoOcorrenciaInterna(): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(this.url + '/combo/situacoes-solicitaca-ocorrencia-interna', this.options());
  }
}
