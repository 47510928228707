import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {PerguntaAvaliacao} from 'app/shared/models/administracao/parametrizacao/pergunta-avaliacao';
import {SelectItemModel} from 'app/shared/models/select-item';
import {Observable} from 'rxjs';

@Injectable()
export class PerguntaAvaliacaoService extends CrudHttpClientService<PerguntaAvaliacao> {
  constructor(protected http: HttpClient) {
    super('administracao/parametrizacao/pergunta-avaliacao', http);
  }

  public consultarPerguntas(): Observable<any> {
		return this.http.get<PerguntaAvaliacao>(this.url + '/perguntas/', this.options());
  }

  public consultarPerguntasPorNatureza(idNatureza: number): Observable<any> {
		return this.http.get<PerguntaAvaliacao>(this.url + '/perguntas/natureza/' + idNatureza, this.options());
  }
  
  public consultarSelectPerguntas(): Observable<any> {
		return this.http.post<SelectItemModel>(this.url + '/select-perguntas', this.options());
  }

  public searchAutocomplete(value: string): Observable<any> {
    return this.http.post(this.url + '/search-autocomplete/', value, this.options());
  }

  public ativarInativar(perguntaAvaliacao: PerguntaAvaliacao): Observable<PerguntaAvaliacao> {
    return this.http.put<PerguntaAvaliacao>(this.url + "/ativar-inativar", perguntaAvaliacao, this.options());
  }

  public consultarPerguntasPorNaturezaAtiva(idNatureza: number): Observable<any> {
		return this.http.get<PerguntaAvaliacao>(this.url + '/perguntas/natureza/ativas/' + idNatureza, this.options());
  }

  public isPerguntaUtilizada(idPergunta: number): Observable<any> {
		return this.http.get<boolean>(this.url + '/perguntaUtilizada/' + idPergunta, this.options());
  }
}
