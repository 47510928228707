import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MovimentacaoOcorrenciaInterna} from 'app/shared/models/tratar-ocorrencia/movimentacao-ocorrencia-interna';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {Observable} from 'rxjs';
import {Pageable} from 'app/shared/models/pageable';

@Injectable()
export class MovimentacaoOcorrenciaInternaService extends CrudHttpClientService<MovimentacaoOcorrenciaInterna> {
  constructor(protected http: HttpClient) {
		super('tratar-ocorrencia/movimentacao-ocorrencia-interna', http);
	}

	public consultarPaginado(filtro: FiltroPaginado): Observable<Pageable> {
		return this.http.post<Pageable>(this.url + '/consultar-paginado', filtro, this.options());
	}
}
