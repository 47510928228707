import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {OcorrenciaExterna} from 'app/shared/models/ocorrencia/ocorrencia-externa';
import {FiltroPaginado} from "../../models/filtroPaginado";

@Injectable()
export class RelatorioService extends CrudHttpClientService<OcorrenciaExterna> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('relatorio', http);
  }

  public consultarRelatorioAnalitivoAvaliacoes(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/analitico-avaliacoes', filtro, this.options());
  }

  gerarRelatorioSAC(filtro: FiltroPaginado) {
    return this.http.post( this.url + '/gerar-relatorio-analitico-ocorrencia', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

  public consultarSinteticoPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-sintetico-paginado', filtro, this.options());
  }

  gerarRelatorioAnaliticoTarefaSAC(filtro: FiltroPaginado) {
    return this.http.post( this.url + '/gerar-relatorio-analitico-tarefa', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

  gerarRelatorioAnaliticoAvaliacaoOcorrenciaSAC(filtro: FiltroPaginado) {
    return this.http.post( this.url + '/gerar-relatorio-analitico-avaliacao_ocorrencia', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

  public contultarDadosRelatorioAnaliticoOcorrenciasVivaVoz(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-analitico-ocorrencias-viva-voz', filtro, this.options());
  }

  public contultarDadosRelatorioAnaliticoGerencialVivaVoz(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-analitico-gerencial-viva-voz', filtro, this.options());
  }

  gerarRelatorioAnaliticoOcorrenciasVivaVoz(filtro: FiltroPaginado) {
    return this.http.post( this.url + '/gerar-relatorio-analitico-ocorrencias-viva-voz', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

  gerarRelatorioAnaliticoGerencialVivaVoz(filtro: FiltroPaginado) {
    return this.http.post( this.url + '/gerar-relatorio-analitico-gerencial-viva-voz', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

  gerarRelatorioAnaliticoPenalidade(filtro: FiltroPaginado) {
    return this.http.post( this.url + '/gerar-relatorio-analitico-penalidade', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

}
