import { PesquisaPerguntaDTO } from './../../models/ocorrencia/pesquisa-pergunta';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {OcorrenciaExterna} from 'app/shared/models/ocorrencia/ocorrencia-externa';
import {FiltroPaginado} from "../../models/filtroPaginado";

@Injectable()
export class OcorrenciaExternaService extends CrudHttpClientService<OcorrenciaExterna> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/ocorrencia-externa', http);
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public consultarPaginadoRelatorioTratamentoUnidade(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado/tratamento-unidade', filtro, this.options());
  }

  public contultarDadosRelatorioAnaliticoDeTarefas(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-analitico-tarefas', filtro, this.options());
  }

  public consultarOcorrenciasSolicitanteDataInicio(cpf: number, dataInicioOcorrenciaExterna?: string): Observable<any> {
    return this.http.get(`${this.url}/historico/${cpf}/${dataInicioOcorrenciaExterna}`);
  }

  public consultarOcorrenciasSolicitante(cpf: number): Observable<any> {
    return this.http.get(`${this.url}/historico/${cpf}`);
  }

  consultarQuantidadeOcorrenciaAgrupadoPorDataEhFiltradoPorMatricula(matricula: number) {
    return this.http.get(`${this.url}/historico/grupo-tratamento/${matricula}`, this.options());
  }

  consultarPorNumeroOcorrencia(numeroOcorrencia: number) {
    return this.http.get(`${this.url}/numero-ocorrencia/${numeroOcorrencia}`);
  }

  consultarSituacaoPorNumeroOcorrencia(numeroOcorrencia: number): Observable<any> {
    return this.http.get<number>(`${this.url}/numero-ocorrencia/situacao/${numeroOcorrencia}`);
  }

  consultarPorNumeroOcorrenciaLegado(numeroOcorrencia: number, nuCnpj: number) {
    return this.http.get(`${this.url}/numero-ocorrencia-legado/${numeroOcorrencia}/${nuCnpj}`);
  }

  gerarRelatorioSAC(filtro: FiltroPaginado) {
    return this.http.post( this.url + '/gerar-relatorio-analitico-ocorrencia', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

  gerarRelatorioOcorrenciaTratamentoUnidade(filtro: FiltroPaginado) {
    return this.http.post( this.url + '/gerar-relatorio-ocorrencia-unidade', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

  public consultarSinteticoPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-sintetico-paginado', filtro, this.options());
  }

  gerarRelatorioSintetico(filtro: FiltroPaginado) {
    return this.http.post( this.url + '/gerar-relatorio-sintetico-ocorrencia', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

  consultarPerguntasAutocomplete(filtro: PesquisaPerguntaDTO): Observable<any> {
    return this.http.post(this.url + '/buscar-perguntas', filtro, this.options());
  }
}
