import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Item} from 'app/shared/models/administracao/parametrizacao/item';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {SelectItemModel} from 'app/shared/models/select-item';
import {Problema} from 'app/shared/models/administracao/parametrizacao/problema';

@Injectable()
export class ProblemaService extends CrudHttpClientService<Problema> {
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/parametrizacao/problema', http);
  }

  public consultarTodos(): Observable<any> {
    return this.http.get<any>(this.url + '/todos', this.options());
  }

  consultar(item: Problema, next?: (itens: Problema[]) => void, errorCallback?: any): any {
    this.callPOST<Item[]>('/consultar', item, next, error => {
      const message = errorCallback(error, 'Ocorreu um erro ao pesquisar itens.');
      this.messageService.addMsgDanger(message);
      console.log('Erro ao chamar o servico. Erro: ' + error);
    });
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

  public consultarId(next?: (itens: Problema[]) => void, errorCallback?: any): any {
    this.callPOST<Problema[]>('/consultarIdT', 0, next, error => {
      const message = errorCallback(error, 'Ocorreu um erro ao pesquisar itens.');
      this.messageService.addMsgDanger(message);
      console.log('Erro ao chamar o servico. Erro: ' + error);
    });
  }

  public ativarInativar(item: Problema): Observable<any> {
    return this.http.put<Problema>(this.url + "/ativar-inativar", item, this.options());
  }

  public todosAtivos(idTipoOcorrencia: number): any {
    return this.http.get<Problema[]>(this.url + '/ativos/' + idTipoOcorrencia, this.options());
  }

  public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis/', idTipoOcorrencia, this.options());
  }

  public consultarIdProblema_Produto(idOrigem: number, idEquipe: number): any {
    return this.http.get<any>(this.url + '/select-itens-perfis-equipe/'+ idOrigem+'/'+idEquipe, this.options());
  }

}
