import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'tipoItemTemplate' })
export class TipoItemTemplatePipe implements PipeTransform {
	transform(value: string) {

		if (value === 'A') {
			return 'Aberta';
		}
		if (value === 'U') {
			return 'Seleção Única';
		}
		if (value === 'M') {
			return 'Seleção Múltipla';
		}
		if (value === 'F') {
			return 'Arquivo';
		}
		return value;
	}
}