import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Entity} from 'app/arquitetura/shared/models/entity';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';


@Injectable()
export class TextoComplementarService extends CrudHttpClientService<Entity> {
  constructor(protected http: HttpClient) {
    super('administracao/parametrizacao/texto-complementar', http);
  }

  public consultarListInfDTO(icFuncionalidadeInf: String) {
    return this.http.get(this.url + '/listaInf-dto/' + icFuncionalidadeInf);
  }

}
