import {CommonModule} from "@angular/common";
import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule,} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DirectivesModule} from "app/arquitetura/shared/directives/directives.module";
import {PipeModule} from "app/shared/pipes/pipe.module";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {CalendarModule} from "primeng/calendar";
import {DialogModule} from "primeng/dialog";
import {
    BreadcrumbModule,
    DropdownModule,
    FieldsetModule,
    PaginatorModule,
    ScrollPanelModule,
    TabViewModule,
} from "primeng/primeng";
import {TableModule} from "primeng/table";
import {CnpjPipe} from "../pipes/cnpj.pipe";
// Componentes compartilhados para aplicação
import {DatalistComponent} from "./datalist/datalist.component";
import {InputDateLocalComponent} from "./input-date-local/input-date-local.component";
import {InputDateComponent} from "./input-date/input-date.component";
import {ManyCheckboxComponent} from "./many-checkbox/many-checkbox.component";
import {ManyRadioComponent} from "./many-radio/many-radio.component";
import {ModalHistoricoOcorrenciaComponent} from "./modal-historico-ocorrencia/modal-historico-ocorrencia.component";
import {ModalHistoricoSolicitacaoComponent} from "./modal-historico-solicitacao/modal-historico-solicitacao.component";
import {ModalPerfisComponent} from "./modal-perfis/modal-perfis.component";
import {ModalVisualizarOcorrenciaComponent} from "./modal-visualizar-ocorrencia/modal-visualizar-ocorrencia.component";
import {RequiredLabelComponent} from "./required/required-label/required-label.component";
import {ValidationField} from "./validation-field/validation-field.component";
import {ModalDetalharCorreioComponent} from "./modal-detalhar-correio/modal-detalhar-correio.component";
import { BarraFixaFooterComponent } from './barra-fixa-footer/barra-fixa-footer.component';

@NgModule({
  declarations: [
    DatalistComponent,
    InputDateComponent,
    RequiredLabelComponent,
    ManyCheckboxComponent,
    ManyRadioComponent,
    CnpjPipe,
    ValidationField,
    InputDateLocalComponent,
    ModalPerfisComponent,
    ModalVisualizarOcorrenciaComponent,
    ModalHistoricoOcorrenciaComponent,
    ModalHistoricoSolicitacaoComponent,
    ModalDetalharCorreioComponent,
    BarraFixaFooterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    BreadcrumbModule,
    FieldsetModule,
    PipeModule,
    TableModule,
    PaginatorModule,
    DropdownModule,
    DialogModule,
    DirectivesModule.forRoot(),
    CalendarModule,
    TabViewModule,
    ScrollPanelModule
  ],
  exports: [
    DatalistComponent,
    InputDateComponent,
    RequiredLabelComponent,
    ManyCheckboxComponent,
    ManyRadioComponent,
    CnpjPipe,
    ValidationField,
    InputDateLocalComponent,
    ModalPerfisComponent,
    ModalVisualizarOcorrenciaComponent,
    ModalHistoricoSolicitacaoComponent,
    ModalHistoricoOcorrenciaComponent,
    ModalDetalharCorreioComponent,
    BarraFixaFooterComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponenteModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ComponenteModule,
    };
  }
}
