import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Equipe} from 'app/shared/models/administracao/parametrizacao/equipe';
import {HttpClient} from '@angular/common/http';
import {MessageService} from 'app/shared/components/messages/message.service';
import {SelectItemModel} from 'app/shared/models/select-item';
import {Observable} from 'rxjs/Observable';
import {MembroEquipe} from 'app/shared/models/administracao/parametrizacao/membro-equipe';
import {Origem} from "../../../models/administracao/parametrizacao/origem";
import {GrupoTratamento} from "../../../models/administracao/parametrizacao/grupo-tratamento";

@Injectable()
export class EquipeService extends CrudHttpClientService<Equipe> {
    constructor(
        protected http: HttpClient,
        protected messageService: MessageService
    ) {
        super('administracao/parametrizacao/equipe', http);
    }

    consultarGestores(): Observable<any> {
        return this.http.get<SelectItemModel>(`${this.url}/gestores`);
    }

    consultarGestoresPorSupervisor(): Observable<any> {
        return this.http.get<SelectItemModel>(`${this.url}/gestores-por-supervisor`);
    }

    consultarMembrosEquipes(idGrupo: number): Observable<any> {
        return this.http.get<MembroEquipe>(`${this.url}/` + idGrupo + `/integrantes-equipe`);
    }

    consultarEquipes(nuMatricula: string, isPerfilSupervisor: boolean): Observable<any> {
        return this.http.get<Equipe>(`${this.url}/` + nuMatricula + `/` + isPerfilSupervisor + `/equipes`);
    }

    consultarOrigensCategorizacao(): Observable<Origem[]> {
        return this.http.post<Origem[]>(this.url + '/select-origens', null, this.options());
    }
    consultarGestoresTipoOcorrencia(tipoOcorrencia): Observable<any> {
        return this.http.get<SelectItemModel>(`${this.url}/gestores-por-tipo-ocorrencia/${tipoOcorrencia}`);
    }

    ativarInativar(grupoTratamento: GrupoTratamento) {
        return this.http.put(this.url + '/ativar-inativar', grupoTratamento, this.options());
    }
}
