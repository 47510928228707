import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'estiloFonte', pure: false })
export class TipoEstiloFontePipe implements PipeTransform {

  fontes = {
    'B': 'webserv-anexo-leg',
    'P': 'webserv-anexo-leg',
    'C': 'unidade-anexo-leg',
    'S': 'ouvidoria-anexo-leg',
    'O': 'ouvidoria-anexo-leg'
  };

  transform(value: string) {
    return (value) ? this.fontes[value.toString().toUpperCase()] : '';
  }

}
