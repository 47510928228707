import { PerfilModulo } from './../../../../shared/models/perfil-modulo';
import { PerfilUtils } from 'app/arquitetura/shared/util/perfil-utils';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';

import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';
import {UsuarioService} from 'app/arquitetura/shared/services/cadastrobasico/usuario.service';
import {MessageService} from 'app/shared/components/messages/message.service';

/**
 * Guard para verificar a flag ST_ATUALIZA_DADOS
 * se estiver com 'S', deve se abrir a tela para atualizar os dados do usuário
 * Também verifica o Status do usuário
 */
@Injectable()
export class DadosUsuarioGuard implements CanActivate, CanActivateChild {
	private verificandoUsuario = false;

	constructor(
		public usuarioService: UsuarioService,
		public router: Router,
		public messageService: MessageService,
		private sessaoService: SessaoService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const url: string = state.url;
		const rotaAtualizacao = '/usuario/atualizar';
		this.verificandoUsuario = true;

		// Busca os dados do usuário para realizar as verificações
		/*this.usuarioService.getUsuarioToken().subscribe(
			(item: any) => {
				const usuario: any = item.data;

				// Verifica Situação do Usuário / Inativo / Bloqueado / Aguardando liberação
				if (usuario.co_situacao_usuario !== '1') {
					this.messageService.addConfirmOk(
						'Os dados do usuário informado não estão ativos, aguarde a liberação do usuário.',
						(): ConfirmListener => {
							this.authService.deleteLocalData();
							Keycloak.logout({});
							this.router.navigate(['/']);
							return;
						}, null, 'Informativo');
					return;
				}

				// Verifica a Flag para Atualização de Dados
				if (usuario.st_atualiza_dado === 'S' && url !== rotaAtualizacao) {
					this.verificandoUsuario = false;
					this.router.navigate(['/usuario/atualizar']);
					return;
				}
				this.verificandoUsuario = false;
			},
			(error: any) => {
				if (error instanceof HttpErrorResponse) {
					const jsonErrors = error.error;
					// Usuário não existe na base do Presença
					if (error.status === 404) {
						this.messageService.addConfirmOk('Você não possui acesso ao sistema SISOU.',
							(): ConfirmListener => {
								this.authService.deleteLocalData();
								Keycloak.logout({});
								this.router.navigate(['/']);
								return;
							}, null, 'Informativo');
						return;
					}
				}
				this.verificandoUsuario = false;
			}
		);*/

		return true;
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// Flag para não verificar duas vezes
		if (!this.verificandoUsuario) {
			return this.canActivate(route, state);
		}

		return true;
	}
}
