import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Observable} from 'rxjs/Rx';
import {Entity} from 'app/arquitetura/shared/models/entity';
import {SelectItemModel} from 'app/shared/models/select-item';

@Injectable()
export class TipoOcorrenciaService extends CrudHttpClientService<Entity> {
  constructor(protected http: HttpClient) {
    super('administracao/parametrizacao/tipo-ocorrencia', http);
  }

  public consultarSelectItens(): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(this.url + '/select-itens', this.options());
  }

  public consultarSelectItensSacOuvidoria(): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(this.url + '/select-itens-sac-ouvidoria', this.options());
  }
}
