import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {SelectItemModel} from 'app/shared/models/select-item';
import {SituacaoOcorrencia} from 'app/shared/models/ocorrencia/situacao-ocorrencia';

@Injectable()
export class SituacaoOcorrenciaService extends CrudHttpClientService<SituacaoOcorrencia> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/situacao', http);
  }

  public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens/' + idTipoOcorrencia, this.options());
  }

  public consultarSelectItensOcorrenciaEstoque(idTipoOcorrencia: number, idOrigemEquipe: number): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens-ocorrencia-estoque/' + idTipoOcorrencia + '/' + idOrigemEquipe, this.options());
  }

  public consultarSituacaoSelectItens(): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens', this.options());
  }

  public consultarSituacaoSelectItensComTipoOcorrencia(): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens/concat-tipo-ocorrencia', this.options());
  }
}
