import {Pipe, PipeTransform} from '@angular/core';
import {LeftPadPipe} from "./left-pad.pipe";

@Pipe({name: 'identificadorOcorrencia', pure: false})
export class IdentificadorOcorrenciaPipe implements PipeTransform {

    /**
     * Aplica a máscara no código (Long) identificador da ocorrencia.
     * DDMMAA.9999999
     */
    transform(codigo: number) {
        let leftPipe = new LeftPadPipe();

        if (codigo !== undefined && codigo !== null) {
            const identificador = leftPipe.transform(codigo.toString(), 13, '0')
            // const identificador = ('0' + codigo).slice(-13);
            return `${identificador.substring(0, 6)}.${identificador.substring(6, 13)}`;
        }
    }

}
