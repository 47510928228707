import {SessaoService} from "../services/seguranca/sessao.service";
import {EnumTipoOcorrencia} from "../../../shared/enums/EnumTipoOcorrencia";

export class ModuloUtils {

    public static isModuloSac(): boolean {
        return (SessaoService.usuario.idTipoOcorrencia == EnumTipoOcorrencia.SAC && SessaoService.usuario.frenteAtual == null)
            || (SessaoService.usuario.frenteAtual == EnumTipoOcorrencia.SAC);
    }

    public static isModuloOuvidoria(): boolean {
        return (SessaoService.usuario.idTipoOcorrencia == EnumTipoOcorrencia.OUVIDORIA && SessaoService.usuario.frenteAtual == null)
            || (SessaoService.usuario.frenteAtual == EnumTipoOcorrencia.OUVIDORIA);
    }

    public static isModuloVivaVoz(): boolean {
        return (SessaoService.usuario.idTipoOcorrencia == EnumTipoOcorrencia.INTERNA && SessaoService.usuario.frenteAtual == null)
            || (SessaoService.usuario.frenteAtual == EnumTipoOcorrencia.INTERNA);
    }

}