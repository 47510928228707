import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {SelectItemModel} from 'app/shared/models/select-item';
import {MotivoEncerramento} from 'app/shared/models/ocorrencia/motivo-encerramento';

@Injectable()
export class MotivoEncerramentoService extends CrudHttpClientService<MotivoEncerramento> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/motivo-encerramento', http);
  }

  public consultarSituacaoSelectItens(idTipoOcorrencia: number): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens/' + idTipoOcorrencia, this.options());
  }
}
