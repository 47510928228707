import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Uf} from 'app/shared/models/comum/uf';
import {Municipio} from 'app/shared/models/comum/municipio';
import {Cep} from 'app/shared/models/comum/cep';

@Injectable()
export class LocalidadeService extends CrudHttpClientService<Uf> {
	constructor(protected http: HttpClient) {
		super('comum/localidade', http);
	}

	/**
	 * Consulta e recebe um erro customizado
	 */
	public getAllUfs(): Observable<Uf[]> {
		return this.http.get<Uf[]>(this.url + '/ufs');
	}

	public getMunicipiosBy(idUf: string): Observable<Municipio> {
		return this.http.get<Municipio>(this.url + '/municipios/' + idUf);
	}

	public getCep(cep: string): Observable<Cep> {
		return this.http.get<Cep>(this.url + '/ceps/' + cep);
	}
}
