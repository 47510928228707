import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'situacaoSolicitacaoOcorrenciaInterna' })
export class SituacaoSolicitacaoOcorrenciaInternaPipe implements PipeTransform {
	transform(value: string) {

		if (value === 'N') { return 'Não Acatada'; }
		if (value === 'E') { return 'Em Análise'; }
		if (value === 'A') { return 'Acatada'; }

		return value;
	}
}
