import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {GrupoTratamentoMeta} from 'app/shared/models/administracao/parametrizacao/grupo-tratamento-meta';
import {Observable} from 'rxjs';

@Injectable()
export class GrupoTratamentoMetaService extends CrudHttpClientService<GrupoTratamentoMeta> {
    constructor(
        protected http: HttpClient,
        protected messageService: MessageService
    ) {
        super('administracao/parametrizacao/grupo-tratamento-meta', http);
    }

    public incluir(grupoTratamentoMeta: GrupoTratamentoMeta): Observable<any> {
        return this.post(grupoTratamentoMeta);
    }

}
