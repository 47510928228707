declare var jQuery: any;

export class WebUtil {
	public static changeFocusWithDelay(idElement: string) {
		setTimeout(() => { WebUtil.changeFocus(idElement) });
	}

	public static changeFocus(idElement: string) {
		WebUtil.blurCurrent();
		jQuery('#' + idElement).focus();
	}

	public static blurCurrent() {
		let htmlElement: HTMLElement;

		if (document.activeElement &&
			(document.activeElement instanceof HTMLElement)) {
			htmlElement = document.activeElement;
			htmlElement.blur();
		}
	}

	public static downLoadFile(response: any) {
		let mimeType: string = response.headers.get('content-type');

		let fileName = 'Download';
		if (mimeType === 'application/pdf') {
			fileName += 'pdf';
		}
		else if (mimeType === 'application/ms-excel') {
			fileName += 'xls';
		}
		else if (mimeType === 'text/csv') {
			fileName += 'csv';
		}

		if (response.headers.get('content-disposition') &&
				(response.headers.get('content-disposition').indexOf('filename=') >= 0)) {
			fileName = response.headers.get('content-disposition').split('filename=')[1];

			if (fileName.indexOf('\'') >= 0) {
				fileName = fileName.split('\'')[1];
			}
		}

		let data = response.body;
		let blob = new Blob([data], { type: mimeType });

		// Se for PDF, abre em nova janela
		// if (mimeType.toLowerCase().indexOf('pdf') >= 0) {
		// 	let url = window.URL.createObjectURL(blob);
		// 	let downloadWindow = window.open(url, '_blank');

		// 	if ((!downloadWindow) || downloadWindow.closed ||
		// 			(typeof downloadWindow.closed == 'undefined')) {
		// 		alert('Por favor, desabilite o seu bloqueador de Pop-Up para a URL ' +
		// 			'deste sistema e tente novamente.');
		// 	}
		// }
		// // Caso contrário, faz o download do arquivo
		// else {
		let urlDownload = window.URL.createObjectURL(blob);
		let link = document.createElement('a');
		document.body.appendChild(link);
		link.href = urlDownload;
		console.log(fileName);
		link.download = fileName.split('\"').join('');
		link.click();
		setTimeout(function () { window.URL.revokeObjectURL(urlDownload); }, 0);
		// }
	}

	public static downLoadFileWithName(response: any, name: string) {
		const mimeType: string = response.headers.get('content-type');

		let fileName = '';
		if (mimeType === 'application/pdf') {
			fileName += '.pdf';
		} else if (mimeType === 'application/ms-excel') {
			fileName += '.xls';
		} else if (mimeType === 'text/csv') {
			fileName += '.csv';
		}

		const data = response.body;
		const blob = new Blob([data], { type: mimeType });

		const urlDownload = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		document.body.appendChild(link);
		link.href = urlDownload;
		link.download = name + fileName;
		link.click();
		setTimeout(function () { window.URL.revokeObjectURL(urlDownload); }, 0);
	}
}
