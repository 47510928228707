import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {SelectItemModel} from '../../models/select-item';
import {Entity} from '../../../arquitetura/shared/models/entity';
import {Injectable} from '@angular/core';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class ComboService extends CrudHttpClientService<Entity> {

  private ufs$: Observable<SelectItemModel[]>;

  constructor(protected http: HttpClient) {
    super('comum/combo', http);
  }

  public comboUF(): Observable<SelectItemModel[]> {
    if (!this.ufs$) {
      this.ufs$ = this.http.get<SelectItemModel[]>(this.url + '/uf', this.options()).pipe(shareReplay(1));
    }
    return this.ufs$;
  }

  public comboMunicipio(siglaUF: string): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
		httpParams = httpParams.set('siglaUF', siglaUF);

    return this.http.get<SelectItemModel[]>(this.url + '/municipio-uf', this.options({params: httpParams}));
  }

  public comboBairro(numeroLocalidade: number): Observable<SelectItemModel[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('numeroLocalidade', numeroLocalidade.toString());

		return this.http.get<SelectItemModel[]>(this.url + '/bairro-localidade', this.options({params: httpParams}));
  }

  public comboTipoTemplate(): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(this.url + '/tipo-template');
  }

  public comboTextoComplementar(): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(this.url + '/texto-complementar');
  }

  public comboTextoComplementarResposta(): Observable<SelectItemModel[]> {
    return this.http.get<SelectItemModel[]>(this.url + '/texto-complementar-resposta');
  }
}
