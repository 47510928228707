import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {SelectItemModel} from 'app/shared/models/select-item';
import {SituacaoTarefa} from 'app/shared/models/ocorrencia/situacao-tarefa';

@Injectable()
export class SituacaoTarefaService extends CrudHttpClientService<SituacaoTarefa> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/situacaoTarefa', http);
  }

  public consultarSituacaoSelectItens(): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/select-itens', this.options());
  }
}
