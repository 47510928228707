import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {environment} from 'environments/environment';

@Injectable()
export class HttpClientService {
    private static nomeSistema = '';
    static urlBase = '';
    static urlBaseWebsocket = '';

    public static setNomeSistema(nomeSistema: string) {
        HttpClientService.nomeSistema = nomeSistema;
    }

    /**
     * Recupera o URL padrão para acesso a API de acordo com o ambiente
     */
    static getBackendUrl(): string {
        if (HttpClientService.urlBase.length === 0) {
            const url: string = window.location.href;
            const sufixo: string = '/' + HttpClientService.nomeSistema.toLowerCase() + '-api/api/';

            if (environment.backendUrlFromFrontend) {
                const index: number = url.toUpperCase().indexOf('/' + HttpClientService.nomeSistema + '-WEB/');
                if (index >= 0) {
                    HttpClientService.urlBase = url.substring(0, index) + sufixo;
                }
            } else {
                if (environment.backendHttps) {
                    HttpClientService.urlBase = 'https';
                } else {
                    HttpClientService.urlBase = 'http';
                }
                HttpClientService.urlBase = HttpClientService.urlBase + environment.url +
                    environment.backendPort + sufixo;
            }
        }

        return HttpClientService.urlBase;
    }

    static getUrlWebsocket(): string {
        let urlBaseWebsocket: string = window.location.href;
        const sufixo: string = '/' + HttpClientService.nomeSistema.toLowerCase() + '-api/';

        if (environment.backendUrlFromFrontend) {
            if(urlBaseWebsocket.includes('https')){
                urlBaseWebsocket = urlBaseWebsocket.replace('https', 'wss');
            }else{
                urlBaseWebsocket = urlBaseWebsocket.replace('http', 'ws');
            }
            const index: number = urlBaseWebsocket.toUpperCase().indexOf('/' + HttpClientService.nomeSistema + '-WEB/');
            if (index >= 0) {
                HttpClientService.urlBaseWebsocket = urlBaseWebsocket.substring(0, index) + sufixo;
            }
        } else {
            if (environment.backendHttps) {
                HttpClientService.urlBaseWebsocket = 'wss';
            } else {
                HttpClientService.urlBaseWebsocket = 'ws';
            }
            HttpClientService.urlBaseWebsocket = HttpClientService.urlBaseWebsocket + environment.url +
                environment.backendPort + sufixo;
        }

        return HttpClientService.urlBaseWebsocket;
    }


    public queryParams: any = null;

    constructor(
        public url: string,
        protected http: HttpClient,
        private withCredentials: boolean = true
    ) {
        // Define o endpoint
        this.url = HttpClientService.getBackendUrl() + url;
    }

    protected options(
        options?: {
            headers?: HttpHeaders;
            observe?: 'body';
            params?: HttpParams;
            reportProgress?: boolean;
            responseType?: any;
            withCredentials?: boolean;
        }
    ) {
        if (!options) {
            options = {};
        }

        let headers: HttpHeaders = new HttpHeaders();
        if (options.headers) {
            for (const headerName of options.headers.keys()) {
                headers = headers.set(headerName, options.headers.getAll(headerName));
            }
        }
        options.headers = headers;

        if (!options.responseType) {
            options.responseType = 'json';
        }

        if (!options.withCredentials) {
            options.withCredentials = this.withCredentials;
        }

        return options;
    }

    protected logError(error: any) {
        if (error.status === 0) {
            console.log('Backend indisponível  [' + HttpClientService.getBackendUrl() + ']');
        }
    }

    /**
     * Monta um objeto HttpParams a partir de um Object passado como parametro ignorando as
     * propriedades com valores não definidos.
     * @param options
     */
    protected getHttpParams(options?: any): HttpParams {
        if (!options) return new HttpParams();
        const clone = Object.assign({}, options);
        Object.keys(clone).forEach(key => (!clone[key]) && delete clone[key]);
        return Object.keys(clone).reduce((params, key) => params.set(key, clone[key]), new HttpParams());
    }

}
