import {Component, OnInit} from '@angular/core';
import {SelectItemModel} from '../../models/select-item';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MessageService} from '../messages/message.service';
import {MovimentacaoOcorrenciaService} from '../../services/ocorrencia/movimentacao-ocorrencia.service';
import {SituacaoOcorrenciaService} from '../../services/ocorrencia/situacao-ocorrencia.service';
import {EquipeOcorrenciaService} from '../../services/ocorrencia/equipe-ocorrencia.service';
import {SessaoService} from '../../../arquitetura/shared/services/seguranca/sessao.service';
import {FiltroPaginado} from '../../models/filtroPaginado';
import {BasePageable} from '../base-pageable';
import {SolicitacaoUnidadeService} from '../../services/solicitacao/solicitacao-unidade.service';
import {MovimentacaoSolicitacao} from '../../models/solicitacao/MovimentacaoSolicitacao';

@Component({
  selector: 'app-modal-historico-solicitacao',
  templateUrl: './modal-historico-solicitacao.component.html',
  styleUrls: ['./modal-historico-solicitacao.component.scss']
})
export class ModalHistoricoSolicitacaoComponent extends BasePageable implements OnInit {


  display = false;
  idSolicitacao: any;
  usuarios: SelectItemModel[];
  tipos: SelectItemModel[];
  formulario: FormGroup;
  listaMovimentacaoSolicitacao: MovimentacaoSolicitacao[];


  cols: any[] = [
    {field: 'origem', header: 'Origem'},
    {field: 'nuOcorrencia', header: 'Nº Solicitação'},
    {field: 'nuMatricula', header: 'Usuário'},
    {field: 'data', header: 'Data/Hora'},
    {field: 'acao', header: 'Ação'},
    {field: 'descricao', header: 'Descrição'}
  ];

  public pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };
  formFiltroContestacao: FormGroup;


  constructor(private formBuilder: FormBuilder,
              protected messageService: MessageService,
              private movimentacaoOcorrenciaService: MovimentacaoOcorrenciaService,
              private situacaoOcorrenciaService: SituacaoOcorrenciaService,
              private equipeOcorrenciaService: EquipeOcorrenciaService,
              private sessaoService: SessaoService,
              private solicitacaoUnidadeService: SolicitacaoUnidadeService
  ) {
    super(messageService);
  }

  ngOnInit() {
    this.carregarFormFiltroContestacao();
    this.carregarUsuarios();
    this.carregarTipos();
  }

  carregarFormFiltroContestacao(): void {
    this.formulario = this.formBuilder.group({
      nuSolicitacao: [''],
      nuTipoMovimentacao: [''],
      data: [''],
      dataFim: [''],
      nuMatriculaMovimentacao: [''],
    });
  }

  consultarPaginado(): void {
    const filtro: FiltroPaginado = this.getFiltroPaginado(this.formulario.getRawValue());
    filtro.filtro.nuSolicitacao = this.idSolicitacao;
    // filtro.filtro.nuMatriculaMovimentacao = this.formulario.get('nuMatriculaResponsavel').value ? this.formulario.get('nuMatriculaResponsavel').value.value : '';
    // filtro.filtro.nuTipoMovimentacao = this.formulario.get('nuTipoMovimentacao').value ? this.formulario.get('nuTipoMovimentacao').value.value : null;

    this.searchPageble(this.solicitacaoUnidadeService.consultarHistoricoSolicitacao(filtro)).subscribe(
      (resposta: MovimentacaoSolicitacao[]) => {
        this.listaMovimentacaoSolicitacao = resposta;
      }, err => {
        this.messageService.addMsgDanger(err.error);
      }
    );


  }

  public show(idSolicitacao): void {
    this.idSolicitacao = idSolicitacao;
    this.display = true;
    this.listaMovimentacaoSolicitacao = [];
    this.consultarPaginado();
  }


  carregarUsuarios(): void {
    this.equipeOcorrenciaService.consultarUsuariosTratamento(this.sessaoService.getEquipeUsuario().idEquipe).subscribe(resultado => {
        this.usuarios = resultado;

        const selecione = new SelectItemModel();
        selecione.label = 'Selecione';
        this.usuarios.unshift(selecione);
      },
      error => {
        this.messageService.addMsgDanger(error.error);
      });
  }

  carregarTipos(): void {
    this.movimentacaoOcorrenciaService.consultarTipos()
      .subscribe(tipos => {
          this.tipos = tipos;

          const selecione = new SelectItemModel();
          selecione.label = 'Selecione';
          this.tipos.unshift(selecione);
        },
        error => {
          this.messageService.addMsgDanger(error.error);
        });
  }

}
