import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Entity} from 'app/arquitetura/shared/models/entity';
import {
    TipoMovimentacaoTextoComplementar
} from 'app/shared/models/administracao/parametrizacao/tipo-movimentacao-texto-complementar';

@Injectable()
export class TipoMovimentacaoTextoComplementarService extends CrudHttpClientService<Entity> {
  constructor(protected http: HttpClient) {
    super('administracao/parametrizacao/tipo-movimentacao-texto-complementar', http);
  }

  public consultarListDTO(idTipoMovimentacao: number): Observable<TipoMovimentacaoTextoComplementar[]> {
    return this.http.get<TipoMovimentacaoTextoComplementar[]>(this.url + '/consultar-tipo-movimentacao/' + idTipoMovimentacao, this.options());
  }
}
