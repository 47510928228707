import {EnumTipoOcorrencia} from "../enums/EnumTipoOcorrencia";

export class PerfilModulo {

  // VIVA VOZ
  public static readonly INTERNA = { label: 'SOUADM01', value: 'SOUADM01', tipoOcorrencia: EnumTipoOcorrencia.INTERNA };
  public static readonly VIVA_ADMIN = { label: 'VIVA_ADMIN', value: 'VIVA_ADMIN', tipoOcorrencia: EnumTipoOcorrencia.INTERNA };
  //public static readonly VIVA_SOL = { label: 'VIVA_SOL', value: 'VIVA_SOL', tipoOcorrencia: EnumTipoOcorrencia.INTERNA };

  // OUVIDORIA
  public static readonly OUVIDORIA = { label: 'SOUADM02', value: 'SOUADM02', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  public static readonly OUV_CURADORIA = { label: 'Curadoria', value: 'OUV_CURADORIA', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  public static readonly OUV_ESTAGIARIOMENOR = { label: 'Estagiário e Menor aprendiz', value: 'OUV_ESTAGIARIOMENOR', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  public static readonly OUV_ESTRATEGIA = { label: 'Estratégia', value: 'OUV_ESTRATEGIA', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  public static readonly OUV_GESTORSISTEMA = { label: 'Gestor Sistema', value: 'OUV_GESTORSISTEMA', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  public static readonly OUV_OPERADOR = { label: 'Operador', value: 'OUV_OPERADOR', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  public static readonly OUV_SERVICO = { label: 'Serviço', value: 'OUV_SERVICO', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  //public static readonly OUV_SUBSIDIARIA = { label: 'Subsidiária', value: 'OUV_SUBSIDIARIA', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  public static readonly OUV_SUPERVISOR = { label: 'Supervisor', value: 'OUV_SUPERVISOR', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  public static readonly OUV_2INSTANCIA = { label: 'Ouvidor Segunda Instância', value: 'OUV_2INSTANCIA', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  public static readonly SOU_TERCEIRIZADAGE = { label: 'Terceirizado ge', value: 'SOU_TERCEIRIZADAGE', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };
  public static readonly SOU_TERCEIRIZADAOP = { label: 'Terceirizado IP', value: 'SOU_TERCEIRIZADAOP', tipoOcorrencia: EnumTipoOcorrencia.OUVIDORIA };

  // SAC
  public static readonly SAC = { label: 'SOUADM03', value: 'SOUADM03', tipoOcorrencia: EnumTipoOcorrencia.SAC };
  public static readonly SAC_GEST_ADM_SISTEMA = { label: 'Gestor Administrativo do Sistema', value: 'SAC_GEST_ADM_SISTEMA', tipoOcorrencia: EnumTipoOcorrencia.SAC };
  public static readonly SAC_AUDITORIA = { label: 'Auditoria', value: 'SAC_AUDITORIA', tipoOcorrencia: EnumTipoOcorrencia.SAC };
  //public static readonly SAC_OPER_CAIXA = { label: 'Operador', value: 'SAC_OPER_CAIXA', tipoOcorrencia: EnumTipoOcorrencia.SAC };
  public static readonly SAC_GEST_UNID_ATEND = { label: 'Gestor da Unidade de Atendimento', value: 'SAC_GEST_UNID_ATEND', tipoOcorrencia: EnumTipoOcorrencia.SAC };
  public static readonly SAC_OPER_UNID_ATEND = { label: 'Operador da Unidade de Atendimento', value: 'SAC_OPER_UNID_ATEND', tipoOcorrencia: EnumTipoOcorrencia.SAC };
  public static readonly SAC_OPER_N2 = { label: 'Terceirizado N1', value: 'SAC_OPER_N2', tipoOcorrencia: EnumTipoOcorrencia.SAC };
  public static readonly SAC_ESTAGIARIO = { label: 'Estagiário', value: 'SAC_ESTAGIARIO', tipoOcorrencia: EnumTipoOcorrencia.SAC };

  public static readonly SAC_OPER_N1 = { label: 'Operador N1', value: 'SAC_OPER_N1', tipoOcorrencia: EnumTipoOcorrencia.SAC }

  public static readonly SISOU_UNID_CAIXA = { label: 'SISOU_UNID_CAIXA', value: 'SISOU_UNID_CAIXA'};
  public static readonly SOU_AUDITOR = { label: 'SOU_AUDITOR', value: 'SOU_AUDITOR'};
  public static readonly SOU_PERFIL_HABILITADO_UNID_CAIXA = { label: 'SOU_PERFIL_HABILITADO_UNID_CAIXA', value: 'SOU_PERFIL_HABILITADO_UNID_CAIXA'};
  public static listaPerfis: any[] = [
                                      PerfilModulo.SISOU_UNID_CAIXA,
                                      PerfilModulo.SOU_AUDITOR,
                                       // INTERNA
                                       PerfilModulo.VIVA_ADMIN,
                                       // OUVIDORIA
                                       PerfilModulo.OUV_CURADORIA,
                                       PerfilModulo.OUV_ESTAGIARIOMENOR,
                                       PerfilModulo.OUV_ESTRATEGIA,
                                       PerfilModulo.OUV_GESTORSISTEMA,
                                       PerfilModulo.OUV_OPERADOR,
                                       PerfilModulo.OUV_SERVICO,
                                       //PerfilModulo.OUV_SUBSIDIARIA,
                                       PerfilModulo.OUV_SUPERVISOR,
                                      // SAC
                                       PerfilModulo.SAC_GEST_ADM_SISTEMA,
                                       PerfilModulo.SAC_AUDITORIA,
                                       PerfilModulo.SAC_GEST_UNID_ATEND,
                                       PerfilModulo.SAC_OPER_UNID_ATEND,
                                       PerfilModulo.SAC_ESTAGIARIO,
                                       PerfilModulo.SAC_OPER_N1,
                                       PerfilModulo.SAC_OPER_N2];

  public static fromModulo(perfil: string): any {
    let retorno: any;
    this.listaPerfis.forEach(p => {
      if(p.value === perfil){
        retorno = p;
      }
    });
    return retorno;
  }
}
