import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

/**
 * Implementação de componente responsável por gerar a estrutura 'html' onde os alertas serão gerados.
 */
@Component({
	selector: 'app-loading-modal',
	templateUrl: './loading-modal.component.html',
	styleUrls: ['./loading-modal.component.scss'],
	exportAs: 'Loading',
	encapsulation: ViewEncapsulation.None
})
export class LoadingModalComponent implements OnInit{
	@Input() loader$: Observable<boolean>;
	isLoading: boolean;
	subscription: Subscription;

	constructor() { }

	ngOnInit(){
		if(!!this.loader$) {
			this.subscription = this.loader$.subscribe(res => {
				this.isLoading = res;
			})
		}
	}
}
