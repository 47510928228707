import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'leftPad', pure: false})
export class LeftPadPipe implements PipeTransform {
    transform(value: string, len: number, ch: string = '.'): string {
        if (value != null) {
            len = len - value.toString().length + 1;
            return len > 0 ?
                new Array(len).join(ch) + value : value;
        } else {
            return value;
        }
    }
}