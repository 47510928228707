import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {ParametroConsole} from 'app/shared/models/administracao/parametrizacao/parametro-console';
import {HistoricoConsoleGestor} from '../../../models/ocorrencia/historico-console-gestor';
import {Observable} from 'rxjs';

@Injectable()
export class ParametroConsoleService extends CrudHttpClientService<ParametroConsole> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('administracao/parametrizacao/parametro-console', http);
  }

  public consultar(): any {
    return this.http.get<ParametroConsole[]>(this.url, this.options());
  }

  public consultarPorOrigem(idOrigem: string): any {
    return this.http.post<ParametroConsole[]>(this.url + '/consultar-por-origem', idOrigem, this.options());
  }

  consultarHistorico(): Observable<HistoricoConsoleGestor[]> {
    return this.http.post<HistoricoConsoleGestor[]>(this.url + '/historico', null, this.options());
  }

}
