import {CustomTableCol} from './custom-table-col';

export class CustomTableRow {
    constructor (
        public id: string,
        public coluns: CustomTableCol[],
        public clazz: any,
        public style: any,
        public showEdit: boolean,
        public showDelete: boolean,
        public showDetail: boolean,
        public showSelect: boolean,
        public showHover: boolean,
        public idModal: string,
        public object: any
    ) {}
}
