import {FormControl, FormGroup} from "@angular/forms";
import {MessageService} from "app/shared/components/messages/message.service";
import {ArquivoService} from "app/shared/services/comum/arquivo.service";
import * as moment from 'moment';
import {WebUtil} from "./web-util";

export class CrudUtils {
    private static messageService: MessageService = MessageService.instance;

    public static trataErro(error: any, msg?: string): void {
        if (msg) {
            if (CrudUtils.messageService) CrudUtils.messageService.addMsgDanger(msg);
        }
        console.log('Erro:', error);
        console.trace();
    }

    public static excluir(lista: any[], objeto: any): void {
        lista.splice(lista.indexOf(objeto), 1);
    }

    public static excluirPeloIndice(lista: any[], index: number): void {
        lista.splice(index, 1);
    }

    public static somaDias(date: any, qtdDias: number) {
        return moment(moment(date, 'DD-MM-YYYY').add(qtdDias, 'days')).toDate();
    }

    public static diferencaEmDiasMoment(dataInicio: any, dataFim: any): number {
        const prz = moment(dataFim);
        const hj = moment(dataInicio);
        let diffPrazo = prz.diff(hj, 'days') + 1;
        return diffPrazo;
    }

    public static compararDatas(data1: any, data2: any): boolean {
        const d1 = moment(data1).format('YYYY-MM-DD');
        const d2 = moment(data2).format('YYYY-MM-DD');
        return d1 == d2;
    }

    public static isDataVencida(dataParam: any): boolean {
        const dParam = moment(moment(dataParam).format('YYYY-MM-DD'));
        const dAtual = moment(moment(new Date()).format('YYYY-MM-DD'));
        return dParam.isBefore(dAtual);
    }

    public static diferencaEmDias(dataInicio: any, dataFim: any) {
        if (dataInicio == undefined || dataFim == undefined) {
            return 0;
        }

        let dt1: Date;
        let dt2: Date;

        if (dataInicio instanceof Date) {
            dt1 = dataInicio;
        } else {
            dt1 = this.toDate(dataInicio.toString());
        }

        if (dataFim instanceof Date) {
            dt2 = dataFim;
        } else {
            dt2 = this.toDate(dataFim.toString());
        }

        const diffc: number = dt2.getTime() - dt1.getTime();

        if (diffc > 0) {
            const days = Math.round(Math.abs(diffc / (1000 * 60 * 60 * 24)));
            return days;
        }

        if (diffc < 0) {
            const days = (-1) * Math.round(Math.abs(diffc / (1000 * 60 * 60 * 24)));
            return days;
        }

        return 0;
    }

    public static toDate(dataStr: string) {
        return moment(dataStr).toDate();
    }


    public static forceValidateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.forceValidateAllFormFields(control);
            }
        });
    }

    public static scrollTop(eixoY?: number): void {
        if (!!eixoY) {
            window.scrollTo(0, eixoY);
        } else {
            window.scrollTo(0, 0);
        }
    }


    public static downloadArquivo(arquivoService: ArquivoService, codigoGed: string) {
        arquivoService.download(codigoGed).subscribe(
            response => {
                WebUtil.downLoadFile(response);
                console.log('sucesso');
            },
            err => {
                console.log(err);
                // this.messageService.addMsgDanger(err.error);
            }
        );
    }


    public static clone(source: any) {
        let target: any = {};
        Object.assign(target, source);
        return target;
    }

    public static isString(value: any) {
        return (typeof value === 'string' || value instanceof String);
    }


    public static tiposAceitosUpload = [
        'application/msword',
        'application/zip',
        'application/x-zip-compressed',
        'application/pdf',
        'text/plain',
        'application/excel',
        'application/vnd.ms-excel',
        'application/msexcel',
        'application/powerpoint',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'image/png',
        'image/jpeg',
        'image/bmp',
        'image/pjpeg',
        'image/gif'
    ];
}