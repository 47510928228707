import {DiarioBordo} from './diario-bordo';
import {ProrrogacaoOcorrenciaOuvidoria} from './prorrogacao-ocorrencia-ouvidoria';

export class Cabecalho {

		id: number;

    //sac
    protocoloExterno: string;
    dataAberturaCliente: Date;
    //fim sac

    cpfCnpj: string;
    nomeCliente: string;
    emailPadraoCliente: string;
    telefonePadraoCliente: string;
    enderecoPadraoCliente:string;

    idSituacaoOcorrencia: number;
    idTipoOcorrencia: number;
    idOrigem: number;
    idNatureza: number;
    isOrigemBacen: boolean;
    idGrupoTratamento: number;

    idProcon: number;
    idPostoProcon: number;
    nomeProcon: string;
    nomePostoProcon: string;
    tipoAtendimentoPosto: string;
    enderecoProcon: string;

    idTipoProcon: number;
    emailProcon: string;

    dataInicio: Date;
    prazo: number;
    prazoFinal: Date;
    dataRecebimento: Date;
    dataAudiencia: Date;
    prazoResposta: Date;
    autoInfracao: String;

    nuUnidadeFiscalizada: number;
    noUnidadeFiscalizada: string;
    nuNaturalFiscalizada: number;
    formaRecebimentoResposta?: number[];
    motivosRetorno?: number[];
    icAutoInfracao: String;
    processoAdministrativo: String;
    valorMulta: number;
    emailEntidadeCivil: String;
    numeroRDR: number;
    registroRDR: number;
    fichaAutuacao: String;
    horaAudiencia: number;
    identificacaoEntidade: String;
    numeroFormaAudiencia: number;
    prazoPagamento: Date;
    prazoDefesa: Date;

    identificacaoOcorrencia: number;

    idSolicitante: number;
    matriculaCadastro: string;

    tipoSolicitacao: string;
    numeroNaturalUnidade: number;
    numeroUnidade: number;

    prorrogacaoOcorrencia: ProrrogacaoOcorrenciaOuvidoria;
    nomeSituacao: string;
    nuUnidadeTratamento: number;
    noUnidadeTratamento: string;

    idTipoAtendimento: number;
    idProblemaComunicacao: number;
    ocorrenciaVinculada: number;
    identificacaoOcorrenciaVinculada: number;
    tipoOcorrenciaVinculada: string;
    situacaoOcorrenciaVinculada: string;
    diariosBordo: DiarioBordo[];

    preOcorrencia: number;
    nomeOrigem: string;
    nomeNatureza: string;
    dataRegistroPreOcorrencia: Date;

    tipoAtendimento0800: number;
}
