import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';

import {TratamentoOcorrenciaExterna} from 'app/shared/models/tratar-ocorrencia/tratamento-ocorrencia-externa';
import { Observable } from 'rxjs/Rx';
import { SessaoService } from '../../../arquitetura/shared/services/seguranca/sessao.service';

@Injectable()
export class TratamentoOcorrenciaExternaService extends CrudHttpClientService<TratamentoOcorrenciaExterna> {

    constructor(protected http: HttpClient) {
    super('ocorrencia-externa/tratamento', http);
  }

  public gerarRelatorioResposta(id: number): Observable<any> {
    let perfilSelecionado = SessaoService.usuario.perfilSelecionado
    let matricula = SessaoService.usuario.matricula
    return this.http.post(this.url + '/gerar-relatorio-detalhar/'+perfilSelecionado+'/'+matricula, id, this.options(
      { observe: 'response' as 'body', responseType: 'arraybuffer' }));
  }

}
