// /-------------------------------------------------\
// | artefatos da biblioteca angular                 |
// \-------------------------------------------------/
import {EventEmitter, Injectable} from '@angular/core';

import {InternacionalizacaoPipe} from './internacionalizacao.pipe';
import {MessageResource} from './message-resource';
import {MessageResourceProvider} from './message-resource-provider';
import {MessageItem} from './message-item';
import {ConfirmListener} from './confirm-listener';

// /--------------------------------------------------\
// | message.service.ts                               |
// |--------------------------------------------------|
// | Define os tipos de mensagem e alertas da         |
// | aplicação.                                       |
// \--------------------------------------------------/
/**
 * Classe 'service' responsável por prover o recurso de mensagem da aplicação.
 */
@Injectable()
export class MessageService {
  private messageResource: MessageResource;
  private i18nPipe: InternacionalizacaoPipe;
  private msgEmitter: EventEmitter<MessageItem>;
  private confirmEmitter: EventEmitter<MessageItem>;

  //usada em classes uteis
  public static instance: MessageService;

  /**
	 * Construtor da classe.
	 *
	 * @param i18nPipe
	 */
  constructor(
    messageResource: MessageResourceProvider,
    i18nPipe: InternacionalizacaoPipe
  ) {
    this.messageResource = new messageResource();
    this.i18nPipe = i18nPipe;
    this.msgEmitter = new EventEmitter();
    this.confirmEmitter = new EventEmitter();

    MessageService.instance = this;
  }

  /**
	 * Retorna a descrição da mensagem conforme os parâmetros informados.
	 *
	 * @param msg
	 */
  private getDescription(msg: string, params: any): string {
    let description = null;

    if (msg !== null && msg !== undefined && msg.trim().length !== 0) {
      description = this.i18nPipe.transform(msg, params);
      description = description === undefined ? msg : description;
    }

    return description;
  }

  /**
	 * Adiciona o modal de confirmação segundo o type (confirm_ok, confirm_yes_no), informado.
	 *
	 * @param msg
	 * @param type
	 * @param params
	 */
  private addConfirm(
    msg: string,
    type: string,
    params: any,
    listenerYesOk?,
    listenerNo?,
    strBtnOk?,
    strBtnCancel?,
    title?,
    setFocus?: boolean
  ): void {
    const description = this.getDescription(msg, params);

    if (description !== null) {
      this.confirmEmitter.emit(new MessageItem(this.messageResource, description, type,
        listenerYesOk, listenerNo, strBtnOk, strBtnCancel, title, setFocus));
    }
  }

  /**
	 * Adiciona o modal de confirmação OK.
	 *
	 * @param msg
	 * @param listenerOk
	 * @param params
	 */
  public addConfirmOk(
    msg: string,
    listenerOk?: (() => ConfirmListener),
    params?: any,
    title?: string,
    setFocus?: boolean
  ): void {
    this.addConfirm(msg, MessageItem.CONFIRM_TYPE_OK, params, listenerOk, null, null,
      null, title, setFocus);
  }

  /**
	 * Adiciona o modal de confirmação YES/NO.
	 *
	 * @param msg
	 * @param listenerYes
	 * @param listenerNo
	 * @param params
	 */
  public addConfirmYesNo(
    msg: string,
    listenerYes?: (() => ConfirmListener),
    listenerNo?: (() => ConfirmListener),
    params?: any, strBtnOk?: string,
    strBtnCancel?: string
  ): void {
    this.addConfirm(msg, MessageItem.CONFIRM_TYPE_YES_NO, params, listenerYes,
      listenerNo, strBtnOk, strBtnCancel);
  }

  /**
	 * Adiciona a mensagem segundo o type (alert-success, alert-info,
	 * alert-warning e alert-danger), informado.
	 *
	 * @param msg
	 * @param type
	 * @param params
	 */
  private addMsg(msg: string, type: string): void {
    this.msgEmitter.emit(new MessageItem(this.messageResource, msg, type));
  }

  /**
	 * Adiciona mensagem de Sucesso.
	 *
	 * @param msg
	 * @param params
	 */
  public addMsgSuccess(msg: string | string[]): void {
    this.addMsgApplication(msg, MessageItem.ALERT_TYPE_SUCCES);
  }

  /**
	 * Adiciona mensagem de Informação.
	 *
	 * @param msg
	 * @param params
	 */
  public addMsgInf(msg: string | string[]): void {
    this.addMsgApplication(msg, MessageItem.ALERT_TYPE_INFO);
  }

  /**
	 * Adiciona mensagem de Alerta.
	 *
	 * @param msg
	 * @param params
	 */
  public addMsgWarning(msg: string | string[]): void {
    this.addMsgApplication(msg, MessageItem.ALERT_TYPE_WARNING);
  }

  /**
	 * Adiciona mensagem de Erro.
	 *
	 * @param msg
	 * @param params
	 */
  public addMsgDanger(msg: string | string[]): void {
    this.addMsgApplication(msg, MessageItem.ALERT_TYPE_DANGER);
  }

  /**
	 * @returns EventEmitter
	 */
  public getMsgEmitter(): EventEmitter<MessageItem> {
    return this.msgEmitter;
  }

  /**
	 * @returns EventEmitter
	 */
  public getConfirmEmitter(): EventEmitter<MessageItem> {
    return this.confirmEmitter;
  }

  private addMsgApplication(msg: string | any[], typeMessage) {
    if (Array.isArray(msg)) {
      msg.forEach(message => {
        this.addMsg(message, typeMessage);
      });
    } else {
      this.addMsg(msg, typeMessage);
    }
  }

  public addConfirmYesNoTitle(
    msg: string,
    title: string,
    listenerYes?: (() => ConfirmListener),
    listenerNo?: (() => ConfirmListener),
    params?: any, strBtnOk?: string,
    strBtnCancel?: string
  ): void {
    this.addConfirm(msg, MessageItem.CONFIRM_TYPE_YES_NO, params, listenerYes,
      listenerNo, strBtnOk, strBtnCancel,title);
  }
}
