import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'correspondente' })
export class CorrespondentePipe implements PipeTransform {
	transform(value: string) {

		if (value == "C") {
			return "Bancário";
		} 
		if (value == "L") {
			return "Lotérico";
		}

		return value;
	}
}
