import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'telefoneMask', pure: false })
export class TelefonePipe implements PipeTransform {
    transform(value: string) {
        if (value) {
            value = value.toString();

            if (value.length === 9) {
                return value.substring(0, 5)
                    .concat('-')
                    .concat(value.substring(5, 9));

            }

            if (value.length === 8) {
                return value.substring(0, 4)
                    .concat('-')
                    .concat(value.substring(4, 9));

            }

            if(value.length === 11){
                return '('.concat(value.substring(0,2)).concat(') ')
                    .concat(value.substring(2,7)).concat('-')
                    .concat(value.substring(7,11));
            }

            if(value.length === 10){
                return '('.concat(value.substring(0,2)).concat(') ')
                    .concat(value.substring(2,6)).concat('-')
                    .concat(value.substring(6,10));
            }
        }

        return value;
    }
}
