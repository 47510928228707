import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Entity} from 'app/arquitetura/shared/models/entity';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {UnidadeBloqueada} from 'app/shared/models/administracao/parametrizacao/unidade-bloqueada';
import {Observable} from 'rxjs';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {TipoRelatorio} from '../../../../arquitetura/shared/reports/tipo-relatorio';
import {RelatorioComFiltro} from '../../../models/relatorio-com-filtro';

@Injectable()
export class UnidadeBloqueadaService extends CrudHttpClientService<Entity> {
  constructor(protected http: HttpClient) {
		super('administracao/parametrizacao/unidade-bloqueada', http);
	}

	public excluir(unidadeBloqueada: UnidadeBloqueada): Observable<any> {
		return this.http.post<UnidadeBloqueada>(this.url + '/excluir', unidadeBloqueada, this.options());
	}

	public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
		return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
	}	

	public consultar(unidadeBloqueada: UnidadeBloqueada): any {
		return this.http.post<boolean>(this.url + '/consultar', unidadeBloqueada, this.options());
	}

	public consultarPorUnidadeBloqueada(idUnidadeBloqueada: number, nuTipoOcorrencia: number): any {
		return this.http.get(this.url + '/idUnidadeBloqueada/' + idUnidadeBloqueada + '/' + nuTipoOcorrencia, this.options());
	}

	public consultarPorId(idUnidadeBloqueada:number, nuTipoOcorrencia: number): any {
		return this.http.get(this.url + '/' + idUnidadeBloqueada + '/' + nuTipoOcorrencia, this.options() );
	}

	public gerarRelatorioSacOuvidoria(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
		let relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
		relatorioComFiltro.tipo = TipoRelatorio[tipo];
		relatorioComFiltro.filtro = filtro;

		return this.http.post(this.url + '/gerar-relatorio-sac-ouvidoria', relatorioComFiltro, this.options(
			{ observe: 'response' as 'body', responseType: 'arraybuffer' }));
	}
	
	public isUnidadeBloqueada(unidadeBloqueada: UnidadeBloqueada): any {
		return this.http.post(this.url + '/is-unidade-bloqueada', unidadeBloqueada, this.options() );
	}

}
