import {EmpregadoUnidade} from "./empregado-unidade";

export class Empregado {

    matricula: string;
    'digito-verificador-matricula': number;
    nome: string;
    cpf: number;
    'codigo-funcao': number;
    'nome-funcao': string;
    cargo: string;
    'data-previsao-desligamento': string;
    'unidade-lotacao': EmpregadoUnidade;
    'unidade-destacamento': EmpregadoUnidade;

    get dvMatricula(): number {return this['digito-verificador-matricula']}
    set dvMatricula(dvMatricula: number) {this['digito-verificador-matricula'] = dvMatricula}

    get numeroFuncao(): number {return this['codigo-funcao']}
    set numeroFuncao(numeroFuncao: number) {this['codigo-funcao'] = numeroFuncao}

    get nomeFuncao(): string {return this['nome-funcao']}
    set nomeFuncao(numeroFuncao: string) {this['nome-funcao'] = numeroFuncao}

    get dataPrvsaDslgo(): string {return this['data-previsao-desligamento']}
    set dataPrvsaDslgo(dataPrvsaDslgo: string) {this['data-previsao-desligamento'] = dataPrvsaDslgo}

    get unidadeLotacao(): EmpregadoUnidade {return this['unidade-lotacao']}
    set unidadeLotacao(unidadeLotacao: EmpregadoUnidade) {this['unidade-lotacao'] = unidadeLotacao}

    get unidadeDestacamento(): EmpregadoUnidade {return this['unidade-destacamento']}
    set unidadeDestacamento(unidadeDestacamento: EmpregadoUnidade) {this['unidade-destacamento'] = unidadeDestacamento}
}