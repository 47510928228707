import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Exemplo} from 'app/arquitetura/shared/models/exemplo';

@Injectable()
export class ExemploService extends CrudHttpClientService<Exemplo> {
	constructor(protected http: HttpClient) {
		super('exemplo/exemplo', http);
	}

	/**
	 * Consulta e recebe um erro customizado
	 */
	public consultarComErro(id?: number): Observable<Exemplo> {
		return this.http.get<Exemplo>(this.url + '/consultar-com-erro', this.options());
	}
}
