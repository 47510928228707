import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {ArquivoGedUpload} from 'app/shared/models/comum/arquivo-ged-upload';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class ArquivoService extends CrudHttpClientService<any> {

  private dadosArquivosGedSubject$ = new Subject<ArquivoGedUpload>();
  dadosArquivosGed$ = this.dadosArquivosGedSubject$.asObservable();

  constructor(protected http: HttpClient) {
    super('comum/arquivo', http);
  }

  realizarUpload(formData: FormData) {
    return this.http.post<ArquivoGedUpload[]>(`${this.url}/upload`, formData, this.options());
  }

  downloadPorIdAnexoOcorrencia(idAnexoOcorrencia: number): Observable<any> {
    return this.http.get(`${this.url}/download/${idAnexoOcorrencia}`, this.downloadOptions());
  }

  download(idDocumentoGED: string): Observable<any> {
    if(idDocumentoGED != null){
      return this.http.get(`${this.url}/download/ged/${idDocumentoGED}`, this.downloadOptions());
    } else{
      return null;
    }
  }

  recuperarGrauSigiloPorId(idGrauSigilo: string): Observable<any> {
    return this.http.get(`${this.url}/recuperar-grau-sigilo-por-id/${idGrauSigilo}`, this.options());
  }

  inativar(arquivoGedUpload: ArquivoGedUpload): Observable<any> {
    return this.http.put(`${this.url}/inativar`, arquivoGedUpload, this.options());
  }

  atualizarIndices(arquivoGedUpload: ArquivoGedUpload): Observable<any> {
    return this.http.put(`${this.url}/atualizar-indices`, arquivoGedUpload, this.options());
  }

  consultarAnexos(idDocumentoGED: string, idAnexoOcorrencia: number): Observable<any> {
    return this.http.get(`${this.url}/consultar-anexos/${idDocumentoGED}/${idAnexoOcorrencia}`, this.options());
  }

  private downloadOptions() {
    return this.options({ observe: 'response' as 'body', responseType: 'blob' });
  }

  emitDadosArquivoGedSubject(arquivoGedUpload: ArquivoGedUpload) {
    this.dadosArquivosGedSubject$.next(arquivoGedUpload);
  }

}
