import {CustomTableCol} from './custom-table-col';
import {CustomTableRow} from './custom-table-row';

export class CustomTable {
    constructor (
        public id: string,
        public title: string,
        public headers: CustomTableCol[],
        public rows: CustomTableRow[],
        public clazz: any,
        public style: any,
        public showPagination: boolean,
        public numberPage: number,
        public numberItems: number,
        public total: number,
        public listObject: any[]
    ) {}
}
