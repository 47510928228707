import { FiltroPaginado } from 'app/shared/models/filtroPaginado';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {OcorrenciaInterna} from 'app/shared/models/ocorrencia/ocorrencia-interna';
import {CategorizacaoOcorrencia} from "../../models/administracao/parametrizacao/categorizacao-ocorrencia";

@Injectable()
export class OcorrenciaInternaService extends CrudHttpClientService<OcorrenciaInterna> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('ocorrencia/ocorrencia-interna', http);
  }

  public gerarRelatorioOcorrencia(id: number): Observable<any> {
    return this.http.post(this.url + '/gerar-relatorio-ocorrencia', id, this.options(
      { observe: 'response' as 'body', responseType: 'arraybuffer' }));
  }

  public calculaDataVencimento(categorizacao: CategorizacaoOcorrencia): Observable<any> {
    return this.http.post<OcorrenciaInterna>(this.url + '/calculaDataVencimento', categorizacao, this.options());
  }

  public existeOcorrenciasSemAvaliacao(matricula: string): Observable<any> {
    return this.http.get<boolean>(this.url + '/existeOcorrenciasSemAvaliacao/' + matricula, this.options());
  }

  public consultarPorNumeroOcorrencia(identificacaoOcorrencia: number): Observable<any> {
    return this.http.get(this.url + '/numero-ocorrencia/' + identificacaoOcorrencia, this.options());
  }
}
