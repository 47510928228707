import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Empregado} from 'app/shared/models/comum/empregado';
import {of} from 'rxjs';

@Injectable()
export class EmpregadoService extends CrudHttpClientService<any> {
	constructor(protected http: HttpClient) {
		super('comum/empregado', http);
	}

	public getEmpregado(matricula: string): Observable<Empregado> {
		return this.http.get<Empregado>(this.url + '/matricula/' + matricula);
	}

  public getPrestador(matricula: string): Observable<Empregado> {
		return this.http.get<Empregado>(this.url + '/prestador/' + matricula);
	}

	getEmpregadoMock(matricula: string): Observable<Empregado> {
		const lista: Empregado[] = [];
		const empregado1 = new Empregado();
		empregado1.matricula = '1111';
		empregado1.nome = 'Teste 1111';
		lista.push(empregado1);
		const empregado2 = new Empregado();
		empregado2.matricula = '2222';
		empregado2.nome = 'Teste 2222';
		lista.push(empregado2);

		return of(lista.filter((x) => x.matricula + '' === matricula)[0]);
	}
}
