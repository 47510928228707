import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Tarefa} from 'app/shared/models/ocorrencia/tarefa';
import {TarefaResumida} from 'app/shared/models/ocorrencia/tarefa-resumida';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {take} from 'rxjs/operators';
import {AtribuirOcorrenciaExterna} from "../../models/tratar-ocorrencia/atribuir-ocorrencia-externa";
import {SelectItemModel} from "../../models/select-item";

@Injectable()
export class TarefaService extends CrudHttpClientService<Tarefa> {

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super('ocorrencia/tarefa', http);
    }

    public getVinculados(idOcorrencia: number) {
        return this.http.get(`${this.url}/vinculos/${idOcorrencia}`);
    }

    public getVinculadosTerceirizada(idOcorrencia: number, idTerceirizada:number) {
        return this.http.get(`${this.url}/vinculos-terceirizada/${idOcorrencia}/${idTerceirizada}`);
    }

    public getVinculadosTerceirizadaGestor(idOcorrencia: number) {
        return this.http.get(`${this.url}/vinculos-terceirizada-gestor/${idOcorrencia}`);
    }

    public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/consultar-paginado', filtro, this.options()).pipe(take(1));
    }

    public consultarPaginadoConsoleTratarOuv(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/consultar-paginado-console-tratar-ouv', filtro, this.options());
    }

    public consultarQuantidadeTarefasPorSituacao(filtro: Tarefa): Observable<any> {
        return this.http.post(this.url + '/consultar-quantidade-tarefas-situacao', filtro, this.options());
    }

    public consultarQuantideReclamcaoOcorrencia(filtro: Tarefa): Observable<any> {
        return this.http.post(this.url + '/consultar-quantidade-ocorrencia-reclamacao', filtro, this.options());
    }

    public consultarResumidaPorId(id: number): Observable<TarefaResumida> {
        return this.http.get<TarefaResumida>(this.url + '/resumida/' + id, this.options());
    }

    public excluir(id: number) {
        return this.http.delete(this.url + '/' + id, this.options());
    }

    consultarProdutosDasTarefa(filtro: Tarefa): Observable<any> {
        return this.http.post(`${this.url}/consultar-quantidade-tarefas-produtos`, filtro, this.options());
    }

    public consultarClassificacoesPorTarefa(id: number): Observable<any> {
        return this.http.get<TarefaResumida>(this.url + '/classificacao-tarefa/' + id, this.options());
    }

    public atribuirTarefaTerceirizada(usuarioTarefa: number, tarefaId: number): Observable<any> {
        return this.http.post(this.url + '/distribuir-tarefa/'+usuarioTarefa+'/'+tarefaId, null, this.options());
    }

    desatribuirTarefaTerceirizada(tarefaId: number) {
        return this.http.post(this.url + '/desatribuir-tarefa/'+tarefaId, null, this.options());
    }

    public consultarResumidaPorOcorrencia(id: number): Observable<TarefaResumida[]> {
        return this.http.get<TarefaResumida[]>(this.url + '/resumida-por-ocorrencia/' + id, this.options());
    }

    atribuir(atribuir: AtribuirOcorrenciaExterna) {
        return this.http.post<boolean>(this.url + '/atribuir-ocorrencia-usuario/', atribuir, this.options());
    }

    consultarUsuarioAtuando(filtro: FiltroPaginado): Observable<SelectItemModel[]>{
        return this.http.post<SelectItemModel[]>(this.url + '/consultar-usuarios-tratamento', filtro, this.options());
    }
}
