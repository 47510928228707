import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {ParametroAvaliacao} from 'app/shared/models/administracao/parametrizacao/parametro-avaliacao';
import {TipoRelatorio} from 'app/arquitetura/shared/reports/tipo-relatorio';
import {RelatorioComFiltro} from 'app/shared/models/relatorio-com-filtro';

@Injectable()
export class ParametroAvaliacaoService extends CrudHttpClientService<ParametroAvaliacao> {
  constructor(
    protected http: HttpClient,
    private messageService: MessageService
  ) {
    super('administracao/parametrizacao/parametro-avaliacao', http);
  }

  public gerarRelatorioHistorico(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
    const relatorioComFiltro = new RelatorioComFiltro();
    relatorioComFiltro.tipo = TipoRelatorio[tipo];
    relatorioComFiltro.filtro = filtro;

    return this.http.post(this.url + '/gerar-relatorio-historico', relatorioComFiltro, this.options(
      { observe: 'response' as 'body', responseType: 'arraybuffer' }));
  }

  public consultarPaginadoHistorico(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado-historico', filtro, this.options());
  }
}
