import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'flagSimNao' })
export class FlagSimNaoPipe implements PipeTransform {
	
	transform(value: string) {
		let strReturn = 'Sim';
		if (value === 'N' || !value) {
			strReturn = 'Não';
		}
		return strReturn;
	}
}
