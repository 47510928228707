import {JwtHelperService} from '@auth0/angular-jwt';

import {JwtTokenClaims} from "./jwt-token-claims";

export class JwtToken {
	public token: string = null;
	public recursos: string[] = null;
	public tempoMinimoRenovacao: number = null;
	
	getClaims(jwtHelperService: JwtHelperService): JwtTokenClaims {
		return new JwtTokenClaims(jwtHelperService.decodeToken(this.token));
	}
}
