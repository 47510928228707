import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {EquipeOcorrencia} from 'app/shared/models/ocorrencia/equipe-ocorrencia';
import {Observable} from 'rxjs';
import {Equipe} from '../../models/administracao/parametrizacao/equipe';
import {HistoricoConsoleGestor} from '../../models/ocorrencia/historico-console-gestor';
import {MessageService} from 'app/shared/components/messages/message.service';

@Injectable()
export class OcorrenciaGestorTempoMedioService extends CrudHttpClientService<EquipeOcorrencia> {

  constructor(
      protected http: HttpClient,
      protected messageService: MessageService) {
    super('ocorrencia/tempo-medio', http);
  }

  consultarEquipePorGestor(): Observable<Equipe[]> {
    return this.http.post<Equipe[]>(this.url + '/buscar-equipe-por-gestor',
            null, this.options());
  }

  atualizarTempoMedio(formulario: any) {
    return this.http.post(this.url + '/atualizar-tempo-medio', formulario, this.options());
  }

  consultarHistorico(): Observable<HistoricoConsoleGestor[]> {
    return this.http.post<HistoricoConsoleGestor[]>(this.url + '/historico', null, this.options());
  }
}
