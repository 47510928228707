import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {AvaliacaoNatureza} from 'app/shared/models/administracao/parametrizacao/avaliacao-natureza';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {SelectItemModel} from 'app/shared/models/select-item';
import {Observable} from 'rxjs';

@Injectable()
export class AvaliacaoNaturezaService extends CrudHttpClientService<AvaliacaoNatureza> {

  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/parametrizacao/avaliacao-natureza', http);
  }

  public deleteLote(itensSelecionados: AvaliacaoNatureza[]) {
    return this.http.post(this.url + '/excluir-lote', itensSelecionados, this.options());
  }

  public alterarSituacaoLote(itensSelecionados: AvaliacaoNatureza[]): Observable<any> {
    return this.http.put(this.url + '/ativar-inativar-lote', itensSelecionados, this.options());
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public searchAutocomplete(value: string): Observable<any> {
    return this.http.post(this.url + '/search-autocomplete/', value, this.options());
  }

  public ativarInativar(avaliacaoNatureza: AvaliacaoNatureza): Observable<AvaliacaoNatureza> {
    return this.http.put<AvaliacaoNatureza>(this.url + "/ativar-inativar", avaliacaoNatureza, this.options());
  }

  public consultarSelectItens(perfis: string[]): Observable<any> {
		return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis', perfis, this.options());
  }
  
}
