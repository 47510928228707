import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {ptBrLocale} from 'ngx-bootstrap/locale';
import {Base} from '../base';
import {MessageService} from './../messages/message.service';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.css']
})
export class InputDateComponent extends Base implements OnInit {
  @Input() label: string;
  @Input() control: string;
  @Input() form: FormGroup;
  @Input() placeholder: string;
  @Input() required = false;

  bsConfig = {
    containerClass: 'theme-dark-blue',
    showWeekNumbers: false
  };

  constructor(
    private localeService: BsLocaleService,
    protected messageService: MessageService
  ) {
    super(messageService);
    defineLocale('pt-br', ptBrLocale);
    this.localeService.use('pt-br');
  }

  ngOnInit() {
    this.form.get(this.control).valueChanges.subscribe(value => {
      if (value && value.toString().toUpperCase() === 'INVALID DATE') {
        this.form.get(this.control).setValue(null);
      }
    });
  }
}
