import {AvaliacaoOcorrenciaExternaNovo} from './../../models/ocorrencia/avaliacao-ocorrencia-externa-novo';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {OcorrenciaExterna} from 'app/shared/models/ocorrencia/ocorrencia-externa';
import {Observable} from 'rxjs/Rx';
import {FiltroPaginado} from '../../models/filtroPaginado';
import {TipoRelatorio} from '../../../arquitetura/shared/reports/tipo-relatorio';
import {RelatorioComFiltro} from '../../models/relatorio-com-filtro';
import {PainelControleSacTransferencia} from '../../models/ocorrencia/painel-controle-sac-transferencia';
import {PerguntaAvaliacao} from "../../models/ocorrencia/pergunta-avaliacao";
import {Equipe} from "../../models/administracao/parametrizacao/equipe";

@Injectable()
export class PainelControleSacService extends CrudHttpClientService<OcorrenciaExterna> {

    constructor(protected http: HttpClient, private messageService: MessageService) {
        super('ocorrencia/ocorrencia-sac/painel-controle', http);
    }

    public gerarPainelEquipe(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/equipe/gerar', filtro, this.options());
    }

    public detalharPaginadoResponsavel(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/responsavel/detalhar-paginado', filtro, this.options());
    }

    public detalharPaginadoEquipe(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/equipe/detalhar-paginado', filtro, this.options());
    }

    public detalharPaginadoEmpregado(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/empregado/detalhar-paginado', filtro, this.options());
    }

    public transferir(id: number, transferencia: PainelControleSacTransferencia) {
        return this.http.post(this.url + '/' + id + '/transferir', transferencia, this.options());
    }

    public transferirGrupo(id: number, transferencia: PainelControleSacTransferencia) {
        return this.http.post(this.url + '/' + id + '/transferir-grupo', transferencia, this.options());
    }

    consultarEquipeResponsavelOcorrencia(idOcorrencia: number) : Observable<Equipe[]>{
		return this.http.get<Equipe[]>(`${this.url}/equipe-responsavel-ocorrencia/${idOcorrencia}`);
	}

    public gerarRelatorioDetalheResponsavel(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
        const relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
        relatorioComFiltro.tipo = TipoRelatorio[tipo];
        relatorioComFiltro.filtro = filtro;
        return this.http.post(this.url + '/responsavel/gerar-relatorio-detalhe', relatorioComFiltro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
    }

    public gerarRelatorioDetalheEquipe(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
        const relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
        relatorioComFiltro.tipo = TipoRelatorio[tipo];
        relatorioComFiltro.filtro = filtro;
        return this.http.post(this.url + '/equipe/gerar-relatorio-detalhe', relatorioComFiltro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
    }

    consultarPerguntaAvaliacao(id: number):Observable<PerguntaAvaliacao[]> {
        return this.http.get<PerguntaAvaliacao[]>(`${this.url}/consultar-pergunta-avaliacao/${id}`);
    }

    consultarRespostasAvaliacao(id: number):Observable<AvaliacaoOcorrenciaExternaNovo[]> {
      return this.http.get<AvaliacaoOcorrenciaExternaNovo[]>(`${this.url}/consultar-resposta-avaliacao/${id}`, this.options());
    }


}
