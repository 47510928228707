import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {Item} from 'app/shared/models/administracao/parametrizacao/item';
import {OrientacaoAtendimento} from 'app/shared/models/administracao/parametrizacao/orientacao-atendimento';
import {Observable} from 'rxjs';
import {SelectItemModel} from 'app/shared/models/select-item';

@Injectable()
export class OrientacaoAtendimentoService extends CrudHttpClientService<OrientacaoAtendimento> {

  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/parametrizacao/orientacao-atendimento', http);
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

  public ativarInativar(orientacaoAtendimento: OrientacaoAtendimento): Observable<any> {
    return this.http.put<Item>(this.url + "/ativar-inativar", orientacaoAtendimento, this.options());
  }

  public consultarSelectItens(idTipoOcorrencia:number): Observable<any> {
		return this.http.get<SelectItemModel>(this.url + '/select-itens-perfis/' + idTipoOcorrencia, this.options());
  }

  public consultarOrientacaoAtivasPorTipoMovimentacao(idMovimentacao: number): Observable<any> {
		return this.http.get(this.url + '/ativas-por-movimentacao/' + idMovimentacao, this.options());
	}

  public consultarOrientacaoAtivasPorTipoSolicitacao(idTipoSolicitacao: number): Observable<any> {
		return this.http.get(this.url + '/ativas-por-tipo-solicitacao/' + idTipoSolicitacao, this.options());
	}

  public alterarSituacaoLote(orientacaoAtendimento: OrientacaoAtendimento[]) {
    return this.http.put(this.url + '/ativar-inativar-lote', orientacaoAtendimento, this.options());
  }

  public deleteLote(orientacaoAtendimento: OrientacaoAtendimento[]) {
    return this.http.post(this.url + '/excluir-lote', orientacaoAtendimento, this.options());
  }
}
