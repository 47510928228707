import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'ativo' })
export class AtivoPipe implements PipeTransform {
	transform(value: string) {

		if (value == "S") {
			return "Ativo";
		} 
		if (value == "N") {
			return "Inativo";
		}

		if (value == "P") {
			return "Pendente";
		}

		return value;
	}
}
