import {Directive} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[formControlName][cpfCnpjMask]',
})
export class CpfCnpjMaskDirective {

  constructor(public ngControl: NgControl) { 
  }

  ngOnInit(): void {
    const originalWriteVal = this.ngControl.valueAccessor.writeValue.bind(this.ngControl.valueAccessor);
    this.ngControl.valueAccessor.writeValue = (val: any) => originalWriteVal(this._maskValue(val));

    const originalChange = (<any>this.ngControl.valueAccessor)['onChange'].bind(this.ngControl.valueAccessor);
    this.ngControl.valueAccessor.registerOnChange((val: any) => originalChange(this._unmaskValue(val)));

    this._setVal(this._maskValue(this.ngControl.value));
  }

  private _setVal(val: string) {
    if (this.ngControl.control) {
      this.ngControl.control.setValue(val, { emitEvent: false });
    }
  }

  private _maskValue(val: any): string {

    if (val) {
      let newVal:string = val;

      if (!isNaN(val)) {
        newVal = val.toString();
      }

      newVal = newVal.replace(/\D/g, '');

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal;
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1.$2');
    } else if (newVal.length <= 9) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1.$2.$3');
    } else if (newVal.length <= 11) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
    } else if (newVal.length <= 12) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1.$2.$3/$4');
    } else if (newVal.length <= 14) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/, '$1.$2.$3/$4-$5');
    } else {
      newVal = newVal.substring(0, 14);
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/, '$1.$2.$3/$4-$5');
    }

      return newVal;
    }
    return val;
  }

  private _unmaskValue(val: string): string {
    const maskedVal = this._maskValue(val);
    const unmaskedVal = this.unmaskValue(maskedVal);

    if (maskedVal !== val) {
      this._setVal(maskedVal);
    }

    return maskedVal ? unmaskedVal : '';
  }

  private unmaskValue(value: string): string {
    if (value) {
      return value.replace(/\D/g, '');;
    }
    return undefined;
  }
}
