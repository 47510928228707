import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {MovimentacaoOcorrenciaOuv} from 'app/shared/models/ocorrencia/movimentacao-ocorrencia-ouv';
import {SelectItemModel} from 'app/shared/models/select-item';
import {EquipeOcorrenciaService} from 'app/shared/services/ocorrencia/equipe-ocorrencia.service';
import {MovimentacaoOcorrenciaService} from 'app/shared/services/ocorrencia/movimentacao-ocorrencia.service';
import {SituacaoOcorrenciaService} from 'app/shared/services/ocorrencia/situacao-ocorrencia.service';
import {BasePageable} from '../base-pageable';
import {MessageService} from '../messages/message.service';

@Component({
    selector: 'app-modal-historico-ocorrencia',
    templateUrl: './modal-historico-ocorrencia.component.html',
    styleUrls: ['./modal-historico-ocorrencia.component.scss']
})
export class ModalHistoricoOcorrenciaComponent extends BasePageable implements OnInit {
    display: boolean = false;
    idOcorrencia: any;
    tipoOcorrencia: any;
    usuarios: SelectItemModel[];
    tipos: SelectItemModel[];
    formulario: FormGroup;
    listaMovimentacaoOcorrencia: MovimentacaoOcorrenciaOuv[];


    cols: any[] = [
        {field: 'origem', header: 'Origem'},
        {field: 'nuOcorrencia', header: 'Nº Ocorrência'},
        {field: 'nuMatricula', header: 'Usuário'},
        {field: 'data', header: 'Data/Hora'},
        {field: 'acao', header: 'Ação'},
        {field: 'descricao', header: 'Descrição'}
    ];

    public pt = {
        firstDayOfWeek: 0,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Out', 'Nov', 'Dez'],
        today: 'Hoje',
        clear: 'Limpar'
    };
    formFiltroContestacao: FormGroup;


    constructor(private formBuilder: FormBuilder,
                protected messageService: MessageService,
                private movimentacaoOcorrenciaService: MovimentacaoOcorrenciaService,
                private situacaoOcorrenciaService: SituacaoOcorrenciaService,
                private equipeOcorrenciaService: EquipeOcorrenciaService,
                private sessaoService: SessaoService,
    ) {
        super(messageService);
    }

    ngOnInit() {
        this.carregarFormFiltroContestacao();
        this.carregarUsuarios();
        this.carregarTipos();
    }

    carregarFormFiltroContestacao(): void {
        this.formulario = this.formBuilder.group({
            nuOcorrenciaExterna: [''],
            identificacaoOcorrencia: [''],
            nuTipoMovimentacao: [''],
            dataCadastroMovimentacao: [''],
            dataFimMovimentacao: [''],
            nuMatriculaResponsavel: [''],
        });
    }

    consultarPaginado(): void {
        const filtro: FiltroPaginado = this.getFiltroPaginado(this.formulario.getRawValue());
        filtro.filtro.nuOcorrenciaExterna = this.idOcorrencia;
        filtro.filtro.nuMatriculaResponsavel = this.formulario.get('nuMatriculaResponsavel').value ? this.formulario.get('nuMatriculaResponsavel').value.value : '';
        filtro.filtro.nuTipoMovimentacao = this.formulario.get('nuTipoMovimentacao').value ? this.formulario.get('nuTipoMovimentacao').value.value : null;
        filtro.filtro.nuTipoOcorrencia = this.tipoOcorrencia
        this.searchPageble(this.movimentacaoOcorrenciaService.consultarHistoricoPaginado(filtro)).subscribe(
            (resposta: MovimentacaoOcorrenciaOuv[]) => {

                this.listaMovimentacaoOcorrencia = resposta;
                if(!this.listaMovimentacaoOcorrencia.length) {
                  this.listaMovimentacaoOcorrencia = [];
                  this.messageService.addMsgDanger(this.mensagem.MN006);
                }

            }, err => {
                this.listaMovimentacaoOcorrencia = [];
                this.messageService.addMsgDanger(err.error);
            }
        );


        // this.buscarHistorico(this.idOcorrencia);
    }

    public show(idOcorrencia, nuTipoOcorrencia?): void {
        this.idOcorrencia = idOcorrencia;
        this.tipoOcorrencia = nuTipoOcorrencia
        this.display = true;
        this.listaMovimentacaoOcorrencia = [];
        this.carregarFormFiltroContestacao();
        this.consultarPaginado();
    }


    //71120000002
    buscarHistorico(idOcorrencia: number) {
        if (this.idOcorrencia) {
            this.movimentacaoOcorrenciaService.consultarPorOcorrencia(idOcorrencia).subscribe(
                (values) => {

                    this.listaMovimentacaoOcorrencia = values;
                    console.log(values);

                },
                error => {
                    this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar a movimentação da ocorrência.');
                    console.log('Erro ao consultar a movimentação da ocorrência:', error);
                }
            );
        }
    }


    carregarUsuarios(): void {
        const equipeUserSession = this.sessaoService.getEquipeUsuario();
        if (equipeUserSession) {
            this.equipeOcorrenciaService.consultarUsuariosTratamento(equipeUserSession.idEquipe).subscribe(resultado => {
                    this.usuarios = resultado;

                    const selecione = new SelectItemModel();
                    selecione.label = 'Selecione';
                    this.usuarios.unshift(selecione);
                },
                error => {
                    this.messageService.addMsgDanger(error.error);
                });

        }
    }

    carregarTipos(): void {
        this.movimentacaoOcorrenciaService.consultarTipos()
            .subscribe(tipos => {
                    this.tipos = tipos;

                    const selecione = new SelectItemModel();
                    selecione.label = 'Selecione';
                    this.tipos.unshift(selecione);
                },
                error => {
                    this.messageService.addMsgDanger(error.error);
                });
    }

    numberOnly(event): void {
      const valor = event.target.value.replace(/\D/g, '')
      this.formulario.controls['identificacaoOcorrencia'].setValue(valor);
    }
}
