import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {Produto} from 'app/shared/models/administracao/parametrizacao/produto';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {SelectItemModel} from 'app/shared/models/select-item';

@Injectable()
export class ProdutoService extends CrudHttpClientService<Produto> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('administracao/parametrizacao/produto', http);
  }

  public consultarTodos(): Observable<any> {
    return this.http.get<any>(this.url + '/todos', this.options());
  }

  consultar(nome: string, situacao: string): any {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('noProduto', nome);
    httpParams = httpParams.set('icSituacao', situacao ? situacao : '');

    return this.http.get<Produto[]>(
        this.url + '/consultar',
        this.options({params: httpParams})
    );
  }

  public carregarProdutos(): any {
    return this.http.get<Produto[]>(this.url);
  }

  searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
  }

  public callListarProdutosPorNatureza(idNatureza: number, next?: (value: any) => void): void {
    this.callGET<Produto[]>('/itens/ativos/natureza/' + idNatureza, next, error => {
      this.messageService.addMsgDanger('Ocorreu um erro ao pesquisar produtos.');
      console.log('Erro ao consultar produtos:' + error);
    });
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public todosAtivos(idTipoOcorrencia: number): any {
    return this.http.get(this.url + '/consultar-ativos/' + idTipoOcorrencia, this.options());
  }

  public produtosTercerizada(idTipoOcorrencia: number): any {
    return this.http.get(this.url + '/consultar-tercerizadas/' + idTipoOcorrencia, this.options());
  }

  public consultarSelectItens(idTipoOcorrencia: number): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-itens-perfis/', idTipoOcorrencia, this.options());
  }

  public alterarSituacao(produto: Produto): Observable<any> {
    return this.http.put(this.url + '/ativar-inativar', produto, this.options());
  }
}
