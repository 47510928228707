import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-barra-fixa-footer',
  templateUrl: './barra-fixa-footer.component.html',
  styleUrls: ['./barra-fixa-footer.component.scss']
})
export class BarraFixaFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
