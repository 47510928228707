import {HttpClient, HttpParams} from '@angular/common/http';
import {Entity} from 'app/arquitetura/shared/models/entity';
import {TipoRelatorio} from 'app/arquitetura/shared/reports/tipo-relatorio';
import {CategorizacaoOcorrencia} from 'app/shared/models/administracao/parametrizacao/categorizacao-ocorrencia';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {RelatorioComFiltro} from 'app/shared/models/relatorio-com-filtro';
import {Observable} from 'rxjs/Observable';
import {HttpClientService} from './http-client.service';


export class CrudHttpClientService<T extends Entity> extends HttpClientService {
	constructor(public uri: string, protected http: HttpClient) {
		super(uri, http);
	}

	/**
	   * Recupera um registro em particular, ou todos (caso não seja passado
	   * o parâmetro id)
	   * @param id
	   */
	public get(id?: number): Observable<T | T[]> {
		let url = this.url;
		if (id) {
			url += '/' + id;
		}
		return this.http.get<T>(url, this.options());
	}

	/**
	   * Insere um registro
	   * @param entity
	   */
	public post(entity: T): Observable<T> {
		return this.http.post<T>(this.url, entity, this.options());
	}

	/**
	   * Altera um registro
	   * @param entity
	   */
	public put(entity: T): Observable<T> {
		return this.http.put<T>(this.url, entity, this.options());
	}

	/**
	   * Exclui um registro
	   * @param id
	   */
	public delete(id: any): Observable<boolean> {
		return this.http.delete<boolean>(this.url + '/' + id, this.options());
	}

	/**
	 * Exclui um registro
	 * @param id
	 */
	public deleteCategorizacao(id: any, origem: any): Observable<boolean> {
		return this.http.delete<boolean>(this.url + '/' + id + '/' + origem, this.options());
	}

	/**
	   * Consulta parametrizada
	   */
	protected consultarPorParametro(endpoint: string, paramName: string, param: any): Observable<T[]> {
		const httpParams: HttpParams = new HttpParams().set(paramName, param);
		return this.http.get<T[]>(this.url + '/' + endpoint, this.options({ params: httpParams }));
	}

	protected callGET<O>(endpoint: string, next: (value: any) => void, error?: (value: any) => void): any {
		return this.http.get<O>(this.url + endpoint, this.options()).subscribe(next, error);
	}

	protected callPOST<O>(endpoint: string, object: any, next: (value: any) => void, error?: (value: any) => void): any {
		return this.http.post<O>(this.url + endpoint, object, this.options()).subscribe(next, error);
	}

	/**
	 * Consultar com paginação
	 */
	public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
		return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
	}

	public consultarPaginadoFormaRecebimentoResposta(filtro: FiltroPaginado): Observable<any> {
		return this.http.post(this.url + '/consultar-paginado-vinculo', filtro, this.options());
	}

	/**
 * Consultar com paginação aba cenário Estoque e ocorrências
 */
	public consultarPaginadoOcorrenciaSupervisor(filtro: FiltroPaginado): Observable<any> {
		return this.http.post(this.url + '/consultar-paginado-ocorrencia-supervisor', filtro, this.options());
	}

	/**
	 * Consultar dificuldade ocorrencias da equipe
	 */
		 public consultarEtapaEquipe(idEquipe): Observable<any> {
			return this.http.get(this.url + '/consulta-etapa-equipe/'+idEquipe, this.options());
		}

	/**
	 * Consultar dificuldade ocorrencias da equipe
	 */
		 public consultarDificuldadeOcorrencia(idEquipe): Observable<any> {
			return this.http.get(this.url + '/consulta-dificuldade-ocorrencia-equipe/'+idEquipe, this.options());
		}

	/**
	 * Gerar relatório
	 */
	public gerarRelatorio(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
		let relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
		relatorioComFiltro.tipo = TipoRelatorio[tipo];
		relatorioComFiltro.filtro = filtro;

		return this.http.post(this.url + '/gerar-relatorio', relatorioComFiltro, this.options(
			{ observe: 'response' as 'body', responseType: 'arraybuffer' }));
	}

	/**
 * Gerar relatório Selecionadas
 */
	public gerarRelatorioSelecionados(tipo: TipoRelatorio, filtro: FiltroPaginado, categorizacaoOcorrencia: CategorizacaoOcorrencia[]): Observable<any> {
		let relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
		relatorioComFiltro.tipo = TipoRelatorio[tipo];
		relatorioComFiltro.filtro = filtro;
		console.log(categorizacaoOcorrencia);
		relatorioComFiltro.filtro.filtro.idSelecionadas = categorizacaoOcorrencia;

		return this.http.post(this.url + '/gerar-relatorio-selecionadas', relatorioComFiltro, this.options(
			{ observe: 'response' as 'body', responseType: 'arraybuffer' }));
	}
}
