import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'campoVazio' })
export class CampoVazioPipe implements PipeTransform {
	transform(value: string) {
		let strReturn = 'Não informado';

		if (value) {
			strReturn = value;
		}

		return strReturn;
	}
}
