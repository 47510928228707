import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'tipoMovimentacao' })
export class TipoMovimentacaoPipe implements PipeTransform {
	transform(value: number) {

		if (value == 1)  { return "Inclusão"; }
		if (value == 179)  { return "Consulta"; }
		if (value == 2)  { return "Resposta"; }
		if (value == 3)  { return "Pendência"; }
		if (value == 4)  { return "Reabertura"; }
		if (value == 5)  { return "Transferência"; }
		if (value == 6)  { return "Cancelamento"; }
		if (value == 7)  { return "Alteração"; }
		if (value == 8)  { return "Avaliação"; }
		if (value == 9)  { return "Solicitação"; }
		if (value == 10) { return "Prorrogação"; }
		if (value == 11)  { return "Recategorização"; }
		if (value == 12)  { return "Resposta da Reabertura"; }
		return value;
	}
}
