import {Injectable} from '@angular/core';
import {HttpClientService} from "../../../arquitetura/shared/services/http-client.service";
import {HttpClient} from "@angular/common/http";
import {EquipeUsuario} from "../../../arquitetura/shared/models/cadastrobasico/equipe-usuario";
import {UnidadeTerceirizada} from "../../models/comum/unidade-terceirizada";

@Injectable({ providedIn: 'root' })
export class PainelEquipeOrigemService extends HttpClientService {
  constructor(protected http: HttpClient) {
    super('painel-equipe-origem', http);
  }

  consultarPainelAtendente(origem: string, equipe: EquipeUsuario) {
    return this.http.get(`${this.url}/${origem}/${equipe.idEquipe}/${equipe.origem.id}`, this.options());
  }

  consultarPainelAtendenteV2(origem: string, equipe: EquipeUsuario) {
    return this.http.get(`${this.url}/${origem}/v2/${equipe.idEquipe}`, this.options());
  }

  consultarOrigemPorEquipe(idGrupo: number) {
    return this.http.get(this.url + '/equipe/' + idGrupo, this.options());
  }

  consultarPainelSupervisor(origem: string, equipe: EquipeUsuario) {
    return this.http.get(`${this.url}/supervisor/${origem}/${equipe.origem.id}`, this.options());
  }

  consultarPainelSupervisorV2(origem: string, equipe: EquipeUsuario) {
    return this.http.get(`${this.url}/supervisor/v2/${origem}/${equipe.idEquipe}`, this.options());
  }

  consultarPainelTerceirizado(unidade:UnidadeTerceirizada) {
    return this.http.post(this.url + '/terceirizado', unidade, this.options());
  }

}
