import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {AvaliacaoOcorrencia} from 'app/shared/models/ocorrencia/avaliacao-ocorrencia';
import {Observable} from 'rxjs';

@Injectable()
export class AvaliacaoOcorrenciaService extends CrudHttpClientService<AvaliacaoOcorrencia> {
  constructor(protected http: HttpClient) {
    super('ocorrencia/avaliacao', http);
  }

  public salvar(value: any): Observable<any> {
    return this.http.post(this.url, value, this.options());
  }
}
