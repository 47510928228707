import {CommonModule} from "@angular/common";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {BreadcrumbModule, DialogModule, PaginatorModule, PanelMenuModule, SidebarModule,} from "primeng/primeng";

// Aplicação
import {FormsModule} from "@angular/forms";
import {ComponenteModule} from "app/shared/components/componente.module";
import {PipeModule} from "app/shared/pipes/pipe.module";
import {NgxMaskModule} from "ngx-mask";
import {DropdownModule} from "primeng/dropdown";
import {SplitButtonModule} from "primeng/splitbutton";
import {TableModule} from "primeng/table";
import {LoadingModule} from "../../../shared/components/loading/loading.module";
import {LeftPadPipe} from "../../../shared/pipes/left-pad.pipe";
import {CabecalhoPadraoComponent} from "./cabecalho-padrao/cabecalho-padrao.component";
import {RodapePadraoComponent} from "./rodape-padrao/rodape-padrao.component";
import {PaginaNaoEncontradaComponent} from "./pagina-nao-encontrada/pagina-nao-encontrada.component";
import {MenuLateralComponent} from "./menu-lateral/menu-lateral.component";
import {AguardarAtualizacaoPaginaComponent} from "./aguardar-atualizacao-pagina/aguardar-atualizacao-pagina.component";
import {OcorrenciaSigilosaComponent} from "./ocorrencia-sigilosa/ocorrencia-sigilosa.component";


/**
 * Modulo Acesso
 **/
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PanelMenuModule,
    SidebarModule,
    BreadcrumbModule,
    FormsModule,
    PipeModule,
    DropdownModule,
    TableModule,
    SplitButtonModule,
    ComponenteModule,
    PaginatorModule,
    DialogModule,
    NgxMaskModule.forChild(),
    LoadingModule,
  ],
  declarations: [
    CabecalhoPadraoComponent,
    RodapePadraoComponent,
    PaginaNaoEncontradaComponent,
    OcorrenciaSigilosaComponent,
    MenuLateralComponent,
    AguardarAtualizacaoPaginaComponent,
  ],
  exports: [
    CabecalhoPadraoComponent,
    RodapePadraoComponent,
    PaginaNaoEncontradaComponent,
    OcorrenciaSigilosaComponent,
    MenuLateralComponent,
    AguardarAtualizacaoPaginaComponent,
  ],
  entryComponents: [
    CabecalhoPadraoComponent,
    RodapePadraoComponent,
    PaginaNaoEncontradaComponent,
    OcorrenciaSigilosaComponent,
    MenuLateralComponent,
    AguardarAtualizacaoPaginaComponent,
  ],
  providers: [LeftPadPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TemplatesModule {}
