import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {StoredPaginationUtil} from 'app/shared/util/stored-pagination-util';
import {MenuItem} from 'primeng/primeng';
import {Subject} from 'rxjs/Rx';
import {filter, takeUntil} from 'rxjs/operators';
import {PermissaoService} from '../../services/seguranca/permissao.service';
import {SessaoService} from '../../services/seguranca/sessao.service';
import {MenuLateralService} from '../../services/menu-lateral.service';
import {ItensMenuPadrao} from '../itens-menu/itens-menu-padrao';
import {ItensMenuVivaVoz} from '../itens-menu/itens-menu-vivavoz';
import {ItensMenuSac} from '../itens-menu/itens-menu-sac';
import {ItensMenuOuvidoria} from '../itens-menu/itens-menu-ouvidoria';
import {ItensMenuRelatorios} from "../itens-menu/itens-menu-relatorios";


@Component({
  selector: "app-menu-lateral",
  templateUrl: "./menu-lateral.component.html",
  styleUrls: ["./menu-lateral.component.scss"],
})
export class MenuLateralComponent implements OnInit, OnDestroy {
  public show: boolean;
  public currentClass: string;
  public wrapperClass: string;
  public display = false;
  public itemsMenu: MenuItem[];
  private url: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public permissaoService: PermissaoService,
    public sessaoService: SessaoService,
    public menuLateralService: MenuLateralService,
    private router: Router
  ) {
    this.url = "";

    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.url = event.url;

        this.mostrarMenu();
      });
  }

  ngOnInit() {
    this.menuLateralService.menuLateralReload$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => this.load());
  }

  load() {
    this.itemsMenu = [];

    new ItensMenuPadrao(this, this.sessaoService).itens.forEach(
      (itemMenuPadrao) => {
        this.itemsMenu.push(itemMenuPadrao);
      }
    );
    new ItensMenuVivaVoz(this, this.sessaoService).itens.forEach(
      (itemMenuVivaVoz) => {
        this.itemsMenu.push(itemMenuVivaVoz);
      }
    );
    new ItensMenuSac(this, this.sessaoService).itens.forEach((itemMenuSac) => {
      this.itemsMenu.push(itemMenuSac);
    });
    new ItensMenuOuvidoria(this, this.sessaoService).itens.forEach(
      (itemMenuOuv) => {
        this.itemsMenu.push(itemMenuOuv);
      }
    );
    new ItensMenuRelatorios(this).itens.forEach(
        (itemMenuRelatorios) => {
          this.itemsMenu.push(itemMenuRelatorios);
        }
    );

    this.mostrarMenu();
  }

  mostrarMenu() {
    if (
      this.sessaoService.isPerfilUsuarioSelecionado() &&
      (this.url == "/" || this.url == "/home")
    ) {
      this.display = false;
    }
  }

  itemMenuClick(idTipoOcorrencia: number) {
    SessaoService.usuario.idTipoOcorrencia = idTipoOcorrencia;
    this.sessaoService.getUsuario().idTipoOcorrencia = idTipoOcorrencia;
    this.sessaoService.gravarUsuarioStorage(this.sessaoService.getUsuario());

    this.display = false;
    StoredPaginationUtil.clearStoredPagination();
    localStorage.removeItem("SISOU_PAINEL_UNIDADES");
    localStorage.removeItem("SISOU_PAINEL_PESQUISA");
    localStorage.removeItem("SISOU_PAINEL_DIAS");
    localStorage.removeItem("SISOU_JUSTIFICAIVA_CONSOLE_VIVA_VOZ");
    localStorage.removeItem("SISOU_SITUACAO_CONSOLE_VIVA_VOZ");
  }

  ngOnDestroy() {
    // Realiza o unsubscribe das Subscriptions realizadas.
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
