import {MessageService} from 'app/shared/components/messages/message.service';
import {Base} from 'app/shared/components/base';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {Util} from 'app/arquitetura/shared/util/util';

@Component({
  selector: 'app-many-checkbox',
  templateUrl: './many-checkbox.component.html',
  styleUrls: ['./many-checkbox.component.css']
})
export class ManyCheckboxComponent extends Base implements OnInit, OnChanges {

  @Input() form: FormGroup;
  @Input() required = false;
  @Input() list: any[];
  @Input() itemDescription: string;
  @Input() itemValue: any;
  @Input() readonlyProperty: string;
  @Input() name: string;
  @Input() label: string;
  @Input() selected: number[] = [];
  @Input() inline = 'false';
  @Input() grid = false;

  nameList = 'list';

  constructor(protected messageService: MessageService) {
    super(messageService);
  }

  /**
   * Responsável por verificar se a lista foi alterada, para que seja atualizado os checkbox.
   *
   * @param changes
   *
   */
  ngOnChanges(changes: SimpleChanges) {
    const keyChanges = Object.keys(changes);
    if (this.contains(keyChanges, this.nameList)) {
      this.setValueArray(this.form, this.name, this.getFormArray());
    }
  }

  ngOnInit(): void {
    this.form.addControl(
      this.name,
      this.getFormArray()
    );
  }

  minSelectedCheckboxes(min = 1) {
    if (!this.required) {
      return;
    }
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => (next ? prev + next : prev), 0);
      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }

  getFormArray(): FormArray {
    const controls = this.list.map(c => new FormControl({
      value: this.selected.indexOf(c.id) > -1, disabled: c[this.readonlyProperty]
    }));
    return new FormArray(controls, this.minSelectedCheckboxes());
  }

  getControls() {
    const control = this.form.get(this.name);
    if (control) {
      const fa = <FormArray>control;
      return fa.controls;
    } else {
      return [];
    }
  }

  isReadonly(i) {
    if (this.readonlyProperty) {
      return this.list[i][this.readonlyProperty];
    } else {
      return false;
    }
  }

  getDescription(i) {
    return this.list[i][this.itemDescription];
  }

  isValid() {
    const campo: AbstractControl = this.form.get(this.name);
    return (campo.dirty || campo.touched) && campo.invalid;
  }

  isGrid() {
    return Util.isDefined(this.grid) ? this.grid : false;
  }

}
