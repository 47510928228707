import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs';
import {SatisfacaoSta} from './../../../models/ocorrencia/satisfacao-sta';
import {TransmissaoArquivoOcorrencia} from "../../../models/ocorrencia/transmissao-arquivo-ocorrencia";


@Injectable()
export class SatisfacaoStaService extends CrudHttpClientService<SatisfacaoSta> {

    constructor(
        protected http: HttpClient,
        private messageService: MessageService
    ) {
        super('ocorrencia/satisfacao-sta', http);
    }

    public consultar(filtro: any) {
        return this.http.post(this.url + '/consultar', filtro, this.options());
    }

    public enviarManualmente(dto: any) {
        return this.http.post(this.url + '/envio-manual', dto, this.options());
    }

    consultarOcorrenciaPorTransmissao(id: number): Observable<TransmissaoArquivoOcorrencia[]> {
        return this.http.get<TransmissaoArquivoOcorrencia[]>(`${this.url}/detalhar/${id}`);
    }

    public consultarDadosPorId(idTransmissao: any): Observable<SatisfacaoSta> {
        return this.http.get<SatisfacaoSta>(`${this.url}/consultar-transmissao/${idTransmissao}`);
    }
}
