import {NamedValue} from './named-value';
import {FiltroPaginado} from './filtroPaginado';

/** Armazenamento de páginação de consulta filtrada. */
export class StoredPagination {

  private address: string;
  private formValues: NamedValue[];
  private filtroPaginado: FiltroPaginado;

  constructor(address: string,  filtroPaginado: FiltroPaginado, formValues: NamedValue[]) {
    this.address = address;
    this.filtroPaginado = filtroPaginado;
    this.formValues = formValues;
  }

  public getAddress(): string {
    return this.address;
  }

  public getFormValues(): NamedValue[] {
    return this.formValues;
  }

  public getFiltroPaginado(): FiltroPaginado {
    return this.filtroPaginado;
  }

  public static fromJSON(json: string): StoredPagination {
    const object: StoredPagination = JSON.parse(json);
    return new StoredPagination(object.address, object.filtroPaginado, object.formValues);
  }

}
