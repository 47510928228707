import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {TipoMensagemSms} from '../../../models/administracao/parametrizacao/tipo-mensagem-sms';

@Injectable()
export class TipoMensagemSmsService extends CrudHttpClientService<TipoMensagemSms> {
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('sms/tipos-mensagens', http);
  }

}
