import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Rx';
import {Observable, of as ofObservable} from "rxjs";

@Injectable()
export class CabecalhoService {

  private cabecalhoSubject$ = new Subject<any>();

  emiteReloadCabecalho() {
    this.cabecalhoSubject$.next();
  }
  
  getCabecalho(): Observable<any> {
    return this.cabecalhoSubject$.asObservable();
  }

  unsubscribe() {
    this.cabecalhoSubject$.unsubscribe();
  }
  
  unsubscribeAsObservable(): Observable<any> {
    return ofObservable(() => this.cabecalhoSubject$.unsubscribe())
  }

}
