import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';

import {MessageService} from 'app/shared/components/messages/message.service';

import {Observable} from 'rxjs';
import {CausaRaiz} from 'app/shared/models/administracao/especificacoes/causa-raiz';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';

@Injectable()
export class CausaRaizService extends CrudHttpClientService<CausaRaiz> {
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/especificacoes/causa-raiz', http);
  }

  consultar(causaRaiz: CausaRaiz, next?: (itens: CausaRaiz[]) => void, errorCallback?: any): any {
    this.callPOST<CausaRaiz[]>('/consultar', causaRaiz, next, error => {
      const message = errorCallback(error, 'Ocorreu um erro ao pesquisar itens.');
      this.messageService.addMsgDanger(message);
      console.log('Erro ao chamar o servico. Erro: ' + error);
    });
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('value', value);
    httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
    return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
}

  consultarId(next?: (itens: CausaRaiz[]) => void, errorCallback?: any): any {
    this.callPOST<CausaRaiz[]>('/consultarIdT', 0, next, error => {
      const message = errorCallback(error, 'Ocorreu um erro ao pesquisar itens.');
      this.messageService.addMsgDanger(message);
      console.log('Erro ao chamar o servico. Erro: ' + error);
    });
  }

  public ativarInativar(causaRaiz: CausaRaiz): Observable<any> {
    return this.http.put<CausaRaiz>(this.url + "/ativar-inativar", causaRaiz, this.options());
  }
}
